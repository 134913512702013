<!-- Outsourcing (Part2) -->
<div class="row row-wel-outsourcing">
    <div class="col-sm-12 col-md-12 col-lg-12 bg-outsourcing">
        <div class="col-sm-12 col-md-12 col-lg-12 bg-frame-soft">
            <div class="soft-title">
                <h3>{{"OUTSOURCING.title2" | translate}}</h3>
            </div>
            <div class="d-frame-soft" data-aos="fade-up" data-aos-once="true" data-aos-duration="1200">
                    <div class="col-sm-12 col-md-12 col-lg-2 icons-frame-soft">
                            <div class="config-img-frame-soft icon-front"></div>
                            <p>{{"OUTSOURCING.subtitle1" | translate}}</p>
                        
                        <div class="content">
                            <ul>
                                <li *ngFor="let i of 'OUTSOURCING.itemsOUTSOURCING1' | translate">
                                    {{i}}
                                </li>
                            </ul>
                        </div>
                        <div class="config-img-frame-soft icon-portal"></div>
                    </div>

                    <div class="col-sm-12 col-md-12 col-lg-2 icons-frame-soft">
                        <div class="config-img-frame-soft icon-midle"></div>
                        <p>{{"OUTSOURCING.subtitle2"| translate}}</p>
                        <div class="content">
                            <ul>
                                <li *ngFor="let i of 'OUTSOURCING.itemsOUTSOURCING2' | translate">
                                    {{i}}
                                </li>
                            </ul>
                        </div>
                        <div class="config-img-frame-soft icon-portal"></div>                             
                    </div>

                    <div class="col-sm-12 col-md-12 col-lg-2 icons-frame-soft">
                        <div class="config-img-frame-soft icon-mobil"></div>
                        <p>{{"OUTSOURCING.subtitle3" | translate}}</p>
                        <div class="content">
                            <ul>
                                <li *ngFor="let i of 'OUTSOURCING.itemsOUTSOURCING3' | translate">
                                    {{i}}
                                </li>
                            </ul>
                        </div>
                        <div class="config-img-frame-soft icon-portal"></div>
                    </div>

                    <div class="col-sm-12 col-md-12 col-lg-2 icons-frame-soft">
                        <div class="config-img-frame-soft icon-dev"></div>
                        <p>{{"OUTSOURCING.subtitle4" | translate}}</p>
                        <div class="row content" >
                            <ul class="col-xs" style="margin-top: 0px; margin-left: auto; margin-right: auto;">
                                <li *ngFor="let i of 'OUTSOURCING.itemsOUTSOURCING4' | translate">
                                    {{i}}
                                </li>
                            </ul>
                            <ul class="col-xs" style="margin-top: 0px; margin-left: auto; margin-right: auto;">
                                <li *ngFor="let i of 'OUTSOURCING.itemsOUTSOURCING4B' | translate">
                                    {{i}}
                                </li>
                            </ul>
                        </div>
                        <div class="config-img-frame-soft icon-portal"></div>
                    </div>

                    <div class="col-sm-12 col-md-12 col-lg-2 icons-frame-soft">
                        <div class="config-img-frame-soft icon-pm-qa"></div>
                        <p>{{"OUTSOURCING.subtitle5"| translate}}</p>
                        <div class="content">
                            <ul >
                                <li *ngFor="let i of 'OUTSOURCING.itemsOUTSOURCING5' | translate">
                                    {{i}}
                                </li>
                            </ul>
                        </div>
                        <div class="config-img-frame-soft icon-portal"></div>
                              
                    </div>
            </div>    
        </div>
    </div>  
</div>