import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { GlobalsService } from './globals.service';

@Injectable({
  providedIn: 'root'
})
export class CountryGuardNoColGuard implements CanActivate {

  constructor(private globalService: GlobalsService){}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    // Lógica de verificación y decisiones aquí
    if (this.globalService.userCountry == 'NOCO') {
      return true; // Permite el acceso a la ruta
    } else {
      // Realiza alguna acción de redirección o notificación
      return false; // Niega el acceso a la ruta
    }
  }
  
}
