import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-business-soft',
  templateUrl: './business-soft.component.html',
  styleUrls: ['./business-soft.component.css']
})
export class BusinessSoftComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
