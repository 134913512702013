import { Component, OnChanges, OnInit } from '@angular/core';
import { GuestInfoService } from '../../services/guest-info.service';
import{GlobalsService} from'../../globals.service';

@Component({
  selector: 'app-services-fast',
  templateUrl: './services-fast.component.html',
  styleUrls: ['./services-fast.component.css']
})
export class ServicesFastComponent implements OnInit,OnChanges {

  /*constructor(private guestService : GuestInfoService, public globals:GlobalsService) { }

  ip;
  country;
  serv : Boolean;

  guestInfo(){
    this.guestService.getGuestIp()
    .subscribe(result => {
      this.ip = result;
      this.ip = this.ip.ip;
      this.getipInfo();
    })

   
   }

   getipInfo(){
    this.guestService.getGuestInfo(this.ip)
    .subscribe(result => {
      this.country = result;
      this.country = this.country.geoplugin_countryName;
      if(this.country != 'Colombia'){
        this.serv = false;
      }else{
        this.serv = true;
      }
    })
   }

  ngOnInit() {
    this.guestInfo();
  }*/

  constructor(private guestService : GuestInfoService, public globals:GlobalsService) { }

  ip;
  region;
  serv : Boolean;


  
  guestInfo(){
    this.guestService.getGuestIp()
    .subscribe(result => {
      this.ip = result;
      this.ip = this.ip.ip;
    })

    this.guestService.getGuestInfo(this.ip)
    .subscribe(result => {
      this.region = result;
      this.region = this.region.geoplugin_continentName;
      if(this.region != 'South America'){
        this.globals.mostrarIngles;
       }else{
        this.globals.mostrarIngles;
        }
      console.log('mostrar en ingles', this.globals.mostrarIngles);
    })
   }

  ngOnInit() {
    this.guestInfo();
  }

  ngOnChanges(){
    this.guestInfo();
  }

}
