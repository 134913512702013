import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-clients-foreign',
  templateUrl: './clients-foreign.component.html',
  styleUrls: ['./clients-foreign.component.css']
})
export class ClientsForeignComponent implements OnInit {

  clientList = [
    {
      name: "Rodrigo Navarro",
      url: "fotos clientes-31.png",
      descriptionKey: 'client1_description', 
      infoKey: 'client1_info',
      // info: "Head of Global Technology Services at Anuvu",
      // description: "Lorem imspuo prlaabras , heir eiheiof ihdhyui yuisdyudfs8wfe, ikhfufsi93iwjyghkO Para la form balviidadid, kfifoalp jolmn hulmsi hice ocidh, jfl;aoj fh ihioasfhioashfolslfkhsolghsiohg i9gaiofg oiasgfio porfolio prurha lorem a ver quit tal el laerttog y la fino de lo dlo cloentos para ver qaue tal se ve este esta buenio si es qau esmkf."
    },
    {
      name: "Edmund Otubuah",
      url: "fotos clientes-32.png",
      descriptionKey: 'client2_description', 
      infoKey: 'client2_info',
      // info: "Co-founder Seed At The Table",
      // description: "Lorem imspuo prlaabras , heir eiheiof ihdhyui yuisdyudfs8wfe, ikhfufsi93iwjyghkO Para la form balviidadid, kfifoalp jolmn hulmsi hice ocidh, jfl;aoj fh ihioasfhioashfolslfkhsolghsiohg i9gaiofg oiasgfio."
    },
    {
      name: "Miguel Garcia",
      url: "fotos clientes-33.jpg",
      descriptionKey: 'client3_description', 
      infoKey: 'client3_info',
      // info: "Administrador de empresa",
      // description: "Lorem imspuo prlaabras , heir eiheiof ihdhyui yuisdyudfs8wfe, ikhfufsi93iwjyghkO Para la form balviidadid, kfifoalp jolmn hulmsi hice ocidh, jfl;aoj fh ihioasfhioashfolslfkhsolghsiohg i9gaiofg oiasgfio."
    }
  ];

  clientSelected: any;

  constructor(private translate: TranslateService) { }

  ngOnInit(): void {
    this.clientSelected = this.clientList[0];
  }

 
translateAttribute(attributeKey: string): string {
  return this.translate.instant(`pageAspForeign.clients.${attributeKey}`)
}

}
