import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-our-clients',
  templateUrl: './our-clients.component.html',
  styleUrls: ['./our-clients.component.css']
})
export class OurClientsComponent implements OnInit {

  constructor() { }

  slides = [];

  slideConfig = {
    "slidesToShow": 5,
    "slidesToScroll": 1,
    "autoplay": true,
    "infinite": true,
    "speed": 2000,
    "autoplaySpeed": 2000,
    "slidesPerRow": 1,
    "swipeToSlide": true,
    "arrows": true
  };


  /*Local
  Clients(){
    for (let i = 1; i <= 10; i++) {
      this.slides.push("../../../assets/clients/client"+i+".png");
    }
  }*/

  /*Productivo*/
  Clients(){
    for (let i = 1; i <= 29; i++) {
      this.slides.push("./assets/clients/client"+i+".png");
    }
  }

  ngOnInit() {
    this.Clients();
  }
  

}
