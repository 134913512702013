<header>
    <div class="contenHeader paddingForeign">
       <div class="logoAspLLC" (click)="goHome()"></div>
       <span class="flex-spacer"></span>
       <nav class="navHeader">
           <a class="linkHeader"  [ngClass]="{'linkSelected': globals.selectNav === 'home'}" (click)="scrollToSection3('home')">
               {{"pageAspForeign.header.linkHome" | translate}}
               <span class="circle" [ngClass]="{'active': globals.selectNav === 'home'}"></span>
           </a>
           <a class="linkHeader" [ngClass]="{'linkSelected': globals.selectNav === 'whatWeDo'}" (click)="scrollToSection3('whatWeDo')">
               {{"pageAspForeign.header.linkWTD" | translate}}
               <span class="circle" [ngClass]="{'active': globals.selectNav === 'whatWeDo'}"></span>
           </a>
           <a class="linkHeader" [ngClass]="{'linkSelected': globals.selectNav === 'ourServices'}"  (click)="selectRoutingNav('ourServices')">
               {{"pageAspForeign.header.linkServices" | translate}}
               <span class="circle" [ngClass]="{'active': globals.selectNav === 'ourServices'}"></span>
           </a>
           <a class="linkHeader" [ngClass]="{'linkSelected': globals.selectNav === 'ourWork'}"  (click)="selectRoutingNav('ourWork')">
               {{"pageAspForeign.header.linkOurWork" | translate}}
               <span class="circle" [ngClass]="{'active': globals.selectNav === 'ourWork'}"></span>
           </a>
           <a class="linkHeader" [ngClass]="{'linkSelected': globals.selectNav === 'qbox'}"  (click)="selectRoutingNav('qbox')">
            Qbox
            <span class="circle" [ngClass]="{'active': globals.selectNav === 'qbox'}"></span>
        </a>
           <!-- <a class="linkHeader" [ngClass]="{'linkSelected': selectNav === 'blog'}" (click)="scrollToSection('blog')">
               {{"pageAspForeign.header.linkBlog" | translate}}
               <span class="circle" [ngClass]="{'active': selectNav === 'blog'}"></span>
           </a>
           <a class="linkHeader" [ngClass]="{'linkSelected': selectNav === 'careers'}" (click)="scrollToSection('careers')">
               {{"pageAspForeign.header.linkCareers" | translate}}
               <span class="circle" [ngClass]="{'active': selectNav === 'careers'}"></span>
           </a> -->
   
           <!-- <mat-icon [matMenuTriggerFor]="menu" class="iconTranslate">translate</mat-icon>
           <mat-menu #menu="matMenu">
               <button mat-menu-item (click)="changeLanguage('es')">Spanish</button>
               <button mat-menu-item (click)="changeLanguage('en')">English</button>
             </mat-menu> -->
   
   
           <!-- <mat-icon *ngIf="language == 'en'" (click)="changeLanguage('es')" class="iconLenguaje"> <img src="../../../assets/pageForeignAsp/icon-spanish.png" alt="ES"> </mat-icon>
           <mat-icon *ngIf="language == 'es'" (click)="changeLanguage('en')" class="iconLenguaje"> <img src="../../../assets/pageForeignAsp/icon-english.png" alt="EN"> </mat-icon> -->
   
           <!-- Bandera idioma -->
           <!-- <mat-icon class="flag-icon iconLenguaje" [ngClass]="{'wave': isHovered}" (mouseenter)="startAnimation()" (mouseleave)="stopAnimation()">
               <img *ngIf="language === 'en'" (click)="changeLanguage('es')"  matTooltip="Translate to Spanish" src="../../../assets/pageForeignAsp/headerForeign/icon-spanish.svg" alt="ES" class="flag-img">
               <img *ngIf="language === 'es' " (click)="changeLanguage('en')"  matTooltip="Translate to English"  src="../../../assets/pageForeignAsp/headerForeign/icon-english.svg" alt="EN" class="flag-img">
           </mat-icon> -->
          
   
   
           <div class="btnRadius" matRipple (click)="navigateContact()">{{"pageAspForeign.header.btnGetTouch" | translate}}</div>

           <img src="../../../assets/pageForeignAsp/headerForeign/icon-translatev2.svg" [matMenuTriggerFor]="menuT" alt="Translate" class="iconTranslate">
           <mat-menu #menuT="matMenu">
            <button mat-menu-item (click)="changeLanguage('es')">Spanish</button>
            <button mat-menu-item (click)="changeLanguage('en')">English</button>
          </mat-menu>
       </nav>
    </div>
   </header>
   
