import { AfterContentInit, AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AppRoutingModuleForeign } from 'src/app/routing-module-foreign';

declare var bodymovin:any;

@Component({
  selector: 'app-asp-foreign-home',
  templateUrl: './asp-foreign-home.component.html',
  styleUrls: ['./asp-foreign-home.component.css']
})

export class AspForeignHomeComponent implements OnInit {
  gifUrl: string;
  // private animation: AnimationItem;

  constructor(private cdr: ChangeDetectorRef,  private router: Router) { 
    this.gifUrl = `../../../assets/pageForeignAsp/homeForeign/img_Are you2.svg?v=${Date.now()}`;
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.loadAnimation();
    }, 100);
  }
   
  loadAnimation(){
    var animation = bodymovin.loadAnimation({
      container: document.getElementById('animationHome1'),
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: '../../../assets/pageForeignAsp/animations/homeTeamAsp.json'
    })
    var animation = bodymovin.loadAnimation({
      container: document.getElementById('animationHome2'),
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: '../../../assets/pageForeignAsp/animations/homeTeamAsp.json'
    })
    var animationLigth = bodymovin.loadAnimation({
      container: document.getElementById('animationLigth'),
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: '../../../assets/pageForeignAsp/animations/LightbulbHome.json'
    })
  }

  navigateContact(){
    this.router.navigateByUrl('/contactForeign');
  }
  
}
