import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-welcome-jformsrun',
  templateUrl: './welcome-jformsrun.component.html',
  styleUrls: ['./welcome-jformsrun.component.css']
})
export class WelcomeJformsrunComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
