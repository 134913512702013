import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-projects-soft',
  templateUrl: './projects-soft.component.html',
  styleUrls: ['./projects-soft.component.css']
})
export class ProjectsSoftComponent implements OnInit {

  constructor() { }

  movil : Boolean;
  web : Boolean;

  selectedBtn(selected){
    this.movil = false;
    this.web = false;
    if(selected == 'movil'){
      this.movil = true;
    }else if(selected == 'web'){
      this.web = true;
    }
  }

  ngOnInit() {
    this.selectedBtn('movil');
  }

}
