import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { GlobalsService } from './globals.service';

@Injectable({
  providedIn: 'root'
})
export class CountryGuardGuard implements CanActivate {

  constructor(private router: Router, private http: HttpClient, private globalService: GlobalsService) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    // Lógica de verificación y decisiones aquí
    if (this.globalService.userCountry == 'CO') {
      return true; // Permite el acceso a la ruta
    } else {
      // Realiza alguna acción de redirección o notificación
      return false; // Niega el acceso a la ruta
    }
  }
}
