import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-analysis-soft',
  templateUrl: './analysis-soft.component.html',
  styleUrls: ['./analysis-soft.component.css']
})
export class AnalysisSoftComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
