<div class="row">
  <div class="col-sm-12 col-md-12 col-lg-12 col-seg">
    <div class="title-segment">
      <h2>{{ "US.titleSegCustomer" | translate }}</h2>
    </div>
    <div  *ngIf="!globals.mostrarIngles" class="btns-customers-segment">
      <button class="publ" [ngClass]="{ 'customer-act': publico === true }"  mat-raised-button (click)="selectedCustomer('publico')">
        <div class="config-g-i-c-m-t img-publ" [ngClass]="{ 'publ-active': publico === true }"></div>
        <span>{{ "US.subTitlePublic" | translate }}</span>
      </button>

      <button class="priv" [ngClass]="{ 'customer-act': privado === true }" mat-raised-button (click)="selectedCustomer('privado')">
        <div class="config-g-i-c-m-t img-priv" [ngClass]="{ 'priv-active': privado === true }"></div>
        <span>{{ "US.subTitlePriv" | translate }}</span>
      </button>

      <button class="intl" [ngClass]="{ 'customer-act': internacional === true }"  mat-raised-button (click)="selectedCustomer('internacional')">
        <div class="config-g-i-c-m-t img-intl" [ngClass]="{ 'intl-active': internacional === true }"></div>
        <span>{{ "US.subTitleIntl" | translate }}</span>
      </button>
    </div>

    <!-- botones en ingles -->
    <div  *ngIf="globals.mostrarIngles" class="btns-customers-segment">
      <button class="north" [ngClass]="{ 'customer-act': north === true }"  mat-raised-button (click)="selectedCustomerEng('north')">
        <div class="config-g-i-c-m-t img-north" [ngClass]="{ 'north-active': north === true }"></div>
        <span>{{ "US.subTitlePublic" | translate }}</span>
      </button>

      <button class="south" [ngClass]="{ 'customer-act': south === true }" mat-raised-button (click)="selectedCustomerEng('south')">
        <div class="config-g-i-c-m-t img-south" [ngClass]="{ 'south-active': south === true }"></div>
        <span>{{ "US.subTitlePriv" | translate }}</span>
      </button>

      <button class="others" [ngClass]="{ 'customer-act': others === true }"  mat-raised-button (click)="selectedCustomerEng('others')">
        <div class="config-g-i-c-m-t img-others" [ngClass]="{ 'others-active': others === true }"></div>
        <span>{{ "US.subTitleIntl" | translate }}</span>
      </button>
    </div>

    <!-- sliders en español -->
    <div *ngIf="publico && !globals.mostrarIngles">
      <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
        <div ngxSlickItem *ngFor="let slide of slides" class="slide borde">
          <img src="{{ slide }}" alt="" width="75%" />
        </div>
      </ngx-slick-carousel>
    </div>

    <div *ngIf="privado && !globals.mostrarIngles">
      <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
        <div ngxSlickItem *ngFor="let slide of slidesPriv" class="slide borde">
          <img src="{{ slide }}" alt="" width="75%" />
        </div>
      </ngx-slick-carousel>
    </div>

    <div *ngIf="internacional && !globals.mostrarIngles">
      <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
        <div ngxSlickItem *ngFor="let slide of slidesIntl" class="slide borde">
          <img src="{{ slide }}" alt="" width="75%" />
        </div>
      </ngx-slick-carousel>
    </div>
    
    <!-- sliders en inglés -->
    <div *ngIf="north && globals.mostrarIngles">
      <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
        <div ngxSlickItem *ngFor="let slide of slidesNorth" class="slide borde">
          <img src="{{ slide.img }}" alt="" width="75%" />
        </div>
      </ngx-slick-carousel>
    </div>

    <div *ngIf="south && globals.mostrarIngles">
      <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
        <div ngxSlickItem *ngFor="let slide of slidesSouth" class="slide borde">
          <img src="{{ slide.img }}" alt="" width="75%" />
        </div>
      </ngx-slick-carousel>
    </div>

    <div *ngIf="others && globals.mostrarIngles">
      <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
        <div ngxSlickItem *ngFor="let slide of slidesOthers" class="slide borde">
          <img src="{{ slide.img }}" alt="" width="75%" />
        </div>
      </ngx-slick-carousel>
    </div>

  </div>
</div>
