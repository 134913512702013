<div class="row row-busin">
    <div class="col-sm-12 col-md-12 col-lg-6 col-img-busin-soft">
        <div class="bg-busin-soft" data-aos="fade-right" data-aos-once="true" data-aos-duration="1400"></div>
    </div>

    <div class="col-sm-12 col-md-12 col-lg-6 ">
        <div class="cont-busin" data-aos="fade-left" data-aos-once="true" data-aos-duration="1400">
            <h2 >{{"SOFTWARE.titleBusiness" | translate}}</h2>
            <ul>
                <li  *ngFor="let p of 'SOFTWARE.paragraphBusiness' | translate">{{p}}</li>
            </ul>
        </div>
    </div>
</div>
