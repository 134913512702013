import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-download-tools',
  templateUrl: './download-tools.component.html',
  styleUrls: ['./download-tools.component.css']
})
export class DownloadToolsComponent implements OnInit {

  constructor() { }

  eclipse : Boolean;
  java : Boolean;
  apache : Boolean;

  selectedLogo(selected){
    this.eclipse = false;
    this.java = false;
    this.apache = false;

    if(selected == 'eclipse'){
      this.eclipse = true;
    }else if(selected == 'java'){
      this.java = true;
    }else if(selected == 'apache'){
      this.apache = true;
    }
  }

  ngOnInit() {
    this.selectedLogo('eclipse');
  }

}
