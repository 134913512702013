import { animate, state, style, transition, trigger } from '@angular/animations';
import { ViewportScroller } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GlobalsService } from 'src/app/globals.service';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-header-asp-mobile',
  templateUrl: './header-asp-mobile.component.html',
  styleUrls: ['./header-asp-mobile.component.css'],
  animations: [
    trigger('slideLeft', [
      state('initial', style({
        visibility : 'hidden',
        left: '-70%'
      })),
      state('final', style({
        left: '0px',
        visibility : 'visible'
      })),
      transition('initial=>final', animate('300ms')),
      transition('final=>initial', animate('300ms'))
    ]),
    trigger('slideInDownService', [
      state('initial', style({
        height: '0%'
      })),
      state('final', style({
        height: 'auto',
      })),
      transition('initial=>final', animate('300ms')),
      transition('final=>initial', animate('300ms'))
    ])
  ]
})
export class HeaderAspMobileComponent implements OnInit {
  selectNav: any;
  panelOpenState = false;
  language: string = "";

  constructor(public globals:GlobalsService, private translate : TranslateService, private router: Router, private viewportScroller: ViewportScroller) { }

  sidenav : boolean = false;
  public currentState : string = 'initial';
  public currentStateServ : string = 'initial';

  exact : boolean = true;
  celphone = "+573043959699";

  showMenu(){
    this.sidenav = !this.sidenav;
    this.currentState = this.currentState === 'initial' ? 'final' : 'initial';
  }

  hideMenu(e){
    if(e.target.id == "navMobile"){
      this.sidenav = !this.sidenav;
      this.currentState = this.currentState === 'initial' ? 'final' : 'initial';
    }
  }

  hideMenuNav(){
    this.sidenav = !this.sidenav;
    this.currentState = this.currentState === 'initial' ? 'final' : 'initial';
  }


  ngOnInit() {
    this.globals.mostrarIngles;
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.viewportScroller.scrollToPosition([0, 0]);
    });
  }


  
  ngOnChanges(){
    this.globals.mostrarIngles;
   }

   scrollToSection(sectionId: string) {
    const element = document.getElementById(sectionId);
    if (element) {
      // this.selectNav = sectionId;
      // element.scrollIntoView({ behavior: 'smooth' });
  
      this.selectNav = sectionId;
      
      // Obtén el tamaño de la ventana
      const windowHeight = window.innerHeight;
  
      // Obtén la posición actual del elemento
      const elementPosition = element.getBoundingClientRect();
  
      // Calcula la posición de desplazamiento con la corrección de 150px
      const scrollPosition = elementPosition.top + window.scrollY - (windowHeight / 2) + 190;
  
      // Realiza el desplazamiento suave
      window.scrollTo({ top: scrollPosition, behavior: 'smooth' });
      this.sidenav = !this.sidenav;
      this.currentState = this.currentState === 'initial' ? 'final' : 'initial';
    }
  }

  changeLanguage(language) : void{
    this.globals.mostrarIngles=!this.globals.mostrarIngles;
    this.translate.use(language);
    this.language = language;
    this.sidenav = !this.sidenav;
    this.currentState = this.currentState === 'initial' ? 'final' : 'initial';
  }
}
