<div class="row row-wel-jforms">
    <div class="col-sm-12 col-md-12 col-lg-12 bg-jforms">
        <div class="wel-jforms" data-aos="fade-left" data-aos-once="true" data-aos-duration="800">
            <h2>{{"JFORMS.titleJforms" | translate}}</h2>
            <p>
                {{"JFORMS.paragraphJforms" | translate}}
            </p>
            <div class="download-tools" data-aos="fade-up" data-aos-once="true" data-aos-duration="800">
                <a  mat-button href="http://www.aspsols.com/AspSolutionsPage2016/faces/doc-jfb.xhtml" target="_blank">{{"JFORMS.btnDownloadDoc" | translate}}</a>
            </div>
        </div>
    </div>
</div>
