<div class="row row-test">
  <div class="col-sm-12 col-md-12 col-lg-12 col-test back-size">
    <div class="content-g-test">
      <h2 data-aos="fade-right" data-aos-once="true" data-aos-duration="600">{{"HOME.titleTestimonial" | translate}}</h2>
      <div class="icont-test">
        <!--  <div class="icon-test-l" data-aos="fade-up" data-aos-once="true" data-aos-duration="1000"></div>
        <div class="icon-test-r" data-aos="fade-up" data-aos-once="true" data-aos-duration="1000"></div> -->
      </div>
      <div>
          <ngx-slick-carousel class="carousel slick-test" #slickModal="slick-carousel" [config]="slideConfig">
          <div ngxSlickItem  class="slide">
            <div class="row">
              <div class="col-lg-8">
                <div class="person-test">
                  <p><span class="quotes">“</span>{{"HOME.testimonial1" | translate}}<span class="quotes">”</span></p>
                  <span>Edmund Otubuah</span><br>
                  <span>Co-founder, masFlight.</span>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="img-test1" data-aos="fade-left" data-aos-once="true" data-aos-duration="1000"></div>
              </div>
            </div>
          </div>

          <div ngxSlickItem  class="slide">
            <div class="row">
              <div class="col-lg-8">
                <div class="person-test">
                  <p><span class="quotes">“</span>{{"HOME.testimonial2" | translate}}<span class="quotes">”</span></p>
                  <span>Alexis Gómez</span><br>
                  <span>Director Ejecutivo, ITEAM.</span>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="img-test2" data-aos="fade-left" data-aos-once="true" data-aos-duration="1000"></div>
              </div>
            </div>
          </div>

          <div ngxSlickItem  class="slide">
            <div class="row">
              <div class="col-lg-8">
                <div class="person-test">
                  <p><span class="quotes">“</span>{{"HOME.testimonial3" | translate}}<span class="quotes">”</span></p>
                  <span>Miquel Sans</span><br>
                  <span>Director, Limit Quattro, S.A.</span>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="img-test3" data-aos="fade-left" data-aos-once="true" data-aos-duration="1000"></div>
              </div>
            </div>
          </div>
          
          <div ngxSlickItem  class="slide">
            <div class="row">
              <div class="col-lg-8">
                <div class="person-test">
                  <p><span class="quotes">“</span>{{"HOME.testimonial4" | translate}}<span class="quotes">”</span></p>
                  <span>Miguel Garcia</span><br>
                  <span>CEO, ConstruWise.</span>
                </div>
              </div>
              
                <div  class="col-lg-4" >
                  <div class="img-test4" data-aos="fade-left" data-aos-once="true" data-aos-duration="1000"></div>
                </div>
              
            </div>
          </div>
          <!-- <div ngxSlickItem  class="slide">
            <div class="img-test3" data-aos="fade-left" data-aos-once="true" data-aos-duration="1000"></div>
            <div class="person-test">
              <p><span class="quotes">“</span>
                {{"HOME.testimonial3" | translate}}<span class="quotes">”</span></p>
              <span>Miquel Sans</span><br>
              <span>Director, Limit Quattro, S.A.</span>
            </div>
          </div> -->
        </ngx-slick-carousel>
        
      </div>
    </div>
  </div>
</div>
