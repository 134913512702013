import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocationService {
  private apiAccessKey = '62d5371ef482de';

  constructor(private http : HttpClient) { }

  ubicationUser() {
    const url = `https://ipinfo.io?token=${this.apiAccessKey}`;
    this.http.get(url).subscribe(
      (response: any) => {
        const country = response.country;
        if (country === 'CO') {
          return 'CO'
        } else {
          return 'NOCOL'
        }
      },
      (error: any) => {
        console.error('Error al obtener la ubicación:', error);
        return 'NOCOL'
      });
  }
}
