<div class="row row-download-tools">
    <div class="col-sm-12 col-md-12 col-lg-6">
        <div class="download-tools" data-aos="fade-up" data-aos-once="true" data-aos-duration="800">
            <h2>{{"TOOLS.titleDownload" | translate}}</h2>
            <p class="description">
                {{"TOOLS.paragraphDownload" | translate}}
            </p>

            <!--mobile-->
            <div class="mobile" data-aos="fade-up" data-aos-once="true" data-aos-duration="800">
                <div class="btn-mobile-logo">
                    <button class="btn-logo" [ngClass]="{'btn-down-act' : eclipse}" mat-raised-button (click)="selectedLogo('eclipse')"><div class="logo-config logo-eclipse" [ngClass]="{'down-act-eclipse' : eclipse}"></div></button>
                    <button class="btn-logo" [ngClass]="{'btn-down-act' : java}" mat-raised-button (click)="selectedLogo('java')"><div class="logo-config logo-java" [ngClass]="{'down-act-java' : java}"></div></button>
                    <button class="btn-logo" [ngClass]="{'btn-down-act' : apache}" mat-raised-button (click)="selectedLogo('apache')"><div class="logo-config logo-apache" [ngClass]="{'down-act-apache' : apache}"></div></button>
                </div>
                <div *ngIf="eclipse" class="img-config-down img-eclipse animated fadeIn"></div>
                <div *ngIf="java" class="img-config-down img-java animated fadeIn"></div>
                <div *ngIf="apache" class="img-config-down img-apache animated fadeIn"></div>
            </div>

            <p *ngIf="eclipse" class="subtitle-download animated fadeIn">{{"TOOLS.titleTool1" | translate}}</p>
            <p *ngIf="java" class="subtitle-download animated fadeIn">{{"TOOLS.titleTool2" | translate}}</p>
            <p *ngIf="apache" class="subtitle-download animated fadeIn">{{"TOOLS.titleTool3" | translate}}</p>
            <a *ngIf="eclipse" mat-button href="https://www.eclipse.org/downloads/" target="_blank">{{"TOOLS.btnDownloadTool" | translate}}</a>
            <a *ngIf="java" mat-button href="https://www.oracle.com/technetwork/es/java/javase/downloads/index.html" target="_blank">{{"TOOLS.btnDownloadTool" | translate}}</a>
            <a *ngIf="apache" mat-button href="https://tomcat.apache.org/" target="_blank">{{"TOOLS.btnDownloadTool" | translate}}</a>
        </div>
    </div>
    <div class="col-sm-12 col-md-6 col-lg-6 col-logo desk">
        <button class="btn-logo" [ngClass]="{'btn-down-act' : eclipse}" mat-raised-button (click)="selectedLogo('eclipse')"><div class="logo-config logo-eclipse" [ngClass]="{'down-act-eclipse' : eclipse}"></div></button>
        <button class="btn-logo" [ngClass]="{'btn-down-act' : java}" mat-raised-button (click)="selectedLogo('java')"><div class="logo-config logo-java" [ngClass]="{'down-act-java' : java}"></div></button>
        <button class="btn-logo" [ngClass]="{'btn-down-act' : apache}" mat-raised-button (click)="selectedLogo('apache')"><div class="logo-config logo-apache" [ngClass]="{'down-act-apache' : apache}"></div></button>
        <div *ngIf="eclipse" class="img-config-down img-eclipse animated fadeIn"></div>
        <div *ngIf="java" class="img-config-down img-java animated fadeIn"></div>
        <div *ngIf="apache" class="img-config-down img-apache animated fadeIn"></div>
    </div>

</div>
