<div class="contentAll">
    <!-- <app-asp-foreign-header></app-asp-foreign-header> -->
    <app-asp-foreign-home id="home"></app-asp-foreign-home>
    <app-what-we-do id="whatWeDo"></app-what-we-do>
    <app-experience id="experience"></app-experience>
    <!-- <app-our-services id="services"></app-our-services> -->
    <app-our-solutions id="solutions"></app-our-solutions>
    <app-our-work id="ourWork"></app-our-work>
    <app-allied-brands></app-allied-brands>
    <app-clients-foreign></app-clients-foreign>
    <!-- <app-footer-foreign></app-footer-foreign> -->
</div>
