import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-backend-soft',
  templateUrl: './backend-soft.component.html',
  styleUrls: ['./backend-soft.component.css']
})
export class BackendSoftComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
