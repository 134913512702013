<div class="row row-mobile-app">

    <div class="col-sm-12 col-md-12 col-lg-6 bg-frame-soft container-img-mobileApp">
        <div class="title-frame-soft" data-aos="fade-up" data-aos-once="true" data-aos-duration="1200" style="text-align: center;">
            <!--<div class="img-app img-mobile-app"></div>-->
            <img src="./assets/software/mobile-app.png" alt="Mobile App" class="img-style-mobileApp">
        </div>
    </div>

    <div class="col-sm-12 col-md-12 col-lg-6 bg-frame-soft">
        <div class="cont-inter"  data-aos="fade-left" data-aos-once="true" data-aos-duration="1200">
            <h2>{{"SOFTWARE.titleMobileApp" | translate}}</h2>
            <p>{{"SOFTWARE.paragraphMobileApp" | translate}}</p>
        </div>
    </div>

</div>