<div class="container-fluid">
    <app-welcome-software></app-welcome-software>
    <app-shortcuts-software></app-shortcuts-software>    
    <app-mobile-application-development></app-mobile-application-development>
    <app-framework-soft></app-framework-soft>
    <app-interactions-soft></app-interactions-soft>
    <app-backend-soft></app-backend-soft>
    <app-business-soft></app-business-soft>
    <app-analysis-soft></app-analysis-soft>
<!-- <app-methodology-soft></app-methodology-soft>  Metolodgia Scrum se paso a Nosotros -->
    <app-projects-soft></app-projects-soft>
</div>
