<div class="container-fluid">


    <app-welcome-outsourcing></app-welcome-outsourcing>
    <app-content-outsourcing></app-content-outsourcing>




</div>
