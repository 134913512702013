<div class="containerPageQboxAll">
    <section class="sectionMainQbox paddingForeign">
        <article class="articleMainQbox1">
            <h3>
               <span>{{"pageAspForeign.QBOX.title1" | translate}}</span>  <br>
               {{"pageAspForeign.QBOX.title2" | translate}} <br>
               {{"pageAspForeign.QBOX.title3" | translate}}
            </h3>
            <p>
              <ng-container *ngIf="showImage === 0">{{"pageAspForeign.QBOX.txtInitial" | translate}}</ng-container>
              <ng-container *ngIf="showImage === 1">{{"pageAspForeign.QBOX.txtInitial2" | translate}}</ng-container>
              <ng-container *ngIf="showImage === 2">{{"pageAspForeign.QBOX.txtInitial3" | translate}}</ng-container>
              <ng-container *ngIf="showImage === 3">{{"pageAspForeign.QBOX.txtInitial4" | translate}}</ng-container>
              <ng-container *ngIf="showImage === 4">{{"pageAspForeign.QBOX.txtInitial5" | translate}}</ng-container>
            </p>
            <div class="containerReachOut">
                <div class="btnRadius buttonReachOut" (click)="navigateContact()" matRipple>{{"pageAspForeign.QBOX.reachOut" | translate}}</div>
                <label>
                    {{"pageAspForeign.QBOX.enhancedData" | translate}} <br> {{"pageAspForeign.QBOX.intelligence" | translate}} 
                </label>
            </div>
            <div class="containerButtonChangeImg">
                <button (click)="showImage = 0" class="buttonChangeImg" [ngClass]="{ 'buttonChangeColor': showImage === 0 }"></button>
                <button (click)="showImage = 1" class="buttonChangeImg" [ngClass]="{ 'buttonChangeColor': showImage === 1 }"></button>
                <button (click)="showImage = 2" class="buttonChangeImg" [ngClass]="{ 'buttonChangeColor': showImage === 2 }"></button>
                <button (click)="showImage = 3" class="buttonChangeImg" [ngClass]="{ 'buttonChangeColor': showImage === 3 }"></button>
                <button (click)="showImage = 4" class="buttonChangeImg" [ngClass]="{ 'buttonChangeColor': showImage === 4 }"></button>
            </div>
           
        </article>

        <article class="articleMainQbox2">
            <img *ngFor="let image of images; let i = index" [src]="image" alt="Imagen {{i + 1}}" class="screen" [ngClass]="{ 'active': showImage === i }">
        </article>
    </section> 

    <section class="sectionBusiness paddingForeign">
        <article class="articleBusiness1">
            <h3>
                <span>{{"pageAspForeign.QBOX.businessTitle1" | translate}} </span>  <br>
                 {{"pageAspForeign.QBOX.businessTitle2" | translate}}
             </h3>
             <p>
                {{"pageAspForeign.QBOX.businessTxt1" | translate}}
              </p>
              <p>
                {{"pageAspForeign.QBOX.businessTxt2" | translate}}
              </p>
        </article>

        <article class="articleBusiness2">
            <img src="../../../assets/pageForeignAsp/pageQbox/img-business.svg"  alt="Business">
        </article>
    </section>
    
    <section class="sectionCoreAdvantages">
        <div class="articleCoreAdvantagesTitle paddingForeign">
            <h3> {{"pageAspForeign.QBOX.CoreAdvanatges" | translate}}</h3>
            <p> {{"pageAspForeign.QBOX.CoreAdvanatgesTxt" | translate}} </p> 
            <div class="btnRadius buttonReachOut" (click)="navigateContact()" matRipple>{{"pageAspForeign.extras.contactUs" | translate}}</div>
        </div>

        <div class="articleCoreAdvantagesImg">
            <p-carousel #carousel [value]="items" [numVisible]="2" [numScroll]="2" [responsiveOptions]="responsiveOptions" [circular]="false">
                <ng-template let-item pTemplate="item">
                  <article class="articleCoreAdvantagesImgTxt borderCoreAdvantages">
                    <div class="txtArticle">
                      <label>{{item.title}}</label>
                      <p>{{item.txt}}</p>
                    </div>
                    <div [ngClass]="{'containerImgLeft': item.position === 'left' || item.position === 'end', 'containerImgRight': item.position === 'right'}" class="absolute">
                      <img src="../../../assets/pageForeignAsp/pageQbox/{{ item.img }}" alt="Strategic">
                    </div>
                  </article>
                </ng-template>
              </p-carousel>
        </div>

        <div class="articleCoreAdvantagesImgMobile">
            <article class="articleCoreAdvantagesImgTxt borderCoreAdvantages" *ngFor="let item of items">
                <div class="txtArticle">
                  <label>{{item.title}}</label>
                  <p>{{item.txt}}</p>
                </div>
                <div [ngClass]="{'containerImgLeft': item.position === 'left', 'containerImgRight': item.position === 'right', 'containerImgEnd': item.position === 'end'}" class="absolute">
                  <img src="../../../assets/pageForeignAsp/pageQbox/{{ item.img }}" alt="Strategic">
                </div>
              </article>
        </div>
        
      </section>
      
    <section class="sectionAdvantage paddingForeign">
        <article class="articleAdvantage">
           <img src="../../../assets/pageForeignAsp/pageQbox/img-survey.svg" alt="System">
           <h4>{{"pageAspForeign.QBOX.titleEnd1" | translate}}<br> {{"pageAspForeign.QBOX.titleEnd1.1" | translate}}</h4>
           <p>
            {{"pageAspForeign.QBOX.txtEnd1" | translate}}
            </p>
        </article>
        <article class="articleAdvantage">
            <img src="../../../assets/pageForeignAsp/pageQbox/img-server.svg" alt="System">
           <h4>{{"pageAspForeign.QBOX.titleEnd2" | translate}} <br> {{"pageAspForeign.QBOX.titleEnd2.1" | translate}} </h4>
           <p>
            {{"pageAspForeign.QBOX.txtEnd2" | translate}}
           </p>
        </article>
        <article class="articleAdvantage">
            <img src="../../../assets/pageForeignAsp/pageQbox/img-Secure-Supported-Solutions.svg" alt="System">
           <h4> {{"pageAspForeign.QBOX.titleEnd3" | translate}} <br> {{"pageAspForeign.QBOX.titleEnd3.1" | translate}}</h4>
           <p>
            {{"pageAspForeign.QBOX.txtEnd3" | translate}}
        </p>
        </article>
    </section>
</div>