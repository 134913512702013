import { HttpClient } from '@angular/common/http';
import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as AOS from 'aos';
import { GlobalsService } from './globals.service';
import { LocationService } from './services/location.service';
import { AppRoutingModule } from './app-routing.module';
import { AppRoutingModuleForeign } from './routing-module-foreign';
import { TranslateService } from '@ngx-translate/core';
// import { AppRoutingModule } from './app-routing.module';
// import { AppRoutingModuleForeign } from './routing-module-foreign';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit{

  title = 'ASPSolutions';
  private apiAccessKey = '62d5371ef482de';
  userLocality: boolean = true;
  userLocation: any;

  private touchCounter = 0;
  private touchTimeout: any;
  language: string;

  constructor(private route : Router, private translate : TranslateService, public globals:GlobalsService, private http: HttpClient, public globalService: GlobalsService, private locationService: LocationService){
    // this.obtenerUbicacionUsuario();
    // this.userLocation = this.locationService.ubicationUser();
    
  }


  ngOnInit() {
    // 1️⃣ Definir idiomas disponibles
    this.translate.addLangs(['es', 'en']);
    this.translate.setDefaultLang('es');
  
    // 2️⃣ Detectar el idioma del navegador
    this.language = navigator.language ? navigator.language.substr(0, 2) : 'es';
    if (this.language !== 'es') {
      this.globals.mostrarIngles = true;
      this.language = 'en';
    } else {
      this.globals.mostrarIngles = false;
      this.language = 'es';
    }
    this.translate.use(this.language);
  
    // 3️⃣ Obtener la ubicación del usuario
    const url = `https://ipinfo.io?token=${this.apiAccessKey}`;
    this.http.get(url).subscribe(
      (response: any) => {
        const country = response.country;
        if (country === 'CO') {
          this.globalService.userCountry = 'CO';
          this.userLocality = true;
          
          // Manejo del navbar sticky
          window.onscroll = function() { myFunction() };
          const navbar = document.getElementById("nav");
          const sticky = navbar.offsetTop;
          function myFunction() {
            if (window.pageYOffset > sticky) {
              navbar.classList.add("sticky");
            } else {
              navbar.classList.remove("sticky");
            }
          }
  
          AOS.init();
          this.route.navigate(['/']); // Página de Colombia
        } else {
          this.globalService.userCountry = 'NOCO';
          this.userLocality = false;
          this.route.navigateByUrl('/homeAsp'); // Página internacional
        }
      },
      (error: any) => {
        console.error('Error al obtener la ubicación:', error);
        // Si hay error, cargar la página internacional por defecto
        this.globalService.userCountry = 'NOCO';
        this.userLocality = false;
        this.route.navigateByUrl('/homeAsp');
      }
    );
  }
  

  obtenerUbicacionUsuario() {
    const url = `https://ipinfo.io?token=${this.apiAccessKey}`;
    this.http.get(url).subscribe(
      (response: any) => {
        const country = response.country;

        if (country === 'CO') {
          // El usuario está en Colombia
          // this.route.navigate(['/pageAspForeing']);

          this.userLocality = false; //Esta parte va abajo, solo es prueba.
          // this.route.navigate(['/pageAspForeing']);
          console.log('El usuario está en Colombia');

          //--- CODIGO ANTEIOR PARA EL DOM DE LA PAGINA WEB ANTIGUA
          window.onscroll = function() {myFunction()};

          var navbar = document.getElementById("nav");
          var sticky = navbar.offsetTop;
          
          function myFunction() {
            if (window.pageYOffset > sticky) {
              navbar.classList.add("sticky")
            } else {
              navbar.classList.remove("sticky");
            }
          }
              AOS.init();
          //--- CODIGO ANTEIOR PARA EL DOM DE LA PAGINA WEB ANTIGUA
          // this.globalService.userCountry = true;
        } else {
          // El usuario no está en Colombia
          this.userLocality = true;
          // this.globalService.userCountry = false;
          console.log('El usuario no está en Colombia');
        }
      },
      (error: any) => {
        // this.globalService.userCountry = false;
        console.error('Error al obtener la ubicación:', error);
      }
    );
  }

  // @HostListener('window:keydown', ['$event'])
  // handleKeyboardEvent(event: KeyboardEvent) {
  //   if (event.ctrlKey && event.shiftKey && event.key === 'L') {
  //     this.globalService.userCountry = 'CO';
  //     this.userLocality = true;
  //     this.route.navigate(['/']);
  //   } else if (event.ctrlKey && event.shiftKey && event.key === 'K') {
  //     this.globalService.userCountry = 'NOCO';
  //     this.userLocality = false;
  //     this.route.navigateByUrl('/homeAsp');
  //   }
  // }

  // @HostListener('window:touchstart', ['$event'])
  // onTouchStart(event: TouchEvent) {
  //   this.touchCounter++;

  //   if (this.touchCounter === 3) {
  //     this.globalService.userCountry = 'NOCO';
  //     this.userLocality = false;
  //     this.route.navigateByUrl('/homeAsp');
  //     this.resetTouchCounter();
  //   } else if (this.touchCounter === 4) {
  //     this.globalService.userCountry = 'CO';
  //     this.userLocality = true;
  //     this.route.navigate(['/']);
  //     this.resetTouchCounter();
  //   } else {
  //     this.resetTouchTimeout();
  //   }
  // }

  // resetTouchTimeout() {
  //   if (this.touchTimeout) {
  //     clearTimeout(this.touchTimeout);
  //   }
  //   this.touchTimeout = setTimeout(() => {
  //     this.resetTouchCounter();
  //   }, 1000);
  // }

  // resetTouchCounter() {
  //   this.touchCounter = 0;
  //   if (this.touchTimeout) {
  //     clearTimeout(this.touchTimeout);
  //   }
  // }

}
