<div class="row row-cont-fast img-footer">
  <div class="col-sm-6 col-md-6 col-lg-6">
    <div class="text-cont-fast" *ngIf="translate.store.currentLang == 'es'">
      <span>{{"FOOTER.title1" | translate}}</span><br/>
      <span>{{"FOOTER.title2" | translate}} <b>{{"FOOTER.title3" | translate}}</b> </span><br/>
      <span>{{"FOOTER.title4" | translate}} <b>{{"FOOTER.title5" | translate}}</b>{{"FOOTER.title6" | translate}}</span>
    </div>
    <div class="text-cont-fast" *ngIf="translate.store.currentLang == 'en'">
      <span>{{"FOOTER.title1" | translate}}</span><br/>
      <span><b>{{"FOOTER.title2" | translate}}</b> {{"FOOTER.title3" | translate}} <b>{{"FOOTER.title4" | translate}}</b> </span><br/>
      <span>{{"FOOTER.title5" | translate}}{{"FOOTER.title6" | translate}}</span>
    </div>
  </div>
  <div class="col-sm-6 col-md-6 col-lg-6 col-contact-mobile">
    <div class="title-cont-fast">
      <a href="/contact">{{"FOOTER.subTitle" | translate}}</a>
    </div>
    <div class="item-cont-fast">
      <div class="item-text-cont-fast">
        <span>{{"FOOTER.address" | translate}}</span> <br/>
        <span>{{"FOOTER.city" | translate}}</span> <!-- <br/>
        <span>{{"FOOTER.country" | translate}}</span> -->
      </div>
      <div class="item-i-cont-fast">
        <mat-icon class="icon">room</mat-icon>
      </div>
    </div>
    <!-- <div class="item-cont-fast">
      <div class="item-text-cont-fast">
        <span>{{"FOOTER.celphone" | translate}}</span> <br/>
        <span>{{"FOOTER.phone" | translate}}</span>
      </div>
      <div class="item-i-cont-fast">
        <mat-icon class="icon">phone_iphone</mat-icon>
      </div>
    </div> -->
    <div class="item-cont-fast">
      <div class="item-text-cont-fast">
        <span>{{"FOOTER.email" | translate}}</span> <br/>
        <span>{{"FOOTER.email2" | translate}}</span>
      </div>
      <div class="item-i-cont-fast">
        <mat-icon class="icon">email</mat-icon>
      </div>
    </div>
  </div>
</div>
