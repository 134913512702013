import { Component, OnInit } from '@angular/core';
declare var bodymovin:any;
@Component({
  selector: 'app-our-solutions',
  templateUrl: './our-solutions.component.html',
  styleUrls: ['./our-solutions.component.css']
})
export class OurSolutionsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.loadAnimationDiscover();
    }, 100);
  }
   
  loadAnimationDiscover(){
    var animationDiscover = bodymovin.loadAnimation({
      container: document.getElementById('animationDiscover'),
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: '../../../assets/pageForeignAsp/animations/discoverInnovate2.json'
    })

    var animationDiscoverMobile = bodymovin.loadAnimation({
      container: document.getElementById('animationDiscoverMobile'),
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: '../../../assets/pageForeignAsp/animations/discoverInnovate2.json'
    })
  }

}
