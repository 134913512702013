import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-methodology-soft',
  templateUrl: './methodology-soft.component.html',
  styleUrls: ['./methodology-soft.component.css']
})
export class MethodologySoftComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
