import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ESMap, HasExpressionInitializer } from 'typescript';

@Component({
  selector: 'app-jsp7-det',
  templateUrl: './jsp7-det.component.html',
  styleUrls: ['./jsp7-det.component.css']
})
export class Jsp7DetComponent implements OnInit {

  constructor(private translate: TranslateService) { }

  act: Boolean;
  cont: Boolean;
  contra: Boolean;
  cxc: Boolean;
  cxp: Boolean;
  inv: Boolean;
  nom: Boolean;
  per: Boolean;
  tes: Boolean;
  plan: Boolean;
  imp: Boolean;
  gest: Boolean;
  ppto: Boolean;
  wflow: Boolean;
  disp: Boolean;
  pptopriv: Boolean;
  vermas: Boolean=false;

  json;


  selectedBtn(seleted) {
    this.act = false;
    this.cont = false;
    this.contra = false;
    this.cxc = false;
    this.cxp = false;
    this.inv = false;
    this.nom = false;
    this.per = false;
    this.tes = false;
    this.plan = false;
    this.imp = false;
    this.gest = false;
    this.ppto = false;
    this.wflow = false;
    this.disp = false;
    this.pptopriv = false;


    if (seleted == 'act') {
      this.act = true;
      this.vermas=false;
    }
    else if (seleted == 'cont') {
      this.cont = true;
      this.vermas=false;
    }
    else if (seleted == 'contra') {
      this.contra = true;
      this.vermas=false;
    }
    else if (seleted == 'cxc') {
      this.cxc = true;
      this.vermas=false;
    }
    else if (seleted == 'cxp') {
      this.cxp = true;
      this.vermas=false;
    }
    else if (seleted == 'inv') {
      this.inv = true;
      this.vermas=false;
    }
    else if (seleted == 'nom') {
      this.nom = true;
      this.vermas=false;
    }
    else if (seleted == 'per') {
      this.per = true;
      this.vermas=false;
    }
    else if (seleted == 'tes') {
      this.tes = true;
      this.vermas=false;
    }
    else if (seleted == "plan") {
      this.plan = true;
      this.vermas=false;
    }
    else if (seleted == "imp") {
      this.imp = true;
      this.vermas=false;
    }
    else if (seleted == "gest") {
      this.gest = true;
      this.vermas=false;
    }
    else if (seleted == "ppto") {
      this.ppto = true;
      this.vermas=false;
    }
    else if (seleted == "wflow") {
      this.wflow = true;
      this.vermas=false;
    }
    else if (seleted == "disp") {
      this.disp = true;
      this.vermas=false;
    }
    else if (seleted == "pptopriv") {
      this.pptopriv = true;
      this.vermas=false;
    }
  }
  mostraTexto(mostrar){
    if(mostrar==true){
      this.vermas=true
      this.getN(this.json,1);
    }else if(mostrar==false){
      this.vermas=false
      this.getN(this.json,0);
    }
    return this.vermas;
  }

  ngOnInit() {
    this.selectedBtn('nom');
  }

  getN(list,a:number){
    this.json=list;
    if(a==0){
      return list.slice(0,10);
    }else if(a==1){
      return list;
    }
  }

}
