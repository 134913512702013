<section class="containerHome paddingForeign">
    <article class="sectionHome flex40">
        <div class="titleSoftware">
            <h1 class="title1"> {{"pageAspForeign.HOME.title1" | translate}}</h1>
            <h1 class="title2">{{"pageAspForeign.HOME.title2" | translate}}</h1>
            <h1 class="title3">{{"pageAspForeign.HOME.title3" | translate}}</h1>
        </div>

        <div class="classSectionImgMobile">
            <!-- <img [src]="gifUrl" alt="Animating Image" class="imgHomeMobile"> -->
            <div id="animationHome1" class="imgHomeMobile"> </div>
        </div>

        <div class="subTitel">
            <div class="containerTxtSubtitel">
                <p class="subtitel1">{{"pageAspForeign.HOME.subTitel1" | translate}}</p>
                <p class="txtSubtitel"  style="white-space: pre-line;">
                    {{"pageAspForeign.HOME.txtSubtitel" | translate}}
                </p>
            </div>
           
            <!-- <div id="animationLigth"  class="imgLightBulb"></div> -->
            <!-- <img src="../../../assets/pageForeignAsp/homeForeign/img-lightbulb.svg" class="imgLightBulb" alt="Animating Image"> -->
        </div>

        <div class="btnCenterMobile">
            <!-- <div class="btnRadius buttonHere" matRipple>{{"pageAspForeign.HOME.btnClickHere" | translate}}</div> -->
        </div>
    </article>

    <article class="sectionHome flex60">
        <!-- <img src="../../../assets/pageForeignAsp/homeForeign/img_Are you2.svg" alt="Animating Image"> -->
         <!-- <img [src]="gifUrl" alt="Animating Image" class="imgHome"> -->
         <div id="animationHome2" class="imgHome"> </div>
    </article>
</section>