

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppRoutingModuleForeign, routingComponentsForeign } from './routing-module-foreign';
import { PageAspForeignMainComponent } from './pageAspForeign/page-asp-foreign-main/page-asp-foreign-main.component'; // Asegúrate de importar el componente
import { AspForeignHeaderComponent } from './pageAspForeign/headerFolder/asp-foreign-header/asp-foreign-header.component';
import { AspForeignHomeComponent } from './pageAspForeign/asp-foreign-home/asp-foreign-home.component';
import { WhatWeDoComponent } from './pageAspForeign/what-we-do/what-we-do.component';
import { ExperienceComponent } from './pageAspForeign/experience/experience.component';
import { OurServicesComponent } from './pageAspForeign/our-services/our-services.component';
import { OurSolutionsComponent } from './pageAspForeign/our-solutions/our-solutions.component';
import { OurWorkComponent } from './pageAspForeign/our-work/our-work.component';
import { AlliedBrandsComponent } from './pageAspForeign/allied-brands/allied-brands.component';
import { FooterForeignComponent } from './pageAspForeign/footer/footer-foreign.component';
import { ClientsForeignComponent } from './pageAspForeign/clients-foreign/clients-foreign.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatTabsModule } from '@angular/material/tabs';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { MatTooltipModule } from '@angular/material/tooltip';
// import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
// import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { GlobalsService } from './globals.service';
import { GuestInfoService } from './services/guest-info.service';
import { SendMailService } from './services/send-mail.service';
import { MyHammerConfig } from './app.module';
import { TranslateModule } from '@ngx-translate/core';
import { HeaderAspDesktopComponent } from './pageAspForeign/headerFolder/header-asp-desktop/header-asp-desktop.component';
import { HeaderAspMobileComponent } from './pageAspForeign/headerFolder/header-asp-mobile/header-asp-mobile.component';
import { PageOurWorkComponent } from './pageAspForeign/page-our-work/page-our-work.component';
import { ContactAspForeignComponent } from './pageAspForeign/contact-asp-foreign/contact-asp-foreign.component';
import {MatRadioModule} from '@angular/material/radio';
import { PrivacyPolicyComponent } from './pageAspForeign/privacy-policy/privacy-policy.component';
import { PageQboxComponent } from './pageAspForeign/page-qbox/page-qbox.component';
import { CarouselModule } from 'primeng/carousel';

@NgModule({
  declarations: [
    PageAspForeignMainComponent,
    AspForeignHomeComponent,
    AspForeignHeaderComponent,
    WhatWeDoComponent,
    ExperienceComponent,
    OurServicesComponent,
    OurSolutionsComponent,
    OurWorkComponent,
    AlliedBrandsComponent,
    FooterForeignComponent,
    ClientsForeignComponent,
    HeaderAspDesktopComponent,
    HeaderAspMobileComponent,
    PageOurWorkComponent,
    ContactAspForeignComponent,
    routingComponentsForeign,
    PrivacyPolicyComponent,
    PageQboxComponent

], // Declara el componente aquí
  imports: [
    CommonModule,
    MatToolbarModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    MatTabsModule,
    FormsModule,
    MatDialogModule,
    ReactiveFormsModule,
    SlickCarouselModule,
    MDBBootstrapModule.forRoot(),
    HttpClientModule,
    MatTooltipModule,
    TranslateModule,
    MatRadioModule,
    // TranslateModule.forRoot({
    //   loader: {
    //     provide: TranslateLoader,
    //     useFactory: HttpLoaderFactory,
    //     deps: [HttpClient]
    //   }
    // }), 
    MatSidenavModule,
    MatExpansionModule,
    MatCheckboxModule,
    MatRippleModule,
    NgbModule,
    AppRoutingModuleForeign,
    CarouselModule,
    ],
    exports: [
        PageAspForeignMainComponent,
        AspForeignHomeComponent,
        AspForeignHeaderComponent,
        WhatWeDoComponent,
        ExperienceComponent,
        OurServicesComponent,
        OurSolutionsComponent,
        OurWorkComponent,
        AlliedBrandsComponent,
        FooterForeignComponent,
        ClientsForeignComponent,
        AppRoutingModuleForeign,
        PrivacyPolicyComponent
      ],
    providers: [GlobalsService,GuestInfoService, SendMailService
    ]
})

export class ForeignModule {}

//Function Translate
// export function HttpLoaderFactory(http: HttpClient) {
//     return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
//   }
