import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-welcome-jforms',
  templateUrl: './welcome-jforms.component.html',
  styleUrls: ['./welcome-jforms.component.css']
})
export class WelcomeJformsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
