import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GlobalsService } from 'src/app/globals.service';
import { AppRoutingModuleForeign } from 'src/app/routing-module-foreign';

@Component({
  selector: 'app-header-asp-desktop',
  templateUrl: './header-asp-desktop.component.html',
  styleUrls: ['./header-asp-desktop.component.css']
})
export class HeaderAspDesktopComponent implements OnInit {

  showFlag1: boolean = true;
  isHovered: boolean = false;
  language: string = "";

  
  public region:string;

  constructor(private translate : TranslateService, private router: Router,
    public globals:GlobalsService) { 
    this.translate.addLangs(['es','en']);
   this.translate.setDefaultLang('en');
     this.language = 'en'
   this.language = navigator.language.substr(0,2);
    if (this.language !='es') {
      this.globals.mostrarIngles=true;
      this.language='en';
    } else{
      this.globals.mostrarIngles=false;
      this.language='es';
    }
    translate.use(this.language);
    }

  ngOnInit(): void {
  }

  changeLanguage(language) : void{
    this.globals.mostrarIngles=!this.globals.mostrarIngles;
    this.translate.use(language);
    this.language = language;
  }

  Ip(){
    this.globals.getIp().subscribe(
      response=>{
      }
    );
  }

  infoIp(){
    this.globals.identityIP().subscribe( (response:any)=>{  
      this.region=response.geoplugin_continentName;
      },
      err=>{
        console.log('error');
      }

        );
    
}

startAnimation() {
  this.isHovered = true;
}

stopAnimation() {
  this.isHovered = false;
}

scrollToSection(sectionId: string) {
  const element = document.getElementById(sectionId);
  if (element) {
    // this.selectNav = sectionId;
    // element.scrollIntoView({ behavior: 'smooth' });

    this.globals.selectNav = sectionId;
    
    // Obtén el tamaño de la ventana
    const windowHeight = window.innerHeight;

    // Obtén la posición actual del elemento
    const elementPosition = element.getBoundingClientRect();

    // Calcula la posición de desplazamiento con la corrección de 150px
    const scrollPosition = elementPosition.top + window.scrollY - (windowHeight / 2) + 190;

    // Realiza el desplazamiento suave
    window.scrollTo({ top: scrollPosition, behavior: 'smooth' });
  }
}

scrollToSection2(sectionId: string, redirectTo: string) {
  const element = document.getElementById(sectionId);
  if (element) {
    // Obtén el tamaño de la ventana
    const windowHeight = window.innerHeight;

    // Obtén la posición actual del elemento
    const elementPosition = element.getBoundingClientRect();

    // Verifica si la sección está presente en la pantalla
    if (
      elementPosition.bottom >= 0 &&
      elementPosition.top <= windowHeight
    ) {
      // La sección está presente en la pantalla, realiza el desplazamiento suave
      window.scrollTo({
        top: elementPosition.top + window.scrollY,
        behavior: 'smooth'
      });
    } else {
      // La sección no está presente en la pantalla, redirige a otra ruta
      this.router.navigate(['/ourWork']);
    }
  }
}

scrollToSection3(sectionId: string) {
  // Redirigir a la ruta deseada
  this.router.navigate(['/homeAsp']).then(() => {
    // Esperar a que Angular termine de procesar la redirección
    setTimeout(() => {
      const element = document.getElementById(sectionId);
      if (element) {
        this.globals.selectNav = sectionId;
        // Realizar el desplazamiento suave después de la redirección
        const windowHeight = window.innerHeight;
        const elementPosition = element.getBoundingClientRect();
        const scrollPosition = elementPosition.top + window.scrollY - (windowHeight / 2) + 260;
        window.scrollTo({ top: scrollPosition, behavior: 'smooth' });
      }
    });
  });
}


selectRoutingNav(sectionId: string){
  if(sectionId == "ourServices" || sectionId == "ourWork" || sectionId == "qbox"){
    window.scrollTo(0, 0);
  }
  this.router.navigateByUrl('/'+ sectionId);
  // this.selectNav = sectionId;
  this.globals.selectNav = sectionId;
}

navigateContact(){
  window.scrollTo(0, 0);
  this.router.navigateByUrl('/contactForeign');
  this.globals.selectNav = "";
}

goHome(){
  window.scrollTo(0, 0);
  this.router.navigateByUrl('/homeAsp');
  this.globals.selectNav = "home";
}
}
