<div class="row row-contact">
  <div class="col-sm-12 col-md-12 col-lg-12 bg-contact col-contact">
    <div class="w-87">
      <h2 class="title-contact" data-aos="fade-right" data-aos-once="true" data-aos-duration="800">{{"CONTACT.titleContact" | translate}}</h2>
      <form class="f-contact" [formGroup]="EmailForm" data-aos="fade-right" data-aos-once="true" data-aos-duration="800">
        <mat-form-field class="inp-contact" appearance="outline">
          <mat-label>{{"CONTACT.fullName" | translate}}</mat-label>
          <input matInput formControlName="NombreCompleto" [(ngModel)]="datos.nombreCompleto" required>
          <mat-error *ngIf="controlsForm.NombreCompleto.errors">
            <strong>{{"CONTACT.errorFullName" | translate}}</strong>
          </mat-error>
        </mat-form-field>
        <mat-form-field class="inp-contact" appearance="outline">
          <mat-label>{{"CONTACT.email" | translate}}</mat-label>
          <input matInput formControlName="Email" [(ngModel)]="datos.email" required>
          <mat-error *ngIf="controlsForm.Email.errors">
            <strong *ngIf="controlsForm.Email.errors.required">{{"CONTACT.errorEmail" | translate}}</strong>
            <strong *ngIf="controlsForm.Email.errors.email">{{"CONTACT.errorEmail2" | translate}}</strong>
          </mat-error>
        </mat-form-field>
        <br>
        <mat-form-field class="inp-contact" appearance="outline">
          <mat-label>{{"CONTACT.tel" | translate}}</mat-label>
          <input type="number" matInput formControlName="Telefono" [(ngModel)]="datos.telefono" min="1" required>
          <mat-error *ngIf="controlsForm.Telefono.errors">
            <strong *ngIf="controlsForm.Telefono.errors.required">{{"CONTACT.errorTel" | translate}}</strong>
            <strong *ngIf="controlsForm.Telefono.errors.min">{{"CONTACT.errorTel2" | translate}}</strong>
          </mat-error>
        </mat-form-field>
        <mat-form-field class="inp-contact" appearance="outline">
          <mat-label>{{"CONTACT.company" | translate}}</mat-label>
          <input matInput formControlName="Empresa" [(ngModel)]="datos.empresa">
        </mat-form-field>
        <br>
        <mat-form-field class="inp-select" appearance="outline">
          <mat-label>{{"CONTACT.request" | translate}}</mat-label>
          <mat-select matInput formControlName="Solicitud" [(ngModel)]="datos.solicitud" required>
            <mat-option *ngIf="!globals.mostrarIngles"  value="E.R.P">{{"CONTACT.erp" | translate}}</mat-option>
            <mat-option value="Desarrollo a la medida">{{"CONTACT.development" | translate}}</mat-option>
            <mat-option value="Migración">{{"CONTACT.migration" | translate}}</mat-option>
            <mat-option value="Infraestructura">{{"CONTACT.infrastructure" | translate}}</mat-option>
          </mat-select>
          <mat-error *ngIf="controlsForm.Solicitud.errors">
            <strong>{{"CONTACT.errorSol" | translate}}</strong>
          </mat-error>
        </mat-form-field>
        <br>
        <mat-form-field class="inp-text-area" appearance="outline">
          <mat-label>{{"CONTACT.message" | translate}}</mat-label>
          <textarea matInput formControlName="Mensaje" rows="4" [(ngModel)]="datos.mensaje" maxlength="300" required></textarea>
          <mat-hint align="end">{{"CONTACT.maxLength" | translate}}</mat-hint>
          <mat-error *ngIf="controlsForm.Mensaje.errors">
            <strong *ngIf="controlsForm.Mensaje.errors.required">{{"CONTACT.errorMsg" | translate}}</strong>
            <strong *ngIf="controlsForm.Mensaje.errors.max">{{"CONTACT.errorMsg2" | translate}}</strong>
          </mat-error>
        </mat-form-field>
        <br>
        <div class="divPolitic divPoliticas">
          <mat-checkbox [(ngModel)]="checkPoliticas" (change)="cambiarCheckPolitica()"></mat-checkbox>
            <span> {{"CONTACT.politicForm" | translate}} </span> 
            <span class="politicasVerMas" (click)="verMasPoliticas()">Ver más...</span>
        </div>   
        <br>
        
        <button class="btn-contact" mat-raised-button (click)="sendMessage()" [disabled]="EmailForm.invalid || checkPoliticas ==false">{{"CONTACT.send" | translate}}</button>
      </form>
    </div>
  </div>
</div>
