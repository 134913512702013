import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DialogContactError, DialogContactSuccess } from 'src/app/contact/form-contact/form-contact.component';
import { GlobalsService } from 'src/app/globals.service';
import { SendMailService } from 'src/app/services/send-mail.service';

@Component({
  selector: 'app-contact-asp-foreign',
  templateUrl: './contact-asp-foreign.component.html',
  styleUrls: ['./contact-asp-foreign.component.css']
})
export class ContactAspForeignComponent implements OnInit {

  constructor(private dialog: MatDialog, private router: Router, private Mail : SendMailService, private fb : FormBuilder, public globals:GlobalsService) { }
  
  //FormControl
  EmailForm = this.fb.group({
    nombreCompleto : new FormControl('',[Validators.required]),
    solicitud: new FormControl('',[Validators.required]),
    email : new FormControl('',[Validators.required, Validators.email]),
    telefono : new FormControl(''),
    empresa : new FormControl(),
    tipoProyecto : new FormControl(''),
    presupuesto: new FormControl(''),
    mensaje : new FormControl('',[Validators.required, Validators.maxLength(300)])
  });
  get controlsForm() { return this.EmailForm.controls; }

  ngOnInit(): void {
  }

  sendMessage(){
    if(this.EmailForm.invalid || (this.controlsForm.nombreCompleto.value as string).trim() == '' || (this.controlsForm.mensaje.value as string).trim() == ''){
      if((this.controlsForm.NombreCompleto.value as string).trim() == ''){
        this.controlsForm.nombreCompleto.setValue('');
      }
      if((this.controlsForm.Message.value as string).trim() == ''){
        this.controlsForm.mensaje.setValue('');
      }
      return;
    }else{
      this.send();
    }

  }

  send(){
    const emailData = this.EmailForm.value;
    this.Mail.sendMail(emailData)
      .subscribe(() => {
        this.openDialog(true);
    });

    this.EmailForm.reset();
  }

  openDialog(boo){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    if(boo){
      this.dialog.open(DialogContactSuccess, dialogConfig);
    }else{
      this.dialog.open(DialogContactError, dialogConfig);
    }
  }

  goPolityPrivacy(){
    window.scrollTo(0, 0);
    this.router.navigateByUrl('/privacyPolicy');
    this.globals.selectNav = ""
  }
  
}
