import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-featured',
  templateUrl: './featured.component.html',
  styleUrls: ['./featured.component.css'],
})
export class FeaturedComponent implements OnInit {

  constructor() { }

  colorlinetab = "#0492ff";

  ngOnInit() {
  }

}
