import { Component, OnInit } from '@angular/core';
import { AppRoutingModuleForeign } from 'src/app/routing-module-foreign';

@Component({
  selector: 'app-page-asp-foreign-main',
  templateUrl: './page-asp-foreign-main.component.html',
  styleUrls: ['./page-asp-foreign-main.component.css']
})
export class PageAspForeignMainComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  

}
