<div class="row row-clients">
  <div class="col-sm-12 col-md-12 col-lg-12 ">
    <h2 class="title-cli" data-aos="fade-up" data-aos-once="true" data-aos-duration="600">{{"HOME.titleClients" | translate}}</h2>
    <ngx-slick-carousel class="carousel "
    #slickModal="slick-carousel" 
    [config]="slideConfig">
<div ngxSlickItem *ngFor="let slide of slides" class="slide borde">
<img src="{{ slide }}" alt="" width="75%">
</div>
</ngx-slick-carousel>
  </div>
  
</div>


