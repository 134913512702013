<div class="row row-team">
  <div class="map-team" data-aos="fade-left" data-aos-once="true" data-aos-duration="800"></div>
  <div class="col-sm-12 col-md-12 col-lg-12 col-team">
    <div class="w-88">
      <div class="title-team">
        <h2 data-aos="fade-up" data-aos-once="true" data-aos-duration="800">{{"HOME.titleTeam" | translate}}</h2>
      </div>
      <div class="p-team">
        <p data-aos="fade-up" data-aos-once="true" data-aos-duration="1000" *ngFor="let p of 'HOME.paragraphTeam' | translate">
          {{p}}
        </p>
      </div>
      <div class="map-team-mobile" data-aos="fade-up" data-aos-once="true" data-aos-duration="1000"></div>
      <div data-aos="fade-up" data-aos-once="true" data-aos-duration="1200"  data-aos-mirror="false">
        <div class="timer-team">
          <div class="y-e-p">
            <span class="year"> +{{years}} <!-- + 20 años de experiencia --></span>
            <span class="text">{{"HOME.yearTeam" | translate}}</span>
          </div>
          <hr class="hr-ver">
          <div class="y-e-p">
            <span class="year">+{{ing}} <!-- + 60 ingenieros --> </span>
            <span class="text">{{"HOME.engineerTeam" | translate}}</span>
          </div>
          <hr class="hr-ver">
          <div class="y-e-p">
            <span class="year">+{{proy}}</span>
            <span class="text">{{"HOME.proyectTeam" | translate}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
