<div class="row row-jsp7">
  <div class="col-sm-12 col-md-12 col-lg-12 col-jsp7 bg-jsp7">
    <div class="w-88 jsp7" data-aos="fade-left" data-aos-once="true" data-aos-duration="800">
      <h2>{{"ERP.titleJsp7" | translate}}</h2>
      <div class="icon-jsp7"></div>
      <div class="paragraph-jsp7">
        <p>{{"ERP.paragraphJsp7" | translate}}</p>
      </div>
    </div>
  </div>
</div>
