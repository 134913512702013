<section class="containerOurWork paddingForeign">
    <article class="contentText">
        <div class="sectionTxtOne">
            <label class="labelWork">{{"pageAspForeign.ourWork.label" | translate}}</label>
            <h2 class="titleWork" style="white-space: pre-line;">
                {{"pageAspForeign.ourWork.title" | translate}}
            </h2>
        </div>

        <div class="sectionTxtTwo">
            <p class="txtWork"> {{"pageAspForeign.ourWork.txt" | translate}}  </p>
            <div class="btnRadius buttonWork" (click)="navigateOurWork()" matRipple>{{"pageAspForeign.ourWork.btn" | translate}}</div>
        </div>
        
    </article>
    
    <article class="contentRigth">
        <!-- <img src="../../../assets/pageForeignAsp/ourWork/img-EXPERTS-AVIATION.svg" class="imgAviation"> -->
        <div id="animationAviation"  class="imgAviation" alt="Aviation"> </div>
    </article>
</section>
 
<section class="containerOurWorkMobile">
    <article class="sectionTitle">
        <div class="sectionTxtOne">
            <label class="labelWork">{{"pageAspForeign.ourWork.label" | translate}}</label>
            <h2 class="titleWork" style="white-space: pre-line;">
                {{"pageAspForeign.ourWork.title" | translate}}
            </h2>
        </div>
    </article>

    <article class="sectionContent">
        <div class="sectionTxtTwoMobile">
            <p class="txtWorkMobile"> {{"pageAspForeign.ourWork.txt" | translate}}  </p>
        </div>

        <article class="contentRigthMobile">
            <!-- <img src="../../../assets/pageForeignAsp/ourWork/img-EXPERTS-AVIATION.svg" class="imgAviationMobile"> -->
            <div id="animationAviationMobile"  class="imgAviationMobile" alt="Aviation"> </div>
        </article>
    </article>

    <article class="sectionButton">
        <div class="btnRadius buttonWorkMobile" (click)="navigateOurWork()" matRipple>{{"pageAspForeign.ourWork.btn" | translate}}</div>
    </article>
</section>