import { Component, OnChanges, OnInit } from '@angular/core';
import { GuestInfoService } from '../../services/guest-info.service';
import{GlobalsService} from'../../globals.service';

@Component({
  selector: 'app-customers-segment',
  templateUrl: './customers-segment.component.html',
  styleUrls: ['./customers-segment.component.css']
})
export class CustomersSegmentComponent implements OnInit {

  constructor(private guestService : GuestInfoService, public globals:GlobalsService) { }
  ip;
  region;
  serv : Boolean;

  guestInfo(){
    this.guestService.getGuestIp()
    .subscribe(result => {
      this.ip = result;
      this.ip = this.ip.ip;
    })

    this.guestService.getGuestInfo(this.ip)
    .subscribe(result => {
      this.region = result;
      this.region = this.region.geoplugin_continentName;
      if(this.region != 'South America'){
        this.globals.mostrarIngles;
       }else{
        this.globals.mostrarIngles;
        }
      console.log('mostrar en ingles', this.globals.mostrarIngles);
    })
   }

  others : Boolean;
  south: Boolean;
  north : Boolean;
  publico: Boolean;
  privado: Boolean;
  internacional: Boolean;

  
  slides = [];
  slidesPriv = [];
  slidesIntl = [];
  slidesNorth = [
    {img: "./assets/clients/internacional/client1.png"},
    {img: "./assets/clients/internacional/client13.png"},
    {img: "./assets/clients/internacional/client18.png"},
    {img: "./assets/clients/internacional/client17.png"},
    {img: "./assets/clients/internacional/client16.png"},
    {img: "./assets/clients/internacional/client10.png"},
    {img: "./assets/clients/internacional/client12.png"},
  ];
  slidesSouth = [
    {img: "./assets/clients/internacional/client3.png"},
    {img: "./assets/clients/internacional/client4.png"},
    {img: "./assets/clients/internacional/client5.png"},
    {img: "./assets/clients/internacional/client6.png"},
    {img: "./assets/clients/internacional/client7.png"},
    {img: "./assets/clients/internacional/client8.png"},
    {img: "./assets/clients/internacional/client9.png"},
    {img: "./assets/clients/internacional/client11.png"},
    {img: "./assets/clients/internacional/client14.png"},
    {img: "./assets/clients/internacional/client15.png"},
    {img: "./assets/clients/privado/client1.png"},
    {img: "./assets/clients/privado/client2.png"},
    {img: "./assets/clients/privado/client3.png"},
    {img: "./assets/clients/privado/client4.png"},
    {img: "./assets/clients/privado/client5.png"},
    {img: "./assets/clients/privado/client6.png"},
    {img: "./assets/clients/privado/client7.png"},
    {img: "./assets/clients/privado/client8.png"},
    {img: "./assets/clients/privado/client9.png"},
    {img: "./assets/clients/privado/client10.png"},
    {img: "./assets/clients/privado/client11.png"},
    {img: "./assets/clients/privado/client12.png"},
    {img: "./assets/clients/privado/client13.png"},
    {img: "./assets/clients/privado/client54.png"},
    {img: "./assets/clients/privado/client55.png"},
    {img: "./assets/clients/privado/client56.png"},
  ];
  slidesOthers = [
    {img: "./assets/clients/internacional/client2.png"},
    {img: "./assets/clients/internacional/client19.png"},
  ];

  slideConfig = {
    "slidesToShow": 4,
    "slidesToScroll": 4,
    "autoplay": true,
    "infinite": true,
    "speed": 2000,
    "autoplaySpeed": 2000,
    "slidesPerRow": 1,
    "swipeToSlide": true,
    "arrows": false
  };


  /*Local
  Clients(){
    for (let i = 1; i <= 10; i++) {
      this.slides.push("../../../assets/clients/client"+i+".png");
    }
  }*/
   /*  */
  /*Productivo*/
  Clients(){
    for (let i = 1; i <= 9; i++) {
      this.slides.push("./assets/clients/publico/client"+i+".png");
    }
    for (let i = 1; i <= 56; i++){
      this.slidesPriv.push("./assets/clients/privado/client"+i+".png"); 
    }
    for (let i = 1; i <= 19; i++){
      this.slidesIntl.push("./assets/clients/internacional/client"+i+".png"); 
    }
/*     for (let i = 1; i <= 11; i++){
      this.slidesNorth.push("./assets/clients/internacional/client"+i+".png"); 
    } */
/*     for (let i = 1; i <= 37; i++){
      this.slidesSouth.push("./assets/clients/privado/client"+i+".png"); 
    } */
/*     for (let i = 1; i <= 14; i++){
      this.slidesOthers.push(""); 
    } */
  }

  selectedCustomer(customer){
    if(customer == 'publico'){
      this.publico = true;
      this.privado = false;
      this.internacional = false;
    }else if(customer == 'privado'){
      this.publico = false;
      this.privado = true;
      this.internacional = false;
    }else if(customer == 'internacional'){
      this.publico = false;
      this.privado = false;
      this.internacional = true;
    }

  }
  selectedCustomerEng(customer){
    if(customer == 'north'){
      this.north = true;
      this.south = false;
      this.others = false;
    }else if(customer == 'south'){
      this.north = false;
      this.south = true;
      this.others = false;
    }else if(customer == 'others'){
      this.north = false;
      this.south = false;
      this.others = true;
    }
  }

  ngOnInit() {
    this.selectedCustomer('publico');
    this.selectedCustomerEng('north');
    this.Clients();
    this.guestInfo();
  }
  ngOnChanges(){
    this.guestInfo();
  }
}
