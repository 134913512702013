<div class="row row-ana">
    <div class="col-sm-12 col-md-12 col-lg-6">
        <div class="cont-ana" data-aos="fade-right" data-aos-once="true" data-aos-duration="1400">
            <h2>{{"SOFTWARE.titleAnalysis" | translate}}</h2>
            <ul style="text-align: justify;">
                <li  *ngFor="let p of 'SOFTWARE.paragraphAnalysis' | translate">{{p}}</li>
            </ul>


        </div>
    </div>

    <div class="col-sm-12 col-md-12 col-lg-6 col-img-ana-soft">
        <div class="bg-ana-soft" data-aos="fade-left" data-aos-once="true" data-aos-duration="1400"></div>
    </div>
</div>
