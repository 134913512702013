<div class="contentOurServices">

  <section>
    <div class="contentTitle paddingForeign">
        <article class="contentText ">
            <h2 class="title whiteSpace">
                {{"pageAspForeign.ourServices.title" | translate}}
            </h2>
            <div class="btnRadius buttonHelp" (click)="navigateContact()" matRipple>{{"pageAspForeign.EXPERIENCE.btn" | translate}}</div>
        </article>
    </div>

    <div  class="sectionOutService paddingForeign">
        <div class="contentOurServiceRow1">
            <p class="txt whiteSpace">
                {{"pageAspForeign.ourServices.txt" | translate}}
            </p>
            <article class="contentImg">
                <!-- <img src="../../../assets/pageForeignAsp/ourServices/icon-ourService.svg" alt="Our Services" class="imgBox"> -->
                <div id="ligthOurServices" class="imgBox" alt="Ligth"> </div>
            </article>
            </div>
        
            <article class="contentInfoNewEra">
                <h3 class="titleInfoNewEra whiteSpace">{{"pageAspForeign.ourServices.titleIntroducing" | translate}} </h3>
                <p class="txtInfoNewEra">{{"pageAspForeign.ourServices.txtIntroducing" | translate}}</p>
            </article>
    </div>
  </section>

  <section class="sectionSoftwareSolutions paddingForeign">
    <h3 class="titlesectionSoftwareSolutions">{{"pageAspForeign.ourServices.titleSoftwareSolutions" | translate}}</h3>
    <article class="row1DirectDeveloper">
        <div class="imgRow1">
            <!-- <img src="../../../assets/pageForeignAsp/ourServices/img-Direct-Developer.png" alt="Direct Developer" class="imgDirectDeveloper"> -->
            <div id="developer1"  class="imgDirectDeveloper" alt="Developer"> </div>
        </div>

        <div class="txtRow1">
            <div class="flex-column">
                <label class="titleDirectDeveloper2">
                    {{"pageAspForeign.ourServices.directDev" | translate}} <br>
                </label>
                <span  class="titleDirectDeveloper2">{{"pageAspForeign.ourServices.management" | translate}}</span>
            </div>
            <p class="txtDirectDeveloper"> 
                {{"pageAspForeign.ourServices.txtDirectDev" | translate}}
            </p>
        </div>
    </article>

    <article class="row1DirectDeveloper">
 
        <div class="txtRow1">
            <div class="flex-column">
                <label class="titleDirectDeveloper2">
                    {{"pageAspForeign.ourServices.guideProduct" | translate}}
                </label>
                <span  class="titleDirectDeveloper2"> {{"pageAspForeign.ourServices.guideProduct2" | translate}}</span>
                <span  class="titleDirectDeveloper2">{{"pageAspForeign.ourServices.guideProduct3" | translate}}</span>    
            </div>
            
            <p class="txtDirectDeveloper"> 
                {{"pageAspForeign.ourServices.txtguideProduct" | translate}}
            </p>
        </div>

        <div class="imgRow1">
            <!-- <img src="../../../assets/pageForeignAsp/ourServices/img-Guide-product.png" alt="Direct Developer" class="imgDirectDeveloper"> -->
            <div id="developer2" class="imgDirectDeveloper" alt="GuidesProduct"> </div>
        </div>
    </article>

    <article class="contentImgPuzzleProduct">
        <img src="../../../assets/pageForeignAsp/ourServices/img-puzzle-product.png" *ngIf="globals.mostrarIngles" alt="Puzzle product" class="imgPuzzleProduct">
        <img src="../../../assets/pageForeignAsp/ourServices/img-puzzle-product-spanish.svg" *ngIf="!globals.mostrarIngles" alt="Puzzle product" class="imgPuzzleProduct">
    </article>

  </section>

  <section class="developmentStagesSection paddingForeign">
    <div class="row1developmentStagesSection">
        <div class="contentTitleRow1Dev">
            <h3 class="titledevelopmentStagesSection">
                <span class="titledevelopmentStagesSection2"> {{"pageAspForeign.ourServices.txtStack1" | translate}}</span> <br>
                {{"pageAspForeign.ourServices.txtStack2" | translate}} <br>
                {{"pageAspForeign.ourServices.txtStack3" | translate}}
            </h3>
        </div>
        <div class="contentInfoRow1Dev">
            <div class="btnRadius buttonDev" matRipple>{{"pageAspForeign.EXPERIENCE.btn" | translate}}</div>
            <p class="txtdevelopmentStagesSection">{{"pageAspForeign.ourServices.txtStages" | translate}} </p>
        </div>
    </div>

    <div class="row2developmentStagesSection">
        <img src="../../../assets/pageForeignAsp/ourServices/img-fullStack-development.png" *ngIf="globals.mostrarIngles" alt="FullStackDevelopment" class="imgFullStackDevelopment">
        <img src="../../../assets/pageForeignAsp/ourServices/img-fullStack-development-spanish.svg" *ngIf="!globals.mostrarIngles" alt="FullStackDevelopment" class="imgFullStackDevelopment">
    </div>
  </section>

  <section class="sectionContentTxtFinish paddingForeign">
    <label class="titlesFinish"> {{"pageAspForeign.ourServices.txtEnd1" | translate}}  </label>

    <span class="txtFinish"> {{"pageAspForeign.ourServices.txtEnd2" | translate}} </span>

    <label class="titlesFinish"> {{"pageAspForeign.ourServices.txtEnd3" | translate}}  </label>

    <div class="btnRadius buttonHelp" (click)="navigateContact()" matRipple>{{"pageAspForeign.extras.contactUs" | translate}}</div>
  </section>

</div>