<!--
  <div class="col-sm-12 col-md-12 col-lg-12 bg-welcome">
    <hr class="hr-welcome">
    <h6 class="title-welcome" data-aos="fade-up" data-aos-once="true" data-aos-duration="800">{{"HOME.subTitleWelcome" | translate}}</h6>
    <div class="title-mov">
        <h1 data-aos="fade-up" data-aos-once="true" data-aos-duration="1200">{{"HOME.titleWelcome" | translate}}</h1>
    </div>
    <div class="text-mov" data-aos="fade-down" data-aos-once="true" data-aos-duration="1400">
      <ul>
        <li *ngFor="let p of 'HOME.paragraphWelcome1' | translate">{{p}}</li>
    </ul>
      <span>{{"HOME.paragraphWelcome2" | translate}}</span>
    </div>
    <div class="bt-contact">
      <button mat-flat-button data-aos="zoom-in" data-aos-once="true" data-aos-duration="1800" [routerLink]="['/contact']">{{"HOME.buttonWelcome" | translate}}</button>
    </div>
  </div>
-->

<div class="row-welcome col-sm-12 col-md-12 col-lg-12" style="padding: 0% !important; height: 100%">
  <div class="d-carrousel-proj-soft animated fadeIn">
    <mdb-carousel id="carousel" class="carousel slide carousel-fade carousel-height carousel-projects" [animation]="'fade'">

      <mdb-carousel-item *ngIf="!globals.mostrarIngles">
        <div class="col-sm-12 col-md-12 col-lg-12 slider-welcome-2">
          <div class="row-title">
            <div class="col-sm-12 col-md-12 col-lg-7">
              <h6 class="title-welcome" data-aos="fade-up" data-aos-once="true" data-aos-duration="800">
                {{ "HOME.subTitleWelcome" | translate }}
              </h6>
              <hr class="hr-welcome" />
              <div class="title-mov" data-aos="fade-down" data-aos-once="true" data-aos-duration="1400">
                <ul>
                  <li *ngFor="let p of 'HOME.paragraphWelcome1' | translate">
                    {{ p }}
                  </li>
                </ul>
              </div>
              <div class="bt-contact">
                <button mat-flat-button data-aos="zoom-in" data-aos-once="true" data-aos-duration="1800" [routerLink]="['/contact']">
                  {{ "HOME.buttonWelcome" | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </mdb-carousel-item>

      <mdb-carousel-item *ngIf="!globals.mostrarIngles">
        <div class="col-sm-12 col-md-12 col-lg-12 slider-welcome">
          <div class="row-title">
            <div class="col-sm-12 col-md-12 col-lg-8">
              <h6 class="title-welcome" data-aos="fade-up" data-aos-once="true" data-aos-duration="800">
                {{ "HOME.subTitleWelcome" | translate }}
              </h6>
              <hr class="hr-welcome"/>
              <div class="title-mov">
                <h1 data-aos="fade-up" data-aos-once="true" data-aos-duration="1200">
                  {{ "HOME.titleWelcome" | translate }}
                </h1>
              </div>
              <div class="bt-contact">
                <button mat-flat-button data-aos="zoom-in" data-aos-once="true" data-aos-duration="1800" [routerLink]="['/contact']">
                  {{ "HOME.buttonWelcome" | translate }}
                </button>
              </div>
            </div>
            <div class="col-sm-12 col-md-2 col-lg-5" id="vacio"></div>
          </div>
        </div>
      </mdb-carousel-item>

      <mdb-carousel-item *ngIf="!globals.mostrarIngles">
        <div class="col-sm-12 col-md-12 col-lg-12 slider-welcome3">
          <div class="row-title">
            <div class="col-sm-12 col-md-12 col-lg-7">
              <h6 class="title-welcome" data-aos="fade-up" data-aos-once="true" data-aos-duration="800">
                {{ "HOME.subTitleWelcome" | translate }}
              </h6>
              <hr class="hr-welcome"/>
              <div class="title-mov">
                <h1 data-aos="fade-up" data-aos-once="true" data-aos-duration="1200">
                  {{ "HOME.titleWelcome3" | translate }}
                </h1>
              </div>
              <div class="bt-contact">
                <button mat-flat-button data-aos="zoom-in" data-aos-once="true" data-aos-duration="1800" [routerLink]="['/erp']">
                  {{ "HOME.buttonWelcome2" | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </mdb-carousel-item>
      <!-- inglés -->
      <mdb-carousel-item *ngIf="globals.mostrarIngles">
        <div class="col-sm-12 col-md-12 col-lg-12 slider-welcome">
          <div class="row-title">
            <div class="col-sm-12 col-md-12 col-lg-7">
              <h6 class="title-welcome" data-aos="fade-up" data-aos-once="true" data-aos-duration="800">
                {{ "HOME.subTitleWelcome" | translate }}
              </h6>
              <hr class="hr-welcome"/>
              <div class="title-mov">
                <h1 data-aos="fade-up" data-aos-once="true" data-aos-duration="1200">
                  {{ "HOME.titleWelcome" | translate }}
                </h1>
              </div>
              <div class="bt-contact">
                <button mat-flat-button data-aos="zoom-in" data-aos-once="true" data-aos-duration="1800" [routerLink]="['/contact']">
                  {{ "HOME.buttonWelcome" | translate }}
                </button>
              </div>
            </div>
            <div class="col-sm-12 col-md-2 col-lg-5" id="vacio"></div>
          </div>
        </div>
      </mdb-carousel-item>

      <mdb-carousel-item *ngIf="globals.mostrarIngles">
        <div class="col-sm-12 col-md-12 col-lg-12 slider-welcome-2">
          <div class="row-title">
            <div class="col-sm-12 col-md-12 col-lg-7">
              <h6 class="title-welcome" data-aos="fade-up" data-aos-once="true" data-aos-duration="800">
                {{ "HOME.subTitleWelcome" | translate }}
              </h6>
              <hr class="hr-welcome" />
              <div class="title-mov" data-aos="fade-down" data-aos-once="true" data-aos-duration="1400">
                <ul>
                  <li *ngFor="let p of 'HOME.paragraphWelcome1' | translate">
                    {{ p }}
                  </li>
                </ul>
              </div>
              <div class="bt-contact">
                <button mat-flat-button data-aos="zoom-in" data-aos-once="true" data-aos-duration="1800" [routerLink]="['/contact']">
                  {{ "HOME.buttonWelcome" | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </mdb-carousel-item>
      
    </mdb-carousel>
  </div>
</div>
