<section class="containerClients">
    <article class="content" *ngIf="clientSelected">
        <h2 class="titleSection">
            {{"pageAspForeign.clients.title1" | translate}} <br>
            {{"pageAspForeign.clients.title2" | translate}}  <br>
            {{"pageAspForeign.clients.title3" | translate}}
        </h2>

        <div class="clients">
          <div class="contentRigth">
            <div class="dialogClient">
                <div class="descriptionClientContent">
                    <p class="descriptionClient">
                        <!-- {{translateDescription(clientSelected.description)}} -->
                        {{ translateAttribute(clientSelected.descriptionKey) }}
                    </p>
                </div>
            </div>
            
            <div class="infoClients">
                <img class="imgClient"  [src]="'../../../assets/pageForeignAsp/clientsForeign/' + clientSelected.url">

                <div class="txtClients">
                    <label class="nameClient">{{clientSelected.name}}</label>
                    <p class="infoClient"> {{ translateAttribute(clientSelected.infoKey) }} </p>
                </div>
            </div>
           </div>
        </div>
    </article>

    <div class="clientsList">
        <div *ngFor="let client of clientList" (click)="clientSelected = client" >
            <img  class="client" [ngClass]="{'userSelected' : clientSelected === client}" [src]="'../../../assets/pageForeignAsp/clientsForeign/' + client.url">
        </div>
    </div>
</section>

<section class="mobileVersion">
    <div class="TitleSectionMobile">
        <h2 class="titleSectionMobile">
            {{"pageAspForeign.clients.title1" | translate}} <br>
            {{"pageAspForeign.clients.title2" | translate}}  <br>
            {{"pageAspForeign.clients.title3" | translate}}
        </h2>
    </div>

    <div class="containerInfoMobile" *ngIf="clientSelected">
        <article class="dialogClientMobile">
            <div class="descriptionClientContentMobile">
                <p class="descriptionClient">
                    {{ translateAttribute(clientSelected.descriptionKey) }}
                </p>
            </div>
        </article>

        <div class="infoClientsMobile">
            <img class="imgClient"  [src]="'../../../assets/pageForeignAsp/clientsForeign/' + clientSelected.url">

            <div class="txtClients">
                <label class="nameClient">{{clientSelected.name}}</label>
                <p class="infoClient"> {{ translateAttribute(clientSelected.infoKey) }} </p>
            </div>
        </div>

        <div class="clientsListMobile">
            <div *ngFor="let client of clientList" (click)="clientSelected = client" >
                <img  class="clientMobile" [ngClass]="{'userSelected' : clientSelected === client}" [src]="'../../../assets/pageForeignAsp/clientsForeign/' + client.url">
            </div>
        </div>
    </div>
</section>