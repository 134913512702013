<div class="containerAllContact">
    <div class="containerContact">
        <h2 class="titleContact noMargin">{{"pageAspForeign.CONTACT.titleContact" | translate}}</h2>
        <form class="f-contact" [formGroup]="EmailForm">
        <div class="row3Input">
            <mat-form-field class="custom-form-field" appearance="legacy" class="row30">
                <mat-label> {{"pageAspForeign.CONTACT.titleFirstName" | translate}}</mat-label>
                <input matInput class="custom-input" formControlName="nombreCompleto"  required>
                <mat-error *ngIf="controlsForm.nombreCompleto.errors">
                  <strong>{{"pageAspForeign.CONTACT.firtName" | translate}}</strong>
                </mat-error>
              </mat-form-field>
    
              <mat-form-field appearance="legacy" class="row30">
                <mat-label>{{"pageAspForeign.CONTACT.titleContact" | translate}}</mat-label>
                <input matInput formControlName="solicitud"  required>
                <mat-error *ngIf="controlsForm.solicitud.errors">
                  <strong>{{"pageAspForeign.CONTACT.helpUser" | translate}}</strong>
                </mat-error>
              </mat-form-field>
    
              <mat-form-field appearance="legacy" class="row40">
                <mat-label>{{"pageAspForeign.CONTACT.titleEmail" | translate}}</mat-label>
                <input matInput formControlName="email"  required>
                <mat-error *ngIf="controlsForm.email.errors">
                  <strong>{{"pageAspForeign.CONTACT.email" | translate}}</strong>
                </mat-error>
              </mat-form-field>
        </div>

        <div class="row3Input">
            <mat-form-field class="custom-form-field" appearance="legacy" class="row30">
                <mat-label>{{"pageAspForeign.CONTACT.titlePhone" | translate}}</mat-label>
                <input matInput class="custom-input" formControlName="telefono" >
              </mat-form-field>
    
              <mat-form-field appearance="legacy" class="row30">
                <mat-label>{{"pageAspForeign.CONTACT.titleCompany" | translate}}</mat-label>
                <input matInput formControlName="empresa">
              </mat-form-field>
    
              <mat-form-field appearance="legacy" class="row40">
                <mat-label>{{"pageAspForeign.CONTACT.titleProjectType" | translate}}</mat-label>
                <input matInput formControlName="tipoProyecto" >
              </mat-form-field>
        </div>

        <div class="row2Input">
            <div class="row20 contentRadioProject">
                <label> {{"pageAspForeign.CONTACT.titleBudget" | translate}} </label>
                <mat-radio-group aria-label="Select an option"  formControlName="presupuesto" style="display: flex; gap: 20px;">
                    <mat-radio-button value="Si">{{"pageAspForeign.CONTACT.yes" | translate}}</mat-radio-button>
                    <mat-radio-button value="No">{{"pageAspForeign.CONTACT.no" | translate}}</mat-radio-button>
                  </mat-radio-group>
            </div>

            <mat-form-field appearance="legacy" class="row80">
                <mat-label>{{"pageAspForeign.CONTACT.titleIdea" | translate}}</mat-label>
                <input matInput placeholder="Placeholder" required formControlName="mensaje">
                <mat-error *ngIf="controlsForm.mensaje.errors">
                  <strong>{{"pageAspForeign.CONTACT.msj" | translate}}</strong>
                </mat-error>
              </mat-form-field>
        </div>

        <div class="contentPrivacyButton">
            <div class="txtPrivacy">
                <P>
                  {{"pageAspForeign.CONTACT.txtPolitic1" | translate}}
                     <span style="color: blue; cursor: pointer;" (click)="goPolityPrivacy()">{{"pageAspForeign.CONTACT.txtPolitic2" | translate}}</span>
                    <br>
                    <br>
                    {{"pageAspForeign.CONTACT.txtPolitic3" | translate}}
                </P>
            </div>
            <div class="contentBtn">
                <button class="btnRadius" (click)="sendMessage()"  [disabled]="EmailForm.invalid" style="width: min-content; padding: 18px 45px;"> {{"pageAspForeign.CONTACT.btnSubmit" | translate}}</button>
            </div>
        </div>

        </form>
    </div>
</div>
