<div class="row row-g-arq-org">
    <div class="col-sm-12 col-md-12 col-lg-12">
        <div class="row row-arq">
            <div class="col-sm-12 col-md-12 col-lg-5" data-aos="fade-left" data-aos-once="true" data-aos-duration="800">
                <div class="arq" *ngIf="jforms">
                    <h2>{{"SCHEME.titleOrgJforms" | translate}}</h2>
                    <ul  class="font">
                    <li> {{"SCHEME.titleOrgJlist1" | translate}} </li>
                    <li> {{"SCHEME.titleOrgJlist2" | translate}} </li>
                    <li>{{"SCHEME.titleOrgJlist3" | translate}} </li>
                    <li>{{"SCHEME.titleOrgJlist4" | translate}} </li>
                    <li> {{"SCHEME.titleOrgJlist5" | translate}} </li>
                    </ul>               
                <div class="imgs l arq-jf imgs-arq-mobile" *ngIf="jforms"></div>
                </div>
                <div class="arq" *ngIf="jreports">
                    <h2>{{"SCHEME.titleArcJreports" | translate}}</h2>
                    <ul  class="font">
                        <li>{{"SCHEME.titleArcJList1" | translate}}</li>
                        <li>{{"SCHEME.titleArcJList2" | translate}}</li>
                        <li>{{"SCHEME.titleArcJList3" | translate}}</li>
                        <li>{{"SCHEME.titleArcJList4" | translate}}</li>
                        <li>{{"SCHEME.titleArcJList5" | translate}}</li>
                        <li>{{"SCHEME.titleArcJList6" | translate}}</li>
                    </ul>               
                    </div>
                <div class="arq" *ngIf="jformsrun">
                    <h2>{{"SCHEME.titleArcJformsruntime" | translate}}</h2>
                    <p>{{"SCHEME.paragraphArcJformsruntime" | translate}}</p>
                </div>
                <div class="arq" *ngIf="jmigrator">
                    <h2>{{"SCHEME.titleArcJmigrator" | translate}}</h2>
                    <p>{{"SCHEME.paragraphArcJmigrator" | translate}}</p>
                    <div class="imgs l arq-jm imgs-arq-mobile" *ngIf="jmigrator"></div>
                </div>
            </div>



            <div class="col-sm-12 col-md-7 col-lg-7 imgs-arq-desk" data-aos="fade-right" data-aos-once="true" data-aos-duration="800">
                <div class="imgs l arq-jf" *ngIf="jreports" (click)="showLightBox('arq-jf')"> 
                    
                   
                </div>
                <div class="imgs l org-jf" *ngIf="!jmigrator && !jreports " (click)="showLightBox('org-jf')">



                </div>
                <div class="imgs l arq-jm" *ngIf="jmigrator" (click)="showLightBox('arq-jm')">



                </div>    

                <div class="circle"><i class="fas fa-expand"></i> </div>
            </div>
            <div class="col-sm-12 col-md-7 col-lg-7 imgs-arq-desk" data-aos="fade-right" data-aos-once="true" data-aos-duration="800">
                <div class="imgs l arq-jm" *ngIf="!jforms" (click)="showLightBox('arq-jm')">

                </div>
                <div class="imgs l org-jf" *ngIf="jforms" (click)="showLightBox('org-jf')">

                   

                </div>
            </div>
        </div>








        <div class="row">
       
            <div class="col-sm-12 col-md-7 col-lg-7 imgs-org-desk" data-aos="fade-left" data-aos-once="true" data-aos-duration="800">
                <div class="imgs r arq-jf" *ngIf="!jmigrator" (click)="showLightBox('arq-jf')">
                
                </div>

                <div class="imgs r org-jm" *ngIf="jmigrator" (click)="showLightBox('org-jm')">
              
                    

                </div>
            </div>
            <div class="col-sm-12 col-md-7 col-lg-7 imgs-org-desk" data-aos="fade-left" data-aos-once="true" data-aos-duration="800">
                <div class="imgs r arq-jf" *ngIf="jforms" (click)="showLightBox('arq-jf')">
                   
                </div>
                <div class="imgs r org-jm" *ngIf="!jforms" (click)="showLightBox('org-jm')">
                         
                </div>
                <div class="circle-down"><i class="fas fa-expand"></i> </div>
            </div>


            <div class="col-sm-12 col-md-12 col-lg-5" data-aos="fade-right" data-aos-once="true" data-aos-duration="800">
                <div class="org" *ngIf="jforms">
                    <h2>{{"SCHEME.titleArcJforms" | translate}}</h2>
                    <p>{{"SCHEME.paragraphArcJforms" | translate}}</p>        
                </div>
                <div class="org" *ngIf="jreports">
                    <h2>{{"SCHEME.titleOrgJreports" | translate}}</h2>
                    <p>
                        {{"SCHEME.paragraphOrgJreports" | translate}}
                    </p>                            
                    </div>
                <div class="org" *ngIf="jformsrun">
                    <h2>{{"SCHEME.titleOrgJformsruntime" | translate}}</h2>
                    <p>{{"SCHEME.paragraphOrgJformsruntime" | translate}}</p>
                </div>
                <div class="org" *ngIf="jmigrator">
                    <h2>{{"SCHEME.titleOrgJmigrator" | translate}}</h2>
                    <p>{{"SCHEME.paragraphOrgJmigrator" | translate}}</p>
                    <div class="imgs r org-jm imgs-org-mobile" *ngIf="jmigrator"></div>
                </div>
            </div>

        </div>
    </div>
</div>
<div id="lightBox" class="row-lightbox" [@fadeInOut]=currentState (click)="hideLightBox($event)">
    <div class="cont-imgs-lightbox">
        <div class="imgs-lightbox {{clsImg}}"></div>
    </div>
</div>