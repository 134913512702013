import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {GuestInfoService } from '../app/services/guest-info.service';

@Injectable({
  providedIn: 'root'
})
export class GlobalsService {
  public userCountry: string = 'CO';

  constructor(private _ip : GuestInfoService) {
   
  }

  getIp():Observable<any>{
    return this._ip.getGuestIp()
  }
  identityIP():Observable<any>{
    return this._ip.getGuestInfo(this.getIp());
  }



  public selectNav: string = "";
  public mostrarIngles:boolean=false;
  public mostrar_colombia:boolean=false;


}
