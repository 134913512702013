import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalsService } from 'src/app/globals.service';

@Component({
  selector: 'app-page-our-work',
  templateUrl: './page-our-work.component.html',
  styleUrls: ['./page-our-work.component.css']
})
export class PageOurWorkComponent implements OnInit {

  constructor(private router: Router, public globals: GlobalsService) { }

  ngOnInit(): void {
  }

  navigateContact(){
    window.scrollTo(0, 0);
    this.router.navigateByUrl('/contactForeign');
    this.globals.selectNav = "";
  }

}
