import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Carousel } from 'primeng/carousel';
import { GlobalsService } from 'src/app/globals.service';

@Component({
  selector: 'app-page-qbox',
  templateUrl: './page-qbox.component.html',
  styleUrls: ['./page-qbox.component.css']
})
export class PageQboxComponent implements OnInit {
  items: any[];
  items3: any[] = [
    {title: "Data Mastery",
     txt: "Qbox combines advanced data orchestration with robust security, enhancing analytical precision and safeguarding data, streamlining comprehensive management and integrity.",
     img: "img_Data_Mastery.svg",
     position: "left"
    },
    {title: "Strategic Empowerment",
     txt: "Leverage Qbox's smart data management for a competitive advantage, optimizing resources for peak performance and strategic superiority in the marketplace.",
     img: "img_Operational_Excellence.svg",
     position: "right"
    },
    {title: "Operational Excellence",
     txt: "Designed for cross-industry adaptability, Qbox enhances operational efficiency and collaboration, delivering tailored insights and efficiency across diverse sectors.",
     img: "img_Strategic.svg",
     position: "end"
    }
  ];

  items2: any[] = [
    {title: "Dominio de los Datos",
     txt: "Qbox combina una avanzada orquestación de datos con una seguridad robusta, mejorando la precisión analítica y protegiendo los datos, optimizando la gestión e integridad.",
     img: "img_Data_Mastery.svg",
     position: "left"
    },
    {title: "Empoderamiento Estratégico",
     txt: "Aprovecha la gestión inteligente de datos de Qbox para obtener una ventaja competitiva, optimizando recursos para un rendimiento óptimo y una superioridad estratégica en el mercado.",
     img: "img_Operational_Excellence.svg",
     position: "right"
    },
    {title: "Excelencia Operativa",
     txt: "Diseñado para adaptabilidad intersectorial, Qbox mejora la eficiencia operativa y la colaboración, proporcionando perspectivas personalizadas y eficiencia en diferentes ámbitos.",
     img: "img_Strategic.svg",
     position: "end"
    }
  ];
  images: string[] = [
    "assets/pageForeignAsp/pageQbox/iMG_MODELO_CELULAR_01.jpg",
    "assets/pageForeignAsp/pageQbox/iMG_MODELO_CELULAR_02.jpg",
    "assets/pageForeignAsp/pageQbox/iMG_MODELO_CELULAR_03.jpg",
    "assets/pageForeignAsp/pageQbox/iMG_MODELO_CELULAR_04.jpg",
    "assets/pageForeignAsp/pageQbox/iMG_MODELO_CELULAR_05.jpg"
  ]
  showImage: number = 0;
  responsiveOptions: any[] | undefined;
  intervalId: any;
  @ViewChild('carousel', { static: false }) carousel: Carousel;
  constructor(private translate: TranslateService, private router: Router, public globals: GlobalsService, private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
     this.actualizarVisualizacion();
     this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
       this.actualizarVisualizacion();
     });
     this.responsiveOptions = [
      {
          breakpoint: '1319px',
          numVisible: 1,
          numScroll: 1
      }
  ];
  this.startAutoChange();
  }

  ngOnDestroy() {
    this.stopAutoChange();
  }

  startAutoChange() {
    this.intervalId = setInterval(() => {
      this.changeSlide();
      this.changeImage();
    }, 5000); // Cambiar cada 5 segundos
  }

  stopAutoChange() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  changeImage() {
    this.showImage = (this.showImage + 1) % this.images.length;
  }

  changeSlide() {
    if (this.carousel) {
      const totalPages = Math.ceil(this.items.length / this.carousel.numVisible);
      if (this.carousel.page >= totalPages - 1) {
        this.carousel.page = 0; // Reiniciamos el carrusel
      } else {
        this.carousel.navForward(null); // Avanzamos a la siguiente diapositiva
      }
      this.cdr.detectChanges(); // Forzar la detección de cambios
    }
  }

  manualChangeImage(index: number) {
    this.stopAutoChange(); // Detenemos el intervalo cuando el usuario cambia manualmente
    this.showImage = index;
    this.startAutoChange(); // Reiniciamos el intervalo
  }

  private actualizarVisualizacion(): void {
    if (this.translate.currentLang === 'es') {
      this.items = this.items2; 
    } else {
      this.items = this.items3; 
    }
  }

  navigateContact(){
    window.scrollTo(0, 0);
    this.router.navigateByUrl('/contactForeign');
    this.globals.selectNav = ""
  }

}
