import { Component, HostListener, OnInit } from '@angular/core';
import { GlobalsService } from 'src/app/globals.service';

@Component({
  selector: 'app-footer-foreign',
  templateUrl: './footer-foreign.component.html',
  styleUrls: ['./footer-foreign.component.css']
})
export class FooterForeignComponent implements OnInit {
  currentYear: number;

  constructor( private globalService: GlobalsService) { }

  ngOnInit(): void {
    this.currentYear = new Date().getFullYear();
  }


}
