import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import{GlobalsService} from'../globals.service';


@Component({
  selector: 'app-multi-language',
  templateUrl: './multi-language.component.html',
  styleUrls: ['./multi-language.component.css']
})
export class MultiLanguageComponent implements OnInit {

  /*
  constructor(private translate : TranslateService,public globals:GlobalsService) {
    translate.addLangs(['es','en']);
    translate.setDefaultLang('es');
    this.language = navigator.language.substr(0,2);
    translate.use(this.language);
  }*/

  language: string;

  
  public region:string;
  
  constructor(private translate : TranslateService,
    public globals:GlobalsService
    ) {
  /*  translate.addLangs(['es','en']);
    translate.setDefaultLang('es');
    this.language = navigator.language.substr(0,2);

  this.infoIp();
  console.log(this.region);
    if(this.region !='South America'){
      this.language='en';
    }else{
      this.language='es';
    }
   translate.use(this.language);*/


   this.translate.addLangs(['es','en']);
   this.translate.setDefaultLang('es');
   this.language = navigator.language.substr(0,2);
    if (this.language !='es') {
      this.globals.mostrarIngles=true;
      this.language='en';
    } else{
      this.globals.mostrarIngles=false;
      this.language='es';
    }
    translate.use(this.language);
   /* this.globals.identityIP().subscribe( (response:any)=>{  
     this.region=response.geoplugin_continentName;

       if(this.region !='South America'){
        this.globals.mostrarIngles=true;
         this.language='en';
       }else{
         this.language='es';
         this.globals.mostrarIngles=false;
       }
      this.translate.use(this.language);

     },
     err=>{
       console.log('error');
     }); */


  }

  changeLanguage(language) : void{
    this.globals.mostrarIngles=!this.globals.mostrarIngles;
    this.translate.use(language);
  }

  Ip(){
    this.globals.getIp().subscribe(
      response=>{
      }
    );
  }

  infoIp(){
    this.globals.identityIP().subscribe( (response:any)=>{  
      this.region=response.geoplugin_continentName;
      },
      err=>{
        console.log('error');
      }

        );
    
}


  ngOnInit() {
  }

}
