

<div class="row row-serv-us">

  <!-- Español   -->
  
  
  <!-- Servicios en español cuando mostrar ingles sea false -->
  
    <div *ngIf="!globals.mostrarIngles" class="col-sm-11 col-md-3 col-lg-3 col-serv-us">
      <div class="img-serv img-erp img-serv-config" routerLink="/erp" >
        <div class="title-img-serv">
          <h3>&nbsp;{{"HOME.titleService1" | translate}}</h3>
          <p>&nbsp;{{"HOME.paragraphService1" | translate}}</p>
        </div>
      </div>
    </div>
    <div *ngIf="!globals.mostrarIngles"  class="col-sm-11 col-md-3 col-lg-3 col-serv-us" routerLink="/software">
      <div  class="img-serv img-des img-serv-config">
        <div class="title-img-serv">
          <h3>{{"HOME.titleService2" | translate}}</h3>
          <p>{{"HOME.paragraphService2" | translate}}</p>
        </div>
      </div>
    </div>
    <div *ngIf="!globals.mostrarIngles" class="col-sm-11 col-md-3 col-lg-3 col-serv-us" routerLink="/jmigrator">
      <div class="img-serv img-mig img-serv-config">
        <div class="title-img-serv">
          <h3>{{"HOME.titleService3" | translate}}</h3>
          <p>{{"HOME.paragraphService3" | translate}}</p>
        </div>
      </div>
    </div>


    <div *ngIf="!globals.mostrarIngles" class="col-sm-11 col-md-3 col-lg-3 col-serv-us">
      <div class="img-serv img-out img-serv-config" routerLink="/outsourcing" >
        <div class="title-img-serv">
          <h3>{{"HOME.titleService6" | translate}}</h3>
          <p>{{"HOME.paragraphService6" | translate}}</p>
        </div>
      </div>
    </div>

  
  <!-- fin  Español   -->
  
  
  <!-- Servicios en español cuando mostrar ingles sea false -->
  
  
   <!--  <div *ngIf="!globals.mostrarIngles" class="col-sm-12 col-md-3 col-lg-3 col-serv-us" routerLink="/wisesophia"  >
      <div *ngIf="serv" class="img-serv img-wis img-serv-config">
        <div class="title-img-serv">
          <h3>{{"HOME.titleService5" | translate}}</h3>
          <p>{{"HOME.paragraphService5" | translate}}</p>
        </div>
      </div>
    </div> -->
    
  
  
  <!-- ingles   -->
  
  
  
  <!-- Servicios en ingles cuando mostrar ingles sea true -->
  
  <!-- Servicio 1 es  ERP comentado para ingles -->
  
  
   <!-- <div *ngIf="!globals.mostrarIngles " class="col-sm-12 col-md-4 col-lg-4 col-serv-us">
      <div *ngIf="serv" class="img-serv img-erp img-serv-config" routerLink="/erp" >
        <div class="title-img-serv">
          <h3>{{"HOME.titleService1" | translate}}</h3>
          <p>{{"HOME.paragraphService1" | translate}}</p>
        </div>
      </div>
    </div>
    -->
  
  <div *ngIf="globals.mostrarIngles"  class="col-sm-13 col-md-4 col-lg-4 col-serv-us" routerLink="/software">
    <div  class="img-serv img-des img-serv-config">
      <div class="title-img-serv">
        <h3>{{"HOME.titleService2" | translate}}</h3>
        <p>{{"HOME.paragraphService2" | translate}}</p>
      </div>
    </div>
  </div>
  <div *ngIf="globals.mostrarIngles" class="col-sm-13 col-md-4 col-lg-4 col-serv-us" routerLink="/jmigrator">
    <div class="img-serv img-mig img-serv-config">
      <div class="title-img-serv">
        <h3>{{"HOME.titleService3" | translate}}</h3>
        <p>{{"HOME.paragraphService3" | translate}}</p>
      </div>
    </div>
  </div>

  
  <div *ngIf="globals.mostrarIngles" class="col-sm-13 col-md-4 col-lg-4 col-serv-us" routerLink="/outsourcing">
    <div class="img-serv img-out img-serv-config">
      <div class="title-img-serv">
        <h3>{{"HOME.titleService6" | translate}}</h3>
        <p>{{"HOME.paragraphService6" | translate}}</p>
      </div>
    </div>
  </div>
   <!--  <div *ngIf="globals.mostrarIngles" class="col-sm-12 col-md-3 col-lg-3 col-serv-us" routerLink="/wisesophia"  >
      <div *ngIf="serv" class="img-serv img-wis img-serv-config">
        <div class="title-img-serv">
          <h3>{{"HOME.titleService5" | translate}}</h3>
          <p>{{"HOME.paragraphService5" | translate}}</p>
        </div>
      </div>
    </div>-->
  
  
  
  
  
  </div>
  