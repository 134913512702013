<div class="row row-proj-soft">
    <div class="col-sm-12 col-md-12 col-lg-12 col-proj-pad">
        <div class="title-proj-soft">
            <h2 data-aos="fade-up" data-aos-once="true" data-aos-duration="1000">{{"SOFTWARE.titleProject" | translate}}</h2>
        </div>
        <div class="btns-proj-soft" data-aos="fade-up" data-aos-once="true" data-aos-duration="1000">
            <button mat-raised-button [ngClass]="{'btn-act' : movil === true}" (click)="selectedBtn('movil')">
                <div class="content-btn-proj-soft">
                    <div class="config-i-proj-soft i-proj1" [ngClass]="{'btn-act-movil' : movil === true}"></div>
                    <div class="content-i-proj-soft">
                        <h6>{{"SOFTWARE.titleBtnProject1" | translate}}</h6>
                        <p>{{"SOFTWARE.subTitleProject1" | translate}}</p>
                    </div>
                </div>
            </button>
            <button mat-raised-button [ngClass]="{'btn-act' : web === true}" (click)="selectedBtn('web')">
                <div class="content-btn-proj-soft">
                    <div class="content-i-proj-soft2">
                        <h6>{{"SOFTWARE.titleBtnProject2" | translate}}</h6>
                        <p>{{"SOFTWARE.subTitleProject2" | translate}}</p>
                    </div>
                    <div class="config-i-proj-soft i-proj3" [ngClass]="{'btn-act-web' : web === true}"></div>
                </div>
            </button>
        </div>
        <!-- Carousel Movil -->
        <div *ngIf="movil" class="d-carrousel-proj-soft animated fadeIn">
            <mdb-carousel class="carousel slide carousel-fade carousel-projects" [animation]="'fade'">
                <mdb-carousel-item>
                    <div class="row row-cont-carousel">
                        <div class="col-sm-12 col-md-6 col-lg-6 col-img-left-proj">
                            <div class="config-img-right-proj-soft img-proj1"></div>
                        </div>
                        <div class="col-sm-12 col-md-6 col-lg-6">
                            <div class="text-right-proj">
                                <h2>{{"SOFTWARE.titleApp1" | translate}}</h2>
                                <p>{{"SOFTWARE.paragraphApp1" | translate}}</p>
                            </div>
                        </div>
                    </div>
                </mdb-carousel-item>
                <mdb-carousel-item>
                    <div class="row row-cont-carousel">
                        <div class="col-sm-12 col-md-6 col-lg-6">
                            <div class="text-left-proj">
                                <h2>{{"SOFTWARE.titleApp2" | translate}}</h2>
                                <p>{{"SOFTWARE.paragraphApp2" | translate}}</p>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-6 col-lg-6 col-img-right-proj">
                            <div class="config-img-left-proj-soft img-proj2"></div>
                        </div>
                    </div>
                </mdb-carousel-item>
            </mdb-carousel>
        </div>
        <!-- Carousel Web -->
        <div *ngIf="web" class="d-carrousel-proj-soft animated fadeIn">
            <mdb-carousel class="carousel slide carousel-fade carousel-projects" [animation]="'fade'">
                <mdb-carousel-item>
                    <div class="row row-cont-carousel">
                        <div class="col-sm-12 col-md-6 col-lg-6 col-img-left-proj">
                            <div class="config-img-right-proj-soft img-fab"></div>
                        </div>
                        <div class="col-sm-12 col-md-6 col-lg-6">
                            <div class="text-right-proj">
                                <h2>{{"SOFTWARE.titleApp3" | translate}}</h2>
                                <ul>
                                    <li  *ngFor="let p of 'SOFTWARE.paragraphApp3' | translate">{{p}}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </mdb-carousel-item>
                <mdb-carousel-item>
                    <div class="row row-cont-carousel">
                        <div class="col-sm-12 col-md-6 col-lg-6">
                            <div class="text-left-proj">
                                <h2>{{"SOFTWARE.titleApp4" | translate}}</h2>
                                <ul>
                                    <li  *ngFor="let p of 'SOFTWARE.paragraphApp4' | translate">{{p}}</li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-6 col-lg-6 col-img-right-proj">
                            <div class="config-img-left-proj-soft img-equi"></div>
                        </div>
                    </div>
                </mdb-carousel-item>
                

                <!--
                <mdb-carousel-item>
                    <div class="row row-cont-carousel">
                        <div class="col-sm-12 col-md-6 col-lg-6">
                            <div class="text-left-proj">
                                <h2>Wisesophia</h2>
                                <ul>
                                    <li  *ngFor="let p of 'WISESOPHIA.paragraphWelcome' | translate">{{p}}</li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-6 col-lg-6 col-img-right-proj">
                            <div class="config-img-left-proj-soft img-wise"></div>
                        </div>
                    </div>
                </mdb-carousel-item>
                 -->
                 
                 
            </mdb-carousel>
        </div>
    </div>
</div>
