<div class="row">
  <div class="col-sm-12 col-md-12 col-lg-12 col-jsp7-det">
    <div class="title-jsp7-det">
      <h2>{{"ERP.titleJsp7Det" | translate}}</h2>
    </div>

    <div class="btns-jsp7-det btn-group">
      <button mat-button (click)="selectedBtn('nom')">
        <div class="i-config i-nom" [ngClass]="{'i-nom-act' : nom}"></div>
      </button>
      <button mat-button (click)="selectedBtn('act')">
        <div class="i-config i-act" [ngClass]="{'i-act-act' : act}"></div>
      </button>
      <button mat-button (click)="selectedBtn('cont')">
        <div class="i-config i-cont" [ngClass]="{'i-cont-act' : cont}"></div>
      </button>
      <button mat-button (click)="selectedBtn('contra')">
        <div class="i-config i-contra" [ngClass]="{'i-contra-act' : contra}"></div>
      </button>
      <button mat-button (click)="selectedBtn('cxc')">
        <div class="i-config i-cxc" [ngClass]="{'i-cxc-act' : cxc}"></div>
      </button>
      <button mat-button (click)="selectedBtn('cxp')">
        <div class="i-config i-cxp" [ngClass]="{'i-cxp-act' : cxp}"></div>
      </button>
      <button mat-button (click)="selectedBtn('inv')">
        <div class="i-config i-inv" [ngClass]="{'i-inv-act' : inv}"></div>
      </button>
      <button mat-button (click)="selectedBtn('per')">
        <div class="i-config i-per" [ngClass]="{'i-per-act' : per}"></div>
      </button>
      <button mat-button (click)="selectedBtn('tes')">
        <div class="i-config i-tes" [ngClass]="{'i-tes-act' : tes}"></div>
      </button>
      <button mat-button (click)="selectedBtn('plan')">
        <div class="i-config i-plan" [ngClass]="{'i-plan-act' : plan}"></div>
      </button>
      <button mat-button (click)="selectedBtn('imp')">
        <div class="i-config i-imp" [ngClass]="{'i-imp-act' : imp}"></div>
      </button>
      <button mat-button (click)="selectedBtn('gest')">
        <div class="i-config i-gest" [ngClass]="{'i-gest-act' : gest}"></div>
      </button>
      <button mat-button (click)="selectedBtn('ppto')">
        <div class="i-config i-ppto" [ngClass]="{'i-ppto-act' : ppto}"></div>
      </button>
      <!-- <button mat-button (click)="selectedBtn('wflow')"><div class="i-config i-wflow" [ngClass]="{'i-wflow-act' : wflow}"></div></button> -->
      <button mat-button (click)="selectedBtn('disp')">
        <div class="i-config i-disp" [ngClass]="{'i-disp-act' : disp}"></div>
      </button>
      <!-- <button mat-button (click)="selectedBtn('pptopriv')"><div class="i-config i-pptopriv" [ngClass]="{'i-pptopriv-act' : pptopriv}"></div></button> -->
    </div>

    <div class="bg-jsp7-det">
      <div class="d-jsp7-det text-jsp7-det animated fadeIn" *ngIf="act">
        <h2>{{"ERP.titleFixedAssets" | translate}}</h2>
        <div [ngSwitch]='vermas'>
          <div *ngSwitchCase=false>
            <ol>
              <li *ngFor="let p of getN('ERP.pointsFixedAssets' | translate,0)">{{p}}</li> 
            </ol>
          </div>
          <div *ngSwitchCase=true>
            <ol>
              <li *ngFor="let p of getN('ERP.pointsFixedAssets'  | translate,1)">{{p}}</li>
            </ol>
          </div>
        </div>
        <button (click)="mostraTexto(true)" class="VerMas" [ngStyle]="{'display': vermas===false? 'block' : 'none'}" >{{"Read.ReadMore" | translate}}</button>
        <button (click)="mostraTexto(false)" class="VerMas" [ngStyle]="{'display': vermas===true? 'block' : 'none'}">{{"Read.ReadLess" | translate}}</button>
      </div>
      <div class="d-jsp7-det text-jsp7-det animated fadeIn" *ngIf="cont">
        <h2>{{"ERP.titleAccounting" | translate}}</h2>
        <div [ngSwitch]='vermas'>
          <div *ngSwitchCase=false>
            <ol>
              <li *ngFor="let p of getN('ERP.pointsAccounting' | translate,0)">{{p}}</li>
            </ol>
          </div>
          <div *ngSwitchCase=true>
            <ol>
              <li *ngFor="let p of getN('ERP.pointsAccounting'  | translate,1)">{{p}}</li>
            </ol>
          </div>
        </div>
        <button (click)="mostraTexto(true)" class="VerMas"
          [ngStyle]="{'display': vermas===false? 'block' : 'none'}">{{"Read.ReadMore" | translate}}</button>
        <button (click)="mostraTexto(false)" class="VerMas"
          [ngStyle]="{'display': vermas===true? 'block' : 'none'}">{{"Read.ReadLess" | translate}}</button>
      </div>
      <div class="d-jsp7-det text-jsp7-det animated fadeIn" *ngIf="contra">
        <h2>{{"ERP.titleHiring" | translate}}</h2>
        <div [ngSwitch]='vermas'>
          <div *ngSwitchCase=false>
            <ol>
              <li *ngFor="let p of getN('ERP.pointsHiring'  | translate,0)">{{p}}</li>
            </ol>
          </div>
          <div *ngSwitchCase=true>
            <ol>
              <li *ngFor="let p of getN('ERP.pointsHiring'  | translate,1)">{{p}}</li>
            </ol>
          </div>
        </div>
        <button (click)="mostraTexto(true)" class="VerMas"
          [ngStyle]="{'display': vermas===false? 'block' : 'none'}">{{"Read.ReadMore" | translate}}</button>
        <button (click)="mostraTexto(false)" class="VerMas"
          [ngStyle]="{'display': vermas===true? 'block' : 'none'}">{{"Read.ReadLess" | translate}}</button>
      </div>
      <div class="d-jsp7-det text-jsp7-det animated fadeIn" *ngIf="cxc">
        <h2>{{"ERP.titleAccountsReceivable" | translate}}</h2>
        <div [ngSwitch]='vermas'>
          <div *ngSwitchCase=false>
            <ol>
              <li *ngFor="let p of getN('ERP.pointsAccountsReceivable' | translate,0)">{{p}}</li>
            </ol>
          </div>
          <div *ngSwitchCase=true>
            <ol>
              <li *ngFor="let p of getN('ERP.pointsAccountsReceivable'  | translate,1)">{{p}}</li>
            </ol>
          </div>
          <button (click)="mostraTexto(true)" class="VerMas"
            [ngStyle]="{'display': vermas===false? 'block' : 'none'}">{{"Read.ReadMore" | translate}}</button>
          <button (click)="mostraTexto(false)" class="VerMas"
            [ngStyle]="{'display': vermas===true? 'block' : 'none'}">{{"Read.ReadLess" | translate}}</button>
        </div>
      </div>
      <div class="d-jsp7-det text-jsp7-det animated fadeIn" *ngIf="cxp">
        <h2>{{"ERP.titleDebtsToPay" | translate}}</h2>
        <div [ngSwitch]='vermas'>
          <div *ngSwitchCase=false>
            <ol>
              <li *ngFor="let p of getN('ERP.pointsDebtstoPay' | translate,0)">{{p}}</li>
            </ol>
          </div>
          <div *ngSwitchCase=true>
            <ol>
              <li *ngFor="let p of getN('ERP.pointsDebtstoPay'  | translate,1)">{{p}}</li>
            </ol>
          </div>
        </div>
        <button (click)="mostraTexto(true)" class="VerMas"
          [ngStyle]="{'display': vermas===false? 'block' : 'none'}">{{"Read.ReadMore" | translate}}</button>
        <button (click)="mostraTexto(false)" class="VerMas"
          [ngStyle]="{'display': vermas===true? 'block' : 'none'}">{{"Read.ReadLess" | translate}}</button>
      </div>
      <div class="d-jsp7-det text-jsp7-det animated fadeIn" *ngIf="inv">
        <h2>{{"ERP.titleInventory" | translate}}</h2>
        <div [ngSwitch]='vermas'>
          <div *ngSwitchCase=false>
            <ol>
              <li *ngFor="let p of getN(('ERP.pointsInventory' | translate),0)">{{p}}</li>
            </ol>
          </div>
          <div *ngSwitchCase=true>
            <ol>
              <li *ngFor="let p of getN(('ERP.pointsInventory'  | translate),1)">{{p}}</li>
            </ol>
          </div>
        </div>
        <button (click)="mostraTexto(true)" class="VerMas"
          [ngStyle]="{'display': vermas===false? 'block' : 'none'}">{{"Read.ReadMore" | translate}}</button>
        <button (click)="mostraTexto(false)" class="VerMas"
          [ngStyle]="{'display': vermas===true? 'block' : 'none'}">{{"Read.ReadLess" | translate}}</button>
      </div>
      <div class="d-jsp7-det text-jsp7-det animated fadeIn" *ngIf="nom">
        <h2>{{"ERP.titleRoster" | translate}}</h2>
        <div [ngSwitch]='vermas'>
          <div *ngSwitchCase=false>
            <ol>
              <li *ngFor="let p of getN('ERP.pointsRoster'  | translate,0)">{{p}}</li>
            </ol>
          </div>
          <div *ngSwitchCase=true>
            <ol>
              <li *ngFor="let p of getN('ERP.pointsRoster'  | translate,1)">{{p}}</li>
            </ol>
          </div>
        </div>
        <button (click)="mostraTexto(true)" class="VerMas"
          [ngStyle]="{'display': vermas===false? 'block' : 'none'}">{{"Read.ReadMore" | translate}}</button>
        <button (click)="mostraTexto(false)" class="VerMas"
          [ngStyle]="{'display': vermas===true? 'block' : 'none'}">{{"Read.ReadLess" | translate}}</button>
      </div>
      <div class="d-jsp7-det text-jsp7-det animated fadeIn" *ngIf="per">
        <h2>{{"ERP.titleStaff" | translate}}</h2>
        <div [ngSwitch]='vermas'>
          <div *ngSwitchCase=false>
            <ol>
              <li *ngFor="let p of getN('ERP.pointsStaff' | translate,0)">{{p}}</li>
            </ol>
          </div>
          <div *ngSwitchCase=true>
            <ol>
              <li *ngFor="let p of getN('ERP.pointsStaff'  | translate,1)">{{p}}</li>
            </ol>
          </div>
        </div>
        <button (click)="mostraTexto(true)" class="VerMas"
          [ngStyle]="{'display': vermas===false? 'block' : 'none'}">{{"Read.ReadMore" | translate}}</button>
        <button (click)="mostraTexto(false)" class="VerMas"
          [ngStyle]="{'display': vermas===true? 'block' : 'none'}">{{"Read.ReadLess" | translate}}</button>
      </div>
      <div class="d-jsp7-det text-jsp7-det animated fadeIn" *ngIf="tes">
        <h2>{{"ERP.titleTreasury" | translate}}</h2>
        <div [ngSwitch]='vermas'>
          <div *ngSwitchCase=false>
            <ol>
              <li *ngFor="let p of getN('ERP.pointsTreasury' | translate,0)">{{p}}</li>
            </ol>
          </div>
          <div *ngSwitchCase=true>
            <ol>
              <li *ngFor="let p of getN('ERP.pointsTreasury'  | translate,1)">{{p}}</li>
            </ol>
          </div>
          <button (click)="mostraTexto(true)" class="VerMas"
            [ngStyle]="{'display': vermas===false? 'block' : 'none'}">{{"Read.ReadMore" | translate}}</button>
          <button (click)="mostraTexto(false)" class="VerMas"
            [ngStyle]="{'display': vermas===true? 'block' : 'none'}">{{"Read.ReadLess" | translate}}</button>
        </div>
      </div>
      <div class="d-jsp7-det text-jsp7-det animated fadeIn" *ngIf="plan">
        <h2>{{"ERP.titlePlanning" | translate}}</h2>
        <div [ngSwitch]='vermas'>
          <div *ngSwitchCase=false>
            <ol>
              <li *ngFor="let p of getN('ERP.pointsPlanning' | translate,0)">{{p}}</li>
            </ol>
          </div>
          <div *ngSwitchCase=true>
            <ol>
              <li *ngFor="let p of getN('ERP.pointsPlanning'  | translate,1)">{{p}}</li>
            </ol>
          </div>
        </div>
        <button (click)="mostraTexto(true)" class="VerMas" [ngStyle]="{'display': vermas===false? 'block' : 'none'}">{{"Read.ReadMore" | translate}}</button>
        <button (click)="mostraTexto(false)" class="VerMas" [ngStyle]="{'display': vermas===true? 'block' : 'none'}">{{"Read.ReadLess" | translate}}</button>
      </div>
      <div class="d-jsp7-det text-jsp7-det animated fadeIn" *ngIf="imp">
        <h2>{{"ERP.titlePropertyTax" | translate}}</h2>
        <div [ngSwitch]='vermas'>
          <div *ngSwitchCase=false>
            <ol>
              <li *ngFor="let p of getN('ERP.pointsPropertyTax' | translate,0)">{{p}}</li>
            </ol>
            </div>
            <div *ngSwitchCase=true>
              <ol>
                <li *ngFor="let p of getN('ERP.pointsPropertyTax'  | translate,1)">{{p}}</li>
              </ol>
            </div>
          </div>
          <button (click)="mostraTexto(true)" class="VerMas"
            [ngStyle]="{'display': vermas===false? 'block' : 'none'}">{{"Read.ReadMore" | translate}}</button>
          <button (click)="mostraTexto(false)" class="VerMas"
            [ngStyle]="{'display': vermas===true? 'block' : 'none'}">{{"Read.ReadLess" | translate}}</button>
        </div>
      <div class="d-jsp7-det text-jsp7-det animated fadeIn" *ngIf="gest">
        <h2>{{"ERP.titlePurchasingMgmt" | translate}}</h2>
        <div [ngSwitch]='vermas'>
          <div *ngSwitchCase=false>
            <ol>
              <li *ngFor="let p of getN('ERP.pointsPurchasingMgmt' | translate,0)">{{p}}</li>
            </ol>
          </div>
          <div *ngSwitchCase=true>
            <ol>
              <li *ngFor="let p of getN('ERP.pointsPurchasingMgmt'  | translate,1)">{{p}}</li>
            </ol>
          </div>
        </div>
        <button (click)="mostraTexto(true)" class="VerMas" [ngStyle]="{'display': vermas===false? 'block' : 'none'}">{{"Read.ReadMore" | translate}}</button>
        <button (click)="mostraTexto(false)" class="VerMas" [ngStyle]="{'display': vermas===true? 'block' : 'none'}">{{"Read.ReadLess" | translate}}</button>
      </div>
      <div class="d-jsp7-det text-jsp7-det animated fadeIn" *ngIf="ppto">
        <h2>{{"ERP.titleOfficialBudget" | translate}}</h2>
        <div [ngSwitch]='vermas'>
          <div *ngSwitchCase=false>
            <ol>
              <li *ngFor="let p of getN('ERP.pointsOfficialBudget' | translate,0)">{{p}}</li>
            </ol>
          </div>
          <div *ngSwitchCase=true>
            <ol>
              <li *ngFor="let p of getN('ERP.pointsOfficialBudget'  | translate,1)">{{p}}</li>
            </ol>
          </div>
        </div>
        <button (click)="mostraTexto(true)" class="VerMas" [ngStyle]="{'display': vermas===false? 'block' : 'none'}">{{"Read.ReadMore" | translate}}</button>
        <button (click)="mostraTexto(false)" class="VerMas" [ngStyle]="{'display': vermas===true? 'block' : 'none'}">{{"Read.ReadLess" | translate}}</button>
      </div>
      <div class="d-jsp7-det text-jsp7-det animated fadeIn" *ngIf="wflow">
        <h2>{{"ERP.titlePurchasingMgmt" | translate}}</h2>
        <div [ngSwitch]='vermas'>
          <div *ngSwitchCase=false>
            <ol>
              <li *ngFor="let p of getN('ERP.pointsPurchasingMgmt' | translate,0)">{{p}}</li>
            </ol>
          </div>
          <div *ngSwitchCase=true>
            <ol>
              <li *ngFor="let p of getN('ERP.pointsPurchasingMgmt'  | translate,1)">{{p}}</li>
            </ol>
          </div>
        </div>
        <button (click)="mostraTexto(true)" class="VerMas" [ngStyle]="{'display': vermas===false? 'block' : 'none'}">{{"Read.ReadMore" | translate}}</button>
        <button (click)="mostraTexto(false)" class="VerMas" [ngStyle]="{'display': vermas===true? 'block' : 'none'}">{{"Read.ReadLess" | translate}}</button>
      </div>
      <div class="d-jsp7-det text-jsp7-det animated fadeIn" *ngIf="disp">
        <h2>{{"ERP.titleMedicament" | translate}}</h2>
        <div [ngSwitch]='vermas'>
          <div *ngSwitchCase=false>
            <ol>
              <li *ngFor="let p of getN('ERP.pointsMedicament' | translate,0)">{{p}}</li>
            </ol>
          </div>
          <div *ngSwitchCase=true>
            <ol>
              <li *ngFor="let p of getN('ERP.pointsMedicament'  | translate,1)">{{p}}</li>
            </ol>
          </div>
        </div>
        <button (click)="mostraTexto(true)" class="VerMas" [ngStyle]="{'display': vermas===false? 'block' : 'none'}">{{"Read.ReadMore" | translate}}</button>
        <button (click)="mostraTexto(false)" class="VerMas" [ngStyle]="{'display': vermas===true? 'block' : 'none'}">{{"Read.ReadLess" | translate}}</button>
      </div>
      <div class="d-jsp7-det text-jsp7-det animated fadeIn" *ngIf="pptopriv">
        <h2>{{"ERP.titlePurchasingMgmt" | translate}}</h2>
        <div [ngSwitch]='vermas'>
          <div *ngSwitchCase=false>
            <ol>
              <li *ngFor="let p of getN('ERP.pointsPurchasingMgmt' | translate,0)">{{p}}</li>
            </ol>
          </div>
          <div *ngSwitchCase=true>
            <ol>
              <li *ngFor="let p of getN('ERP.pointsInventory'  | translate,1)">{{p}}</li>
            </ol>
          </div>
        </div>
        <button (click)="mostraTexto(true)" class="VerMas" [ngStyle]="{'display': vermas===false? 'block' : 'none'}">{{"Read.ReadMore" | translate}}</button>
        <button (click)="mostraTexto(false)" class="VerMas" [ngStyle]="{'display': vermas===true? 'block' : 'none'}">{{"Read.ReadLess" | translate}}</button>
      </div>
      </div>
    </div>
  </div>