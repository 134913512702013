<div class="row row-inter">
    <div class="col-sm-12 col-md-12 col-lg-6 col-gif-inter">
        <div class="gif-inter"></div>
    </div>
    <div class="col-sm-12 col-md-12 col-lg-6">
        <div class="cont-inter"  data-aos="fade-left" data-aos-once="true" data-aos-duration="1200">
            <h2>{{"SOFTWARE.titleInter" | myTranslate}}</h2>
            <ul>
                <li *ngFor="let p of 'SOFTWARE.paragraphInter' | myTranslate">{{p}}</li>
            </ul>
             <button mat-button (click) = toggleDisplay() >{{"SOFTWARE.btnInter" | myTranslate}}</button> 
        </div>
    </div>
      
</div>
<div *ngIf = "!isShow" class="row azul">
    <div class="col-sm-12 col-md-12 col-lg-8">
        <div class="cont-inter"  data-aos="fade-left" data-aos-once="true" data-aos-duration="1200">
            <h2>{{"SOFTWARE.titleInter1" | myTranslate}}</h2>
            <ul>
                <li *ngFor="let p of 'SOFTWARE.paragraphInter1' | translate">{{p}}</li>
            </ul>
        </div>
    </div>
    <div class="col-sm-12 col-md-12 col-lg-4 col-gif-inter">
        <div class="img-test"></div>
    </div>
      
</div>





<div *ngIf = "!isShow" class="row row-frame-soft azul">
    <div class="col-sm-12 col-md-12 col-lg-12">
        <div class="cont-inter2"  data-aos="fade-left" data-aos-once="true" data-aos-duration="1200">
            <h2>{{"SOFTWARE.titleInter2" | myTranslate}}</h2>
        </div>
    </div>

    <div class="col-sm-12 col-md-12 col-lg-12 bg-frame-soft2">
        <div class="d-frame-soft" data-aos="fade-up" data-aos-once="true" data-aos-duration="1200">
            <div class="icons-frame-soft2">
                <div class="config-img-frame-soft2 img-wp1"></div>
                <p>{{"SOFTWARE.titlewp1" | myTranslate}}</p>
            </div>
            <div class="icons-frame-soft2">
                <div class="config-img-frame-soft2 img-wp2"></div>
                <p>{{"SOFTWARE.titlewp2" | myTranslate}}</p>
            </div>
            <div class="icons-frame-soft2">
                <div class="config-img-frame-soft2 img-wp3"></div>
                <p>{{"SOFTWARE.titlewp3" | myTranslate}}</p>
            </div>
            <div class="icons-frame-soft2">
                <div class="config-img-frame-soft2 img-wp4"></div>
                <p>{{"SOFTWARE.titlewp4" | myTranslate}}</p>
            </div>
        </div>
    </div>
    <div class="col-sm-12 col-md-12 col-lg-12 bg-frame-soft2">
        <div class="d-frame-soft" data-aos="fade-up" data-aos-once="true" data-aos-duration="1200">
            <div class="icons-frame-soft2">
                <div class="config-img-frame-soft2 img-wp5"></div>
                <p>{{"SOFTWARE.titlewp5" | myTranslate}}</p>
            </div>
            <div class="icons-frame-soft2">
                <div class="config-img-frame-soft2 img-wp6"></div>
                <p>{{"SOFTWARE.titlewp6" | myTranslate}}</p>
            </div>
            <div class="icons-frame-soft2">
                <div class="config-img-frame-soft2 img-wp7"></div>
                <p>{{"SOFTWARE.titlewp7" | myTranslate}}</p>
            </div>
            <div class="icons-frame-soft2">
                <div class="config-img-frame-soft2 img-wp8"></div>
                <p>{{"SOFTWARE.titlewp8" | myTranslate}}</p>
            </div>
        </div>
    </div>
</div>
