<div class="row row-about-us">
  <div class="col-sm-12 col-md-12 col-lg-12 bg-wel-us col-about-us">
    <div class="img-hombre-about-us" data-aos="fade-left" data-aos-once="true" data-aos-duration="1000"></div>

      <div class="title-wel-about-us" data-aos="fade-up" data-aos-once="true" data-aos-duration="800">
        <h2>{{"US.titleWelcome" | translate}}</h2>
        <hr class="hr-welcome-us">
      </div>
  </div>
</div>
