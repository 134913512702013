<div class="row row-wel-jreports">
    <div class="col-sm-12 col-md-12 col-lg-12 bg-jreports">
        <div class="wel-jreports" data-aos="fade-right" data-aos-once="true" data-aos-duration="800">
            <h2>{{"JREPORTS.titleJreports" | translate}}</h2>
            <p>
                {{"JREPORTS.paragraphJreports" | translate}}
            </p>
            <div class="download-tools" data-aos="fade-up" data-aos-once="true" data-aos-duration="800">
                <a  mat-button href="http://www.aspsols.com/AspSolutionsPage2016/faces/doc-jrm.xhtml" target="_blank">{{"JREPORTS.btnDownloadDoc" | translate}}</a>
            </div>
        </div>
    </div>
</div>