import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalsService } from 'src/app/globals.service';
import { AppRoutingModuleForeign } from 'src/app/routing-module-foreign';

declare var bodymovin:any;

@Component({
  selector: 'app-our-work',
  templateUrl: './our-work.component.html',
  styleUrls: ['./our-work.component.css']
})
export class OurWorkComponent implements OnInit {

  constructor(private router: Router, public globals: GlobalsService) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.loadAnimation();
    }, 100);
  }

  navigateOurWork(){
    window.scrollTo(0, 0);
    this.router.navigateByUrl('/ourWork');
    this.globals.selectNav = "ourWork"
  }

  loadAnimation(){
    var animationAviation = bodymovin.loadAnimation({
      container: document.getElementById('animationAviation'),
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: '../../../assets/pageForeignAsp/animations/expertsInAviation.json'
    })

    var animationAviationMobile = bodymovin.loadAnimation({
      container: document.getElementById('animationAviationMobile'),
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: '../../../assets/pageForeignAsp/animations/expertsInAviation.json'
    })
  }


 

}
