import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.css']
})
export class TestimonialsComponent implements OnInit {

  constructor() { }

  slideConfig = {
    "slidesToShow": 1,
    "slidesToScroll": 1,
    "autoplay": true,
    "infinite": true,
    "speed": 1500,
    "autoplaySpeed": 8000,
    "arrows": true,
    "fade": true
  };

  ngOnInit() {
  }

}
