<section class="containerSolutions paddingForeign">
    <article class="contentLeft">
        <h3 class="title" style="white-space: pre-line;">
            {{"pageAspForeign.ourSolutions.title" | translate}}
        </h3>
        <!-- <img src="../../../assets/pageForeignAsp/ourSolutions/img-Discover-our innovate-solutions.svg" class="imgMobile" alt="Innovation"> -->
        <div id="animationDiscoverMobile"  class="imgMobile" alt="Discover"> </div>
        <p class="txt" style="white-space: pre-line;">{{"pageAspForeign.ourSolutions.txt" | translate}}</p>
       
    </article>

    <article class="contentRigth">
        <div id="animationDiscover"  class="img" alt="Discover"> </div>
        <!-- <img src="../../../assets/pageForeignAsp/ourSolutions/img-Discover-our innovate-solutions.svg" class="img" alt="Innovation"> -->
    </article>
</section>