<section  class="containerWhatWeDo">
    <!-- <div class="backgroundWhatWeDo"> -->
    <article class="articleTextWhatWeDo paddingForeign">
        <aside class="titleWhatWeDo">
            <div class="line"></div>
            <div> <h1 class="title"> {{"pageAspForeign.WhatWeDo.title" | translate}}</h1> </div>
            <div class="line"></div>
        </aside>

        <aside class="textWhatWeDo">
            <p class="text">
                {{"pageAspForeign.WhatWeDo.text" | translate}}
            </p>
        </aside>
    </article>

    <article class="articleImgsWhatWeDo paddingForeign">
        
        <aside class="chartContent chartContentLeft" [ngClass]="{'show': showLeft}">
            <div id="animation2"  class="imgLeft" alt="Direc"> </div>
                <label class="titleChart"> {{"pageAspForeign.WhatWeDo.titleCard1" | translate}}</label>
                <p class="textChart">{{"pageAspForeign.WhatWeDo.txtCard1" | translate}}</p>
        </aside>

        <aside class="chartContent chartContenRigth" [ngClass]="{'show': showRight}">
            <!-- <img class="imgRigth" src="../../../assets/pageForeignAsp/whatWeDo/img-We-Manage-for-You.svg"  alt="Direc"> -->
            <div id="animation3"  class="imgRigth" alt="Manage"> </div>
                <label class="titleChart">{{"pageAspForeign.WhatWeDo.titleCard2" | translate}}</label>
                <p class="textChart">{{"pageAspForeign.WhatWeDo.txtCard2" | translate}}</p>
        </aside>
    </article>
<!-- </div> -->
</section>
