<div class="row row-frame-soft">
    <div class="col-sm-12 col-md-12 col-lg-5 bg-frame-soft">
        <div class="title-frame-soft" data-aos="fade-up" data-aos-once="true" data-aos-duration="1200">
            <h2>{{"SOFTWARE.titleFrame" | translate}}</h2>
            <p>{{"SOFTWARE.subTitleFrame" | translate}}</p>
        </div>
    </div>
    <div class="col-sm-12 col-md-12 col-lg-7 bg-frame-soft">
        <div class="d-frame-soft" data-aos="fade-up" data-aos-once="true" data-aos-duration="1200">
            <div class="icons-frame-soft">
                <div class="config-img-frame-soft icon-fr1"></div>
                <p>{{"SOFTWARE.subTitleTec1" | translate}}</p>
            </div>
            <div class="icons-frame-soft">
                <div class="config-img-frame-soft icon-fr2"></div>
                <p>{{"SOFTWARE.subTitleTec2" | translate}}</p>
            </div>
            <div class="icons-frame-soft">
                <div class="config-img-frame-soft icon-fr3"></div>
                <p>{{"SOFTWARE.subTitleTec3" | translate}}</p>
                <p>{{"SOFTWARE.subTitleTec4" | translate}}</p>
            </div>
            <div class="icons-frame-soft">
                <div class="config-img-frame-soft icon-fr4"></div>
                <p>{{"SOFTWARE.subTitleTec5" | translate}}</p>
            </div>
        </div>
    </div>
</div>
