<nav class="nav-menu-m">
    <ul class="ul-nav">
        <li class="li-menu-mobile"><button mat-icon-button (click)="showMenu()"><mat-icon>menu</mat-icon></button></li>
        <li class="li-logo"> <div class="logo" [routerLink]="['/home']" routerLinkActive="router-link-active"></div> <!--<img class="logo" [routerLink]="['/home']" routerLinkActive="router-link-active"  src="../../assets/home/logo.png" alt="logo">--></li>
        <li class="li-language">
            <!-- <app-multi-language></app-multi-language> --> 
            <div class="btnRadius" [routerLink]="['/contactForeign']" matRipple>{{"pageAspForeign.header.btnGetTouch" | translate}}</div>
        </li>
    </ul>    
</nav>

<nav id="navMobile" class="content-menu-mobile" (click)="hideMenu($event)" [ngClass]="{'nav-visible':sidenav }">
    <ul class="ul-side" [@slideLeft]=currentState>
        <li class="li-logo"><div class="logo-side" [routerLink]="['/home']" (click)="hideMenuNav()"></div></li>
        <div class="d-options-menu">
            <li><button class="bt-menu-side" mat-button [routerLink]="['/homeAsp']" (click)="scrollToSection('home')"> {{"pageAspForeign.header.linkHome" | translate}}</button></li>
            <li><button class="bt-menu-side" mat-button [routerLink]="['/homeAsp']" (click)="scrollToSection('whatWeDo')"> {{"pageAspForeign.header.linkWTD" | translate}}</button></li>
            <li><button class="bt-menu-side" mat-button [routerLink]="['/ourServices']" routerLinkActive="active-list-item" (click)="hideMenuNav()"> {{"pageAspForeign.header.linkServices" | translate}}</button></li>
            <li><button class="bt-menu-side" mat-button [routerLink]="['/ourWork']" routerLinkActive="active-list-item" (click)="hideMenuNav()">{{"pageAspForeign.header.linkOurWork" | translate}}</button></li>
            <li><button class="bt-menu-side" mat-button [routerLink]="['/qbox']" routerLinkActive="active-list-item" (click)="hideMenuNav()">Qbox</button></li>
            <!-- <li><button class="bt-menu-side" mat-button [routerLink]="['/about-us']" routerLinkActive="active-list-item" (click)="hideMenuNav()">{{"pageAspForeign.header.linkCareers" | translate}}</button></li> -->
            <li><button class="bt-menu-side" mat-button [routerLink]="['/contactForeign']" routerLinkActive="active-list-item" (click)="hideMenuNav()">{{"pageAspForeign.header.btnGetTouch" | translate}}</button></li>


            <mat-accordion>
                <mat-expansion-panel (opened)="panelOpenState = true"
                                     (closed)="panelOpenState = false">
                  <mat-expansion-panel-header>
                    <mat-panel-title class="titlePanel">
                       {{"pageAspForeign.header.language" | translate}}
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <div class="linksLenguaje">
                    <button mat-button (click)="changeLanguage('es')"> {{"pageAspForeign.header.es" | translate}}</button>
                  <button mat-button (click)="changeLanguage('en')"> {{"pageAspForeign.header.en" | translate}}</button>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            <!-- <li>
                <mat-accordion>
                    <mat-expansion-panel class="m-panel" mat-button>
                    <mat-expansion-panel-header class="m-panel-h">
                        <mat-panel-title>
                            {{"MENU.service" | translate}}
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                        <button class="bt-sub-menu-side" *ngIf="!globals.mostrarIngles"  mat-button [routerLink]="['/erp']" routerLinkActive="active-list-item" (click)="hideMenuNav()">{{"MENU.erp" | translate}}</button>
                        <button class="bt-sub-menu-side" mat-button [routerLink]="['/software']" routerLinkActive="active-list-item" (click)="hideMenuNav()">{{"MENU.development" | translate}}</button>
                        <button class="bt-sub-menu-side" mat-button [routerLink]="['/outsourcing']" routerLinkActive="active-list-item" (click)="hideMenuNav()">{{"MENU.outsourcing" | translate}}</button>
                     
                    </mat-expansion-panel>
                </mat-accordion>
            </li>
            <li>
                <mat-accordion>
                    <mat-expansion-panel class="m-panel" mat-button>
                        <mat-expansion-panel-header class="m-panel-h">
                            <mat-panel-title>
                                {{"MENU.product" | translate}}
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <button class="bt-sub-menu-side" mat-button [routerLink]="['/jforms']" routerLinkActive="active-list-item" (click)="hideMenuNav()">{{"MENU.jform" | translate}}</button>
                        <button class="bt-sub-menu-side" mat-button [routerLink]="['/jreports']" routerLinkActive="active-list-item" (click)="hideMenuNav()">{{"MENU.jreport" | translate}}</button>
                        <button class="bt-sub-menu-side" mat-button [routerLink]="['/jmigrator']" routerLinkActive="active-list-item" (click)="hideMenuNav()">{{"MENU.jmigrator" | translate}}</button>
                    </mat-expansion-panel>
                </mat-accordion>
            </li> -->
        </div>
        <!-- <div class="d-footer-menu">
            <div class="d-title-footer-menu">
                <div>
                    {{"FOOTER.subTitle" | translate}}
                </div>
            </div>

            <div class="d-footer-icons">
                <div class="d-icon-menu" [routerLink]="['/contact']" fragment="map-asp" (click)="hideMenuNav()">
                    <mat-icon class="icon">room</mat-icon>
                </div>
                <a href="tel:{{celphone}}">
                    <div class="d-icon-menu" (click)="hideMenuNav()">
                        <mat-icon class="icon">phone_iphone</mat-icon>
                    </div>
                </a>
                <div class="d-icon-menu" [routerLink]="['/contact']" (click)="hideMenuNav()">
                    <mat-icon class="icon">email</mat-icon>
                </div>
            </div>
        </div> -->
    </ul>
</nav>