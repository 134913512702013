import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GlobalsService } from 'src/app/globals.service';

@Component({
  selector: 'app-asp-foreign-header',
  templateUrl: './asp-foreign-header.component.html',
  styleUrls: ['./asp-foreign-header.component.css']
})
export class AspForeignHeaderComponent implements OnInit {

  showFlag1: boolean = true;
  isHovered: boolean = false;
  language: string = "";

  
  public region:string;
  selectNav: string;

  constructor(private translate : TranslateService,
    public globals:GlobalsService) { 
  //   this.translate.addLangs(['es','en']);
  //  this.translate.setDefaultLang('en');
  //  this.language = navigator.language.substr(0,2);
  //   if (this.language !='es') {
  //     this.globals.mostrarIngles=true;
  //     this.language='en';
  //   } else{
  //     this.globals.mostrarIngles=false;
  //     this.language='es';
  //   }
  //   translate.use(this.language);
    }

  ngOnInit(): void {
  }

//   changeLanguage(language) : void{
//     this.globals.mostrarIngles=!this.globals.mostrarIngles;
//     this.translate.use(language);
//     this.language = language;
//   }

//   Ip(){
//     this.globals.getIp().subscribe(
//       response=>{
//       }
//     );
//   }

//   infoIp(){
//     this.globals.identityIP().subscribe( (response:any)=>{  
//       this.region=response.geoplugin_continentName;
//       },
//       err=>{
//         console.log('error');
//       }

//         );
    
// }

// startAnimation() {
//   this.isHovered = true;
// }

// stopAnimation() {
//   this.isHovered = false;
// }

// scrollToSection(sectionId: string) {
//   const element = document.getElementById(sectionId);
//   if (element) {

//     this.selectNav = sectionId;
    
//     const windowHeight = window.innerHeight;

//     const elementPosition = element.getBoundingClientRect();

//     const scrollPosition = elementPosition.top + window.scrollY - (windowHeight / 2) + 190;

    
//     window.scrollTo({ top: scrollPosition, behavior: 'smooth' });
//   }
// }



}
