<div class="row row-jsp7-cloud">
    <div class="col-sm-12 col-md-12 col-lg-12 col-jsp7-cloud">
        <div class="jsp7-cloud-cont">
            <h2 data-aos="fade-up" data-aos-once="true" data-aos-duration="800">{{"ERP.titleJsp7Cloud" | translate}}</h2>
            <div class="row sub-row-cloud">
                <div class="col-sm-12 col-md-1 col-lg-1"></div>
                <div class="col-sm-12 col-md-4 col-lg-4 d-cloud izq">
                    <p data-aos="fade-rirght" data-aos-once="true" data-aos-duration="800">
                        {{"ERP.paragrap1Jsp7Cloud" | translate}}
                    </p>
                </div>
                <div class="col-sm-12 col-md-1 col-lg-1 col-jsp7-cloud marg-right">
                    <div class="i-config-cloud mesa" data-aos="fade-right" data-aos-once="true" data-aos-duration="800"></div>
                </div>
                
                <div class="col-sm-12 col-md-1 col-lg-1 col-jsp7-cloud marg-left">
                    <div class="i-config-cloud serv_erp" data-aos="fade-left" data-aos-once="true" data-aos-duration="800"></div>
                </div>
                <div class="col-sm-12 col-md-4 col-lg-4 d-cloud der">
                    <p data-aos="fade-left" data-aos-once="true" data-aos-duration="800">
                        {{"ERP.paragrap2Jsp7Cloud" | translate}}
                    </p>
                </div>
            </div>
            <div class="row sub-row-cloud">
                <div class="col-sm-12 col-md-1 col-lg-1"></div>
                <div class="col-sm-12 col-md-4 col-lg-4 d-cloud izq">
                    <p data-aos="fade-right" data-aos-once="true" data-aos-duration="800">
                        {{"ERP.paragrap3Jsp7Cloud" | translate}}
                    </p>
                </div>
                <div class="col-sm-12 col-md-1 col-lg-1 col-jsp7-cloud marg-right">
                    <div class="i-config-cloud acceso_erp" data-aos="fade-right" data-aos-once="true" data-aos-duration="800"></div>
                </div>
                <div class="col-sm-12 col-md-1 col-lg-1 col-jsp7-cloud marg-left">
                    <div class="i-config-cloud serv_nube" data-aos="fade-left" data-aos-once="true" data-aos-duration="800"></div>
                </div>
                <div class="col-sm-12 col-md-4 col-lg-4 d-cloud der">
                    <p data-aos="fade-left" data-aos-once="true" data-aos-duration="800">
                        {{"ERP.paragrap4Jsp7Cloud" | translate}}
                    </p>
                </div>
            </div>
            <div class="row sub-row-cloud">
                <div class="col-sm-12 col-md-1 col-lg-1"></div>
                <div class="col-sm-12 col-md-4 col-lg-4 d-cloud izq">
                    <p data-aos="fade-right" data-aos-once="true" data-aos-duration="800">
                        {{"ERP.paragrap5Jsp7Cloud" | translate}}
                    </p>
                </div>
                <div class="col-sm-12 col-md-1 col-lg-1 col-jsp7-cloud marg-right">
                    <div class="i-config-cloud act_erp" data-aos="fade-right" data-aos-once="true" data-aos-duration="800"></div>
                </div>
                <div class="col-sm-12 col-md-1 col-lg-1 col-jsp7-cloud marg-left">
                    <div class="i-config-cloud db_erp" data-aos="fade-left" data-aos-once="true" data-aos-duration="800"></div>
                </div>
                <div class="col-sm-12 col-md-4 col-lg-4 d-cloud der">
                    <p data-aos="fade-left" data-aos-once="true" data-aos-duration="800">
                        {{"ERP.paragrap6Jsp7Cloud" | translate}}
                    </p>
                </div>
            </div>

            <!-- MOBILE -->
            <div class="cloud-mobile" data-aos="fade-up" data-aos-once="true" data-aos-duration="800">
                <div class="i-config-cloud-mobile mesa"></div>
                <p class="p-mobile">
                    {{"ERP.paragrap1Jsp7Cloud" | translate}}
                </p>
            </div>

            <div class="cloud-mobile" data-aos="fade-up" data-aos-once="true" data-aos-duration="800">
                <div class="i-config-cloud-mobile serv_erp"></div>
                <p class="p-mobile">
                    {{"ERP.paragrap2Jsp7Cloud" | translate}}
                </p>
            </div>

            <div class="cloud-mobile" data-aos="fade-up" data-aos-once="true" data-aos-duration="800">
                <div class="i-config-cloud-mobile acceso_erp"></div>
                <p class="p-mobile">
                    {{"ERP.paragrap3Jsp7Cloud" | translate}}
                </p>
            </div>

            <div class="cloud-mobile" data-aos="fade-up" data-aos-once="true" data-aos-duration="800">
                <div class="i-config-cloud-mobile serv_nube"></div>
                <p class="p-mobile">
                    {{"ERP.paragrap4Jsp7Cloud" | translate}}
                </p>
            </div>
            
            <div class="cloud-mobile" data-aos="fade-up" data-aos-once="true" data-aos-duration="800">
                <div class="i-config-cloud-mobile act_erp"></div>
                <p class="p-mobile">
                    {{"ERP.paragrap5Jsp7Cloud" | translate}}
                </p>
            </div>
            
            <div class="cloud-mobile" data-aos="fade-up" data-aos-once="true" data-aos-duration="800">
                <div class="i-config-cloud-mobile db_erp"></div>
                <p class="p-mobile">
                    {{"ERP.paragrap6Jsp7Cloud" | translate}}
                </p>
            </div>
        </div>
        <div class="candado" data-aos="fade-in" data-aos-once="true" data-aos-duration="600"></div>

    </div>
</div>