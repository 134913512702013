<footer class="footerWeb">
    <!-- <div class="icons"> 
        <div class="contentIcon">
            <mat-icon style="height: 28px; width: 28px; font-size: 28px;">language</mat-icon>
        </div>

        <div class="contentIcon">
            <i class="fab fa-linkedin-in"></i>
        </div>

        <div class="contentIcon">
            <i class="fab fa-facebook-f"></i>
        </div>

        <div class="contentIcon">
            <i class="fas fa-map-marker-alt"></i>
        </div>
    </div> -->
   
    <div class="txt">
        Copyright @ {{currentYear}} ASPSOLS LLC. All Rights Reserved.

    </div>
</footer>
