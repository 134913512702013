<nav class="nav-menu" [ngClass]="{'bg-nav-ws' : getBgNav() === true}">
  <ul class="ul-nav">
    <li class="li-logo"><div class="logo" [routerLink]="['/home']" routerLinkActive="router-link-active"></div></li>
    <li class="li-menu-general">
        <ul class="ul-menu">
        <li><button class="bt-menu" mat-button [routerLink]="['/home']" routerLinkActive="active-list-item">{{"MENU.home" | translate}}</button></li>
        <li><button class="bt-menu" mat-button [routerLink]="['/about-us']" routerLinkActive="active-list-item">{{"MENU.us" | translate}}</button></li>
        <li><button class="bt-menu" mat-button [matMenuTriggerFor]="services_us" [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active-list-item"><div class="arrow2"></div>{{"MENU.service" | translate}}
            <mat-menu #services_us="matMenu" class="animated fadeInDown fast">
              <button *ngIf="!globals.mostrarIngles" class="bt-sub-menu"  mat-menu-item [routerLink]="['/erp']" routerLinkActive="active-list-item">{{"MENU.erp" | translate}}</button>
              <button class="bt-sub-menu" mat-menu-item [routerLink]="['/software']" routerLinkActive="active-list-item">{{"MENU.development" | translate}}</button>
              <button class="bt-sub-menu" mat-menu-item [routerLink]="['/outsourcing']" routerLinkActive="active-list-item">{{"MENU.outsourcing" | translate}}</button>
             <!-- <button class="bt-sub-menu" mat-menu-item [routerLink]="['/wisesophia']" routerLinkActive="active-list-item">{{"MENU.wisesophianube" | translate}}</button>-->
            </mat-menu>
        </button>
        </li>
        <li><button class="bt-menu" mat-button [matMenuTriggerFor]="products_us" [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active-list-item"><div class="arrow"></div>{{"MENU.product" | translate}}
          <mat-menu #products_us="matMenu" class="animated fadeInDown fast">
            <button class="bt-sub-menu" mat-menu-item [routerLink]="['/jforms']" routerLinkActive="active-list-item">{{"MENU.jform" | translate}}</button>
            <button class="bt-sub-menu" mat-menu-item [routerLink]="['/jreports']" routerLinkActive="active-list-item">{{"MENU.jreport" | translate}}</button>
<!--               <button class="bt-sub-menu" mat-menu-item [routerLink]="['/jformsruntime']" routerLinkActive="active-list-item">{{"MENU.jformRuntime" | translate}}</button>
-->          
            <button class="bt-sub-menu" mat-menu-item [routerLink]="['/jmigrator']" routerLinkActive="active-list-item">{{"MENU.jmigrator" | translate}}</button>
           <!--  <button class="bt-sub-menu" mat-menu-item [routerLink]="['/wisesophia']" routerLinkActive="active-list-item">{{"MENU.wisesophia" | translate}}</button>-->
          </mat-menu>
      </button>
      </li>
        <li><button class="bt-menu" mat-button [routerLink]="['/contact']" routerLinkActive="active-list-item">{{"MENU.contact" | translate}}</button></li>
        </ul>
    </li>
    <li class="li-language">
        <app-multi-language></app-multi-language>
    </li>
  </ul>
</nav>
