import { Component, HostListener, OnInit } from '@angular/core';

declare var bodymovin:any;

@Component({
  selector: 'app-what-we-do',
  templateUrl: './what-we-do.component.html',
  styleUrls: ['./what-we-do.component.css']
})
export class WhatWeDoComponent implements OnInit {
  showLeft = false;
  showRight = false;

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.loadAnimation();
    }, 100);
  }
   
  loadAnimation(){
    var animation = bodymovin.loadAnimation({
      container: document.getElementById('animation2'),
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: '../../../assets/pageForeignAsp/animations/data6.json'
    })
    var animation2 = bodymovin.loadAnimation({
      container: document.getElementById('animation3'),
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: '../../../assets/pageForeignAsp/animations/WeManage.json'
    })
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(event: Event): void {
    const windowHeight = window.innerHeight;
    const scrollValue = window.scrollY;
    const chartContents = document.querySelectorAll('.chartContent');

    chartContents.forEach((element: HTMLElement, index: number) => {
      const chartContentTop = element.getBoundingClientRect().top;
      const triggerPosition = chartContentTop - windowHeight + 300 * (index + 1);

      if (scrollValue > triggerPosition && !element.classList.contains('show')) {
        element.classList.add('show');
      }
    });
  }


}
