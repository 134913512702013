<div class="row row-feat">
  <div class="col-sm-12 col-md-12 col-lg-12 col-feat">
    <h2 class="title-feat" data-aos="zoom-in" data-aos-once="true" data-aos-duration="600">{{"HOME.titleFeatured" | translate}}</h2>
      <div class="feat-tabs">
        <mat-tab-group mat-stretch-tabs class="m-tab-gro-feat">
            <mat-tab>
              <ng-template mat-tab-label>
                <div class="m-tab-lab-feat">
                  <div class="img-jf"></div>
                  <h6>{{"HOME.titleTab1Featured" | translate}}</h6>
                </div>
              </ng-template>

              <div class="row row-content-feat">
                <div class="col-sm-2 col-md-2 col-lg-2 col-feat col-mob">
                  <div class="row f-w f-w-margin" data-aos="zoom-in" data-aos-once="true" data-aos-duration="1200">
                    <div class="i-j-feat cjf1"></div>
                    <p class="title-i-j-feat">{{"HOME.att1JFB" | translate}}</p>
                  </div>
                  <div class="row f-w" data-aos="zoom-in" data-aos-once="true" data-aos-duration="1200">
                    <div class="i-j-feat cjf2"></div>
                    <p class="title-i-j-feat">{{"HOME.att2JFB" | translate}}</p>
                  </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-8 col-feat col-display-center" data-aos="zoom-in" data-aos-once="true" data-aos-duration="1200">
                  <div class="img-parent-izq"></div>
                  <div class="content-middle">
                    <h2>{{"HOME.subTitle1Featured" | translate}}</h2>
                    <p>
                      {{"HOME.paragraph1Featured" | translate}}
                    </p>
                    <button class="bt-v-jf" mat-flat-button [routerLink]="['/jforms']">{{"HOME.button1Featured" | translate}}</button>
                  </div>
                  <div class="img-parent-der"></div>
                </div>
                <div class="col-sm-2 col-md-2 col-lg-2 col-feat col-mob">
                  <div class="row f-w f-w-margin" data-aos="zoom-in" data-aos-once="true" data-aos-duration="1200">
                    <div class="i-j-feat cjf3"></div>
                    <p class="title-i-j-feat">{{"HOME.att3JFB" | translate}}</p>
                  </div>
                  <div class="row f-w" data-aos="zoom-in" data-aos-once="true" data-aos-duration="1200">
                    <div class="i-j-feat cjf4"></div>
                    <p class="title-i-j-feat">{{"HOME.att4JFB" | translate}}</p>
                  </div>
                </div>
              </div>
            </mat-tab>

            <mat-tab>
              <ng-template mat-tab-label>
                <div class="m-tab-lab-feat">
                  <div class="img-jr"></div>
                  <h6>{{"HOME.titleTab2Featured" | translate}}</h6>
                </div>
              </ng-template>
              <div class="row row-content-feat">
                <div class="col-sm-2 col-md-2 col-lg-2 col-feat col-mob">
                  <div class="row f-w f-w-margin" data-aos="zoom-in" data-aos-once="true" data-aos-duration="1200">
                    <div class="i-j-feat cjr1"></div>
                    <p class="title-i-j-feat">{{"HOME.att1JRB" | translate}}</p>
                  </div>
                  <div class="row f-w" data-aos="zoom-in" data-aos-once="true" data-aos-duration="1200">
                    <div class="i-j-feat cjr2"></div>
                    <p class="title-i-j-feat">{{"HOME.att2JRB" | translate}}</p>
                  </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-8 col-feat col-display-center" data-aos="zoom-in" data-aos-once="true" data-aos-duration="1200">
                  <div class="img-parent-izq"></div>
                  <div class="content-middle">
                    <h2>{{"HOME.subTitle2Featured" | translate}}</h2>
                    <p>
                      {{"HOME.paragraph2Featured" | translate}}
                    </p>
                    <button class="bt-v-jf" mat-flat-button [routerLink]="['/jreports']">{{"HOME.button1Featured" | translate}}</button>
                  </div>
                  <div class="img-parent-der"></div>
                </div>
                <div class="col-sm-2 col-md-2 col-lg-2 col-feat col-mob">
                  <div class="row f-w f-w-margin" data-aos="zoom-in" data-aos-once="true" data-aos-duration="1200">
                    <div class="i-j-feat cjr3"></div>
                    <p class="title-i-j-feat">{{"HOME.att3JRB" | translate}}</p>
                  </div>
                  <div class="row f-w" data-aos="zoom-in" data-aos-once="true" data-aos-duration="1200">
                    <div class="i-j-feat cjr4"></div>
                    <p class="title-i-j-feat">{{"HOME.att4JRB" | translate}}</p>
                  </div>
                </div>
              </div>
            </mat-tab>

<!-- JForms Runtime -->
            <!-- <mat-tab>
              <ng-template mat-tab-label>
                <div class="m-tab-lab-feat">
                  <div class="img-rt"></div>
                  <h6>{{"HOME.titleTab3Featured" | translate}}</h6>
                </div>
              </ng-template>
              <div class="row row-content-feat">
                <div class="col-sm-2 col-md-2 col-lg-2 col-feat col-mob">
                  <div class="row f-w f-w-margin" data-aos="zoom-in" data-aos-once="true" data-aos-duration="1200">
                    <div class="i-j-feat cjr1"></div>
                    <p class="title-i-j-feat">{{"HOME.att1JRB" | translate}}</p>
                  </div>
                  <div class="row f-w" data-aos="zoom-in" data-aos-once="true" data-aos-duration="1200">
                    <div class="i-j-feat cjr2"></div>
                    <p class="title-i-j-feat">{{"HOME.att2JRB" | translate}}</p>
                  </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-8 col-feat col-display-center" data-aos="zoom-in" data-aos-once="true" data-aos-duration="1200">
                  <div class="img-parent-izq"></div>
                  <div class="content-middle">
                    <h2>{{"HOME.subTitle2Featured" | translate}}</h2>
                    <p>
                      {{"HOME.paragraph2Featured" | translate}}
                    </p>
                    <button class="bt-v-jf" mat-flat-button [routerLink]="['/jforms']">{{"HOME.button1Featured" | translate}}</button>
                  </div>
                  <div class="img-parent-der"></div>
                </div>
                <div class="col-sm-2 col-md-2 col-lg-2 col-feat col-mob">
                  <div class="row f-w f-w-margin" data-aos="zoom-in" data-aos-once="true" data-aos-duration="1200">
                    <div class="i-j-feat cjr3"></div>
                    <p class="title-i-j-feat">{{"HOME.att3JRB" | translate}}</p>
                  </div>
                  <div class="row f-w" data-aos="zoom-in" data-aos-once="true" data-aos-duration="1200">
                    <div class="i-j-feat cjr4"></div>
                    <p class="title-i-j-feat">{{"HOME.att4JRB" | translate}}</p>
                  </div>
                </div>
              </div>
            </mat-tab>


 -->


            <mat-tab>
              <ng-template mat-tab-label>
                <div class="m-tab-lab-feat">
                  <div class="img-jm"></div>
                  <h6>{{"HOME.titleTab4Featured" | translate}}</h6>
                </div>
              </ng-template>
              <div class="row row-content-feat">
                <div class="col-sm-2 col-md-2 col-lg-2 col-feat col-mob">
                  <div class="row f-w f-w-margin" data-aos="zoom-in" data-aos-once="true" data-aos-duration="1200">
                    <div class="i-j-feat cjm1"></div>
                    <p class="title-i-j-feat">{{"HOME.att1JM" | translate}}</p>
                  </div>
                  <div class="row f-w" data-aos="zoom-in" data-aos-once="true" data-aos-duration="1200">
                    <div class="i-j-feat cjm2"></div>
                    <p class="title-i-j-feat">{{"HOME.att2JM" | translate}}</p>
                  </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-8 col-feat col-display-center" data-aos="zoom-in" data-aos-once="true" data-aos-duration="1200">
                  <div class="img-parent-izq"></div>
                  <div class="content-middle">
                    <h2>{{"HOME.subTitle4Featured" | translate}}</h2>
                    <p>
                      {{"HOME.paragraph4Featured" | translate}}
                    </p>
                    <button class="bt-v-jf" mat-flat-button [routerLink]="['/jmigrator']">{{"HOME.button1Featured" | translate}}</button>
                  </div>
                  <div class="img-parent-der"></div>
                </div>
                <div class="col-sm-2 col-md-2 col-lg-2 col-feat col-mob">
                  <div class="row f-w f-w-margin" data-aos="zoom-in" data-aos-once="true" data-aos-duration="1200">
                    <div class="i-j-feat cjm3"></div>
                    <p class="title-i-j-feat">{{"HOME.att3JM" | translate}}</p>
                  </div>
                  <div class="row f-w" data-aos="zoom-in" data-aos-once="true" data-aos-duration="1200">
                    <div class="i-j-feat cjm4"></div>
                    <p class="title-i-j-feat">{{"HOME.att4JM" | translate}}</p>
                  </div>
                </div>
              </div>
            </mat-tab>

            <mat-tab>
              <ng-template mat-tab-label>
                <div class="m-tab-lab-feat">
                  <div class="img-ws"></div>
                  <h6>{{"HOME.titleTab5Featured" | translate}}</h6>
                </div>
              </ng-template>
              <div class="row row-content-feat">
                <div class="col-sm-2 col-md-2 col-lg-2 col-feat col-mob">
                  <div class="row f-w f-w-margin" data-aos="zoom-in" data-aos-once="true" data-aos-duration="1200">
                    <div class="i-j-feat cws1"></div>
                    <p class="title-i-j-feat">{{"HOME.att1WS" | translate}}</p>
                  </div>
                  <div class="row f-w" data-aos="zoom-in" data-aos-once="true" data-aos-duration="1200">
                    <div class="i-j-feat cws2"></div>
                    <p class="title-i-j-feat">{{"HOME.att2WS" | translate}}</p>
                  </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-8 col-feat col-display-center" data-aos="zoom-in" data-aos-once="true" data-aos-duration="1200">
                  <div class="img-parent-izq"></div>
                  <div class="content-middle">
                    <!--<div class="img-wise-s"></div>--> 
                    <h2>{{"HOME.subTitle5Featured" | translate}}</h2>
                    <p>
                     <!-- {{"HOME.paragraphWiseSophia" | translate}}--> 
                      {{"HOME.paragraphBi" | translate}}
                    </p>
                    <button class="bt-v-jf" mat-flat-button [routerLink]="['/wisesophia']">{{"HOME.button1Featured" | translate}}</button>
                  </div>
                  <div class="img-parent-der"></div>
                </div>
                <div class="col-sm-2 col-md-2 col-lg-2 col-feat col-mob">
                  <div class="row f-w f-w-margin" data-aos="zoom-in" data-aos-once="true" data-aos-duration="1200">
                    <div class="i-j-feat cws3"></div>
                    <p class="title-i-j-feat">{{"HOME.att3WS" | translate}}</p>
                  </div>
                  <div class="row f-w" data-aos="zoom-in" data-aos-once="true" data-aos-duration="1200">
                    <div class="i-j-feat cws4"></div>
                    <p class="title-i-j-feat">{{"HOME.att4WS" | translate}}</p>
                  </div>
                </div>
              </div>
            </mat-tab>

        </mat-tab-group>
      </div>
  </div>
</div>
