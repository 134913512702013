import { Component, OnInit } from '@angular/core';
import { interval } from 'rxjs';

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.css']
})
export class TeamComponent implements OnInit {

  constructor() { }

  years : number = 0;
  ing : number = 0;
  proy : number = 0;

  Counter(){
    for (let i = 0; i <= 200; i++) {
      if(this.years < 20){
        this.years = i;
      }

      if(this.ing < 85){
        this.ing = i;
      }

      this.proy = i;

    }
  }

  ngOnInit() {
      this.Counter();
  }

}
