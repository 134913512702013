import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { ErpComponent } from './erp/erp.component';
import { ContactComponent } from './contact/contact.component';
import { JformsComponent } from './jforms/jforms.component';
import { JmigratorComponent } from './jmigrator/jmigrator.component';
import { JreportsComponent } from './jreports/jreports.component';
import { JformsruntimeComponent } from './jformsruntime/jformsruntime.component';
import { SoftwareComponent } from './software/software.component';
import { OutsourcingComponent } from './outsourcing/outsourcing.component';
// import { PageAspForeignMainComponent } from './pageAspForeign/page-asp-foreign-main/page-asp-foreign-main.component';
import { WisesophiaComponent } from './wisesophia/wisesophia.component';
import { CountryGuardGuard } from './country-guard.guard';
import { CountryGuardNoColGuard } from './country-guard-no-col.guard';


const routes: Routes = [
  {path: '', redirectTo:'/home', pathMatch:'full', canActivate: [CountryGuardGuard]},
  {path: 'home', component: HomeComponent, canActivate: [CountryGuardGuard]},
  {path: 'about-us', component: AboutUsComponent, canActivate: [CountryGuardGuard]},
  {path: 'jforms', component: JformsComponent, canActivate: [CountryGuardGuard]},
  {path: 'jreports', component: JreportsComponent, canActivate: [CountryGuardGuard]},
  {path: 'jmigrator', component: JmigratorComponent, canActivate: [CountryGuardGuard]},
  {path: 'jformsruntime', component: JformsruntimeComponent, canActivate: [CountryGuardGuard]},
  {path: 'wisesophia',  redirectTo:'/home', pathMatch:'full', canActivate: [CountryGuardGuard]},
  {path: 'erp', component: ErpComponent, canActivate: [CountryGuardGuard]},
  {path: 'software', component: SoftwareComponent, canActivate: [CountryGuardGuard]},
  {path: 'outsourcing', component: OutsourcingComponent, canActivate: [CountryGuardGuard]},
  {path: 'contact', component: ContactComponent, canActivate: [CountryGuardGuard]},
  {path: 'homeForeign', loadChildren: () => import('./foreign.module').then(m => m.ForeignModule), canActivate: [CountryGuardNoColGuard]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, anchorScrolling: 'enabled', relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
export const routingComponents = [HomeComponent, AboutUsComponent, JformsComponent, JreportsComponent, JmigratorComponent, JformsruntimeComponent, ErpComponent, SoftwareComponent, ContactComponent];
