<div class="row row-short-soft">
    <div class="col-sm-12 col-md-12 col-lg-12 col-short-soft">
        <div class="group-btn-short-soft" data-aos="fade-up" data-aos-once="true" data-aos-duration="1000">
            <a mat-raised-button (click)="goMobileDev()">
                <div class="content-btn-short-soft">
                    <div class="config-i-short-soft i-soft1"></div>
                    <div class="content-i-short-soft">
                        <h6>{{"SOFTWARE.titleBtn1" | translate}}</h6>
                        <p>{{"SOFTWARE.subTitleBtn1" | translate}}</p>
                    </div>
                </div>
            </a>

            <a mat-raised-button (click)="goIntegration()">
                <div class="content-btn-short-soft">
                    <div class="config-i-short-soft i-soft2"></div>
                    <div class="content-i-short-soft">
                        <h6>{{"SOFTWARE.titleBtn2" | translate}}</h6>
                        <p>{{"SOFTWARE.subTitleBtn2" | translate}}</p>
                    </div>
                </div>
            </a>

            <a mat-raised-button (click)="goBackend()">
                <div class="content-btn-short-soft">
                    <div class="config-i-short-soft i-soft3"></div>
                    <div class="content-i-short-soft">
                        <h6>{{"SOFTWARE.titleBtn3" | translate}}</h6>
                    </div>
                </div>
            </a>
        </div>
        <div class="group-btn-short-soft" data-aos="fade-up" data-aos-once="true" data-aos-duration="1000">
            <a mat-raised-button (click)="goBI()">
                <div class="content-btn-short-soft">
                    <div class="config-i-short-soft i-soft4"></div>
                    <div class="content-i-short-soft">
                        <h6>{{"SOFTWARE.titleBtn4" | translate}}</h6>
                    </div>
                </div>
            </a>
<!-- 
            <a mat-raised-button>
                <div class="content-btn-short-soft">
                    <div class="config-i-short-soft i-soft5"></div>
                    <div class="content-i-short-soft">
                        <h6>{{"SOFTWARE.titleBtn5" | translate}}</h6>
                        <p>{{"SOFTWARE.subTitleBtn5" | translate}}</p>
                    </div>
                </div>
            </a>
 -->

 <!--  METODO SCRUM BOTON ELIMINADO Y METOLOGIA PASO DE SERVICIOS A NOSOROS
            <a mat-raised-button (click)="goMethodology()">
                <div class="content-btn-short-soft">
                    <div class="config-i-short-soft i-soft6"></div>
                    <div class="content-i-short-soft">
                        <h6>{{"SOFTWARE.titleBtn6" | translate}}</h6>
                        <p>{{"SOFTWARE.subTitleBtn6" | translate}}</p>
                    </div>
                </div>
            </a>
-->
        </div>
    </div>
</div>
