import { Component, OnInit } from '@angular/core';
import { GuestInfoService } from 'src/app/services/guest-info.service';
import{GlobalsService} from'../../globals.service';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.css']
})
export class WelcomeComponent implements OnInit {

  constructor( public globals:GlobalsService, private guestService : GuestInfoService,) { }

  ip;
  country;
  


  ngOnInit() {

    this.guestInfo();


    this.guestService.getGuestInfo(this.ip)
    .subscribe((result:any) => {
      this.country = result;
      this.country = result.geoplugin_countryName;
      if(this.country === 'Colombia'){
        this.globals.mostrarIngles;
        this.globals.mostrar_colombia=true;

      }else{
        this.globals.mostrarIngles;
        this.globals.mostrar_colombia=false;

      }
      console.log('estoy en colombia',this.globals.mostrar_colombia);
    });
  }

  goServices(){
    window.scrollTo({ top:1170, left: 0 });
  }


  guestInfo(){
    this.guestService.getGuestIp()
    .subscribe(result => {
      this.ip = result;
      this.ip = this.ip.ip;
    })
  }
}