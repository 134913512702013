import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-content-outsourcing',
  templateUrl: './content-outsourcing.component.html',
  styleUrls: ['./content-outsourcing.component.css']
})
export class ContentOutsourcingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
