import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { PageAspForeignMainComponent } from './pageAspForeign/page-asp-foreign-main/page-asp-foreign-main.component';
import { OurServicesComponent } from './pageAspForeign/our-services/our-services.component';
import { CountryGuardNoColGuard } from './country-guard-no-col.guard';
import { PageOurWorkComponent } from './pageAspForeign/page-our-work/page-our-work.component';
import { ContactAspForeignComponent } from './pageAspForeign/contact-asp-foreign/contact-asp-foreign.component';
import { PrivacyPolicyComponent } from './pageAspForeign/privacy-policy/privacy-policy.component';
import { PageQboxComponent } from './pageAspForeign/page-qbox/page-qbox.component';

const routes: Routes = [
  {path: '', redirectTo:'/homeAsp', pathMatch:'full', canActivate: [CountryGuardNoColGuard]},
  { path: 'homeAsp', component: PageAspForeignMainComponent, canActivate: [CountryGuardNoColGuard] }, // Ruta por defecto, página principal
  { path: 'ourServices', component: OurServicesComponent, canActivate: [CountryGuardNoColGuard] }, // Ruta a OurServices 
  { path: 'ourWork', component: PageOurWorkComponent, canActivate: [CountryGuardNoColGuard] }, // Ruta a ourWork
  { path: 'contactForeign', component: ContactAspForeignComponent, canActivate: [CountryGuardNoColGuard]},
  { path: 'privacyPolicy', component: PrivacyPolicyComponent, canActivate: [CountryGuardNoColGuard]},
  { path: 'qbox', component: PageQboxComponent, canActivate: [CountryGuardNoColGuard]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, anchorScrolling: 'enabled', relativeLinkResolution: 'legacy' })],
// imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class AppRoutingModuleForeign { }
export const routingComponentsForeign = [PageAspForeignMainComponent, OurServicesComponent, PageOurWorkComponent, ContactAspForeignComponent];
// export const routingComponents = [PageAspForeignMainComponent];