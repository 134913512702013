<!-- Outsourcing (Part1) -->
<div class="row row-wel-out">
    <div class="col-sm-12 col-md-12 col-lg-12 bg-wel-out">
        <div class="wel-out" data-aos="fade-left" data-aos-once="true" data-aos-duration="1000">
            <h3>{{"OUTSOURCING.titleWelcome" | translate}}</h3>
            <p>{{"OUTSOURCING.paragraphWelcome" | translate}}</p>
        </div>
    </div>
</div>





