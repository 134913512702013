<section class="containerBrands">
    <div class="imgLeft">
        <!-- <img src="../../../assets/pageForeignAsp/alliedBrands/img-lights-izq.svg"> -->
    </div>

    <div class="contentBrands">
        <h2 class="title">{{"pageAspForeign.brands.label" | translate}}</h2>
        <div class="contentBrans">
            <div class="contentImgBrands">
                <div class="brands"> <img src="../../../assets/pageForeignAsp/alliedBrands/img-logo-anuvu.svg" class="sizeImgTxt"> </div>
                <div class="brands"> <img src="../../../assets/pageForeignAsp/alliedBrands/logo-Meridian.svg" class="sizeImgTxt"> </div>
                <div class="brands"> <img src="../../../assets/pageForeignAsp/alliedBrands/img-logo-UST.svg" class="sizeImgSquare"> </div>
            </div>

            <div class="contentImgBrands"> 
                <div class="brands sizeImgTxt"> <img src="../../../assets/pageForeignAsp/alliedBrands/img-logo-SEED.svg" class="sizeImgTxt"> </div>
                <div class="brands sizeImgTxt"> <img src="../../../assets/pageForeignAsp/alliedBrands/img-logo-Landline.svg" class="sizeImgTxt"> </div>
                <div class="brands"> <img src="../../../assets/pageForeignAsp/alliedBrands/logo-mba.svg" class="sizeImgMba"> </div>
            </div>
        </div>
    </div>

    <div class="imgRigth">
        <!-- <img src="../../../assets/pageForeignAsp/alliedBrands/img-lights-der.svg"> -->
    </div>
</section>
