import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { trigger, state, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'app-arq-org',
  templateUrl: './arq-org.component.html',
  styleUrls: ['./arq-org.component.css'],
  animations: [
    trigger('fadeInOut', [
      state('initial', style({
        visibility : "hidden",
        opacity : 0
      })),
      state('final', style({
        visibility : "visible",
        opacity : 1
      })),
      transition('initial=>final', animate('500ms')),
      transition('final=>initial', animate('500ms'))
    ]),
  ]
})
export class ArqOrgComponent implements OnInit {

  jforms : Boolean;
  jreports : Boolean;
  jformsrun : Boolean;
  jmigrator : Boolean;

  currentState : string = "initial";
  clsImg : string;
  constructor(private route : Router) {}

  showLightBox(Img){
    this.clsImg = Img;
    this.currentState = this.currentState === 'initial' ? 'final' : 'initial';
  }

  hideLightBox(e){
    if(e.target.id == "lightBox"){
      this.currentState = this.currentState === 'initial' ? 'final' : 'initial';
    }
  }

  ngOnInit() {
    this.jforms = false;
    this.jreports = false;
    this.jformsrun = false;
    this.jmigrator = false;

    if(this.route.url == "/jforms"){
      this.jforms = true;
    }else if(this.route.url == "/jreports"){
      this.jreports = true;
    }else if(this.route.url == "/jformsruntime"){
      this.jformsrun = true;
    }else if(this.route.url == "/jmigrator"){
      this.jmigrator = true;
    }
  }

}
