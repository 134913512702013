<div class="row row-metho">
    <div class="col-sm-12 col-md-12 col-lg-6 col-img-metho-soft">
        <div class="bg-metho-soft" data-aos="fade-right" data-aos-once="true" data-aos-duration="1400"></div>
    </div>

    <div class="col-sm-12 col-md-12 col-lg-6 ">
        <div class="cont-metho" data-aos="fade-left" data-aos-once="true" data-aos-duration="1400">
            <h2>{{"SOFTWARE.titleMethodology" | translate}}</h2>
            <p>{{"SOFTWARE.paragraphMethodology" | translate}}</p>
        </div>
    </div>
</div>

