<section class="contentExperience">
    <div class="titleExperienceMobile"><h1 class="title"> {{"pageAspForeign.EXPERIENCE.title" | translate}}</h1></div>

    <div class="contentImgExperience">
        <img class="imgExperience" *ngIf="!globals.mostrarIngles" src="../../../assets/pageForeignAsp/experience/img_diagram Horizon-experienceSpanish.svg" alt="Experience">
        <img class="imgExperience" *ngIf="globals.mostrarIngles" src="../../../assets/pageForeignAsp/experience/img_diagram Horizon-experience.svg" alt="Experience">
    </div>

    <div class="contentHelp">
        <div class="btnRadius buttonHelp" (click)="navigateContact()" matRipple>{{"pageAspForeign.EXPERIENCE.btn" | translate}}</div>
        <img class="imgHelp" *ngIf="globals.mostrarIngles" src="../../../assets/pageForeignAsp/experience/helpExperience.svg" alt="Help">
        <img class="imgHelp" *ngIf="!globals.mostrarIngles" src="../../../assets/pageForeignAsp/experience/helpExperienceSpanish.svg" alt="Help">
    </div>
</section>
