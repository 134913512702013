import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalsService } from 'src/app/globals.service';
declare var bodymovin:any;

@Component({
  selector: 'app-our-services',
  templateUrl: './our-services.component.html',
  styleUrls: ['./our-services.component.css']
})
export class OurServicesComponent implements OnInit {

  constructor(private router: Router, public globals: GlobalsService) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.loadAnimation();
    }, 100);
  }
   
  loadAnimation(){
    var developer1 = bodymovin.loadAnimation({
      container: document.getElementById('developer1'),
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: '../../../assets/pageForeignAsp/animations/img-Direct-Developer.json'
    })

    var developer2 = bodymovin.loadAnimation({
      container: document.getElementById('developer2'),
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: '../../../assets/pageForeignAsp/animations/GuidedProductManagement.json'
    })

    var ligthOurServices = bodymovin.loadAnimation({
      container: document.getElementById('ligthOurServices'),
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: '../../../assets/pageForeignAsp/animations/ourServicesLigth.json'
    })
  }

  navigateContact(){
    window.scrollTo(0, 0);
    this.router.navigateByUrl('/contactForeign');
    this.globals.selectNav = ""
  }

}
