<div class="row">
  <div class="col-sm-12 col-md-12 col-lg-12 fondo "  > 
    <div [ngClass]="{'fondo-mision': mision === true, 'fondo-vision': vision === true, 'fondo-oferta':oferta ===true }">
    </div>



    <div class="btn-mision">
      <button class="bt-m animated pulse slow" [ngClass]="{'btn-act' : mision === true}" mat-raised-button (click)="selectedBtn('mision')"><div class="imgs-m-v-o img-mision" [ngClass]="{'btn-act-mision' : mision === true}"></div><span>{{"US.subTitltMision" | translate}}</span></button>
      <button class="bt-v animated pulse slow" [ngClass]="{'btn-act' : vision === true}" mat-raised-button (click)="selectedBtn('vision')"><div class="imgs-m-v-o img-vision" [ngClass]="{'btn-act-vision' : vision === true}"></div><span>{{"US.subTitltVision" | translate}}</span></button>
      <button class="bt-o animated pulse slow" [ngClass]="{'btn-act' : oferta === true}" mat-raised-button (click)="selectedBtn('oferta')"><div class="imgs-m-v-o img-oferta" [ngClass]="{'btn-act-oferta' : oferta === true}"></div><span>{{"US.subTitltOffer" | translate}}</span></button>
    </div>
  
  

    <div *ngIf="mision" class="content-mision animated fadeIn">
    
        <h2>{{"US.titleMision" | translate}}</h2>
        <p>
          {{"US.paragraphMision" | translate}}
        </p>  
      </div>
    <div *ngIf="vision" class="content-mision animated fadeIn">
      <h2>{{"US.titleVision" | translate}}</h2>
      <p *ngFor="let p of 'US.paragraphVision' | translate">
        {{p}}
      </p>
    </div>
    <div *ngIf="oferta" class="content-mision animated fadeIn">
      <h2>{{"US.titleOffer" | translate}}</h2>
      <p *ngFor="let p of 'US.paragraphOffer' | translate">
        {{p}}
      </p>
    </div>




  </div>
</div>
