import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';
import{GlobalsService} from'../../globals.service';

@Component({
  selector: 'app-menu-desktop',
  templateUrl: './menu-desktop.component.html',
  styleUrls: ['./menu-desktop.component.css']
})
export class MenuDesktopComponent implements OnInit {

  constructor(private router : Router, public globals:GlobalsService) {}

   exact: boolean;

  getBgNav() : Boolean{
    if(this.router.url == "/wisesophia"){
      return true;
    }
    return false;
  }


  ngOnInit() {
  }

}
