import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-jsp7-cloud',
  templateUrl: './jsp7-cloud.component.html',
  styleUrls: ['./jsp7-cloud.component.css']
})
export class Jsp7CloudComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
