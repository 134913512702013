import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-allied-brands',
  templateUrl: './allied-brands.component.html',
  styleUrls: ['./allied-brands.component.css']
})
export class AlliedBrandsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
