<ng-container *ngIf="userLocality">
  <header id="nav" >
    <app-menu ></app-menu>
  </header>
  <!-- <app-top></app-top> -->

  <router-outlet class="content"></router-outlet>

  <footer>
    <app-footer></app-footer>
  </footer>
</ng-container>

<ng-container *ngIf="!userLocality">
  <app-asp-foreign-header></app-asp-foreign-header>

    <router-outlet class="content2"></router-outlet>

  <app-footer-foreign></app-footer-foreign>
</ng-container>


