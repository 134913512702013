import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders  } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SendMailService {

  constructor(private http : HttpClient) { }

  //BaseUrl = "http://localhost:9696";
  BaseUrl = "";
 /* sendMail(datos){
    return this.http.post(this.BaseUrl+"/PageASP/sendMail", datos);
  }*/
/* 
  sendMail(datos){
    return this.http.post(this.BaseUrl+"/sendMail", datos);
  }

  sendMail2(datos){
    return this.http.post(this.BaseUrl+"/sendMail/mail2/", datos);
  }  */

  /*
  sendMail(datos){
    return this.http.post(this.BaseUrl+"/PageASP/sendMail", datos);
  }

  sendMail2(datos){
    return this.http.post(this.BaseUrl+"/PageASP/sendMail/mail2/", datos);
  } 
*/

/* ServidorMail */

  sendMail(datos){
    let params=JSON.stringify(datos);
    let headers=new HttpHeaders().set('Content-Type','application/json');
    return this.http.post("https://dev01.aspsols.com:8033/sendMail", params,{headers:headers});
  }
}
