import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators} from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { SendMailService } from '../../services/send-mail.service';
import{GlobalsService} from'../../globals.service';


@Component({
  selector: 'app-form-contact',
  templateUrl: './form-contact.component.html',
  styleUrls: ['./form-contact.component.css']
})
export class FormContactComponent implements OnInit {

  constructor(private dialog: MatDialog, private Mail : SendMailService, private fb : FormBuilder, public globals:GlobalsService) { }

  datos = {
    nombreCompleto : "",
    email : "",
    telefono : "",
    empresa : "",
    solicitud : "",
    mensaje : ""
  };

  //FormControl
  EmailForm = this.fb.group({
    NombreCompleto : new FormControl('',[Validators.required]),
    Email : new FormControl('',[Validators.required, Validators.email]),
    Telefono : new FormControl('',[Validators.required, Validators.min(1)]),
    Empresa : new FormControl(),
    Solicitud : new FormControl('',[Validators.required]),
    Mensaje : new FormControl('',[Validators.required, Validators.maxLength(300)])
  });

  checkPoliticas:boolean =false;
  //Return status of Form
  get controlsForm() { return this.EmailForm.controls; }

  sendMessage(){
    if(this.EmailForm.invalid || (this.controlsForm.NombreCompleto.value as string).trim() == '' || (this.controlsForm.Mensaje.value as string).trim() == ''){
      if((this.controlsForm.NombreCompleto.value as string).trim() == ''){
        this.controlsForm.NombreCompleto.setValue('');
      }
      if((this.controlsForm.Mensaje.value as string).trim() == ''){
        this.controlsForm.Mensaje.setValue('');
      }
      return;
    }else{
      this.send();
    }

  }
  verMasPoliticas(){
    window.open("https://www.aspsols.com/AspSolutionsPage2016/resources/Politica_Tratamiento_Datos_Personales_ASPSOLUTIONS.pdf","","Location=NO");
  }

  send(){
    this.Mail.sendMail(this.datos)
      .subscribe(() => {
        this.openDialog(true);
    });

    this.datos = {
      nombreCompleto : "",
      email : "",
      telefono : "",
      empresa : "",
      solicitud : "",
      mensaje : ""
    };

    this.EmailForm.reset();
  }

  openDialog(boo){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    if(boo){
      this.dialog.open(DialogContactSuccess, dialogConfig);
    }else{
      this.dialog.open(DialogContactError, dialogConfig);
    }
  }

  ngOnInit() {
    this.globals.mostrarIngles;
  }



  ngOnChanges(){
   this.globals.mostrarIngles;
  }

  cambiarCheckPolitica(){
  this.checkPoliticas=!this.checkPoliticas;
  }
}


@Component({
  selector: 'app-dialog-contact-success',
  templateUrl: './dialog-contact-success.html',
  styleUrls: ['./dialog-contact.css']
})
export class DialogContactSuccess{}

@Component({
  selector: 'app-dialog-contact-error',
  templateUrl: './dialog-contact-error.html',
  styleUrls: ['./dialog-contact.css']
})
export class DialogContactError{}
