<div class="containerPolicy paddingForeign">
    <section class="sectionContent">
        <h1>ASP Sols, LLC Data Protection Policy.</h1>
        <p>
            Welcome to ASP Sols, LLC Data Protection Policy. We are committed to protecting the privacy and security of our users' information.
            This policy outlines the types of information we collect, how it's used, and the measures we take to protect it.
        </p>
    </section>

    <section class="sectionContent">
        <h2>Data Collection.</h2>
        <p>
            We collect information in the following ways:
        </p>
        <ul>
            <li>Information you provide to us directly (e.g., when signing up for our services, contacting us for support)</li>
            <li>Information we collect through your use of our services (e.g., device information, location data, usage statistics)</li>
            <li>Types of Data Collected</li>
        </ul>

        <p>
            We may collect the following types of information:
        </p>
        <ul>
            <li>Personal identification information (Name, email address, phone number, etc.).</li>
            <li>Browser and device information (IP address, browser type, device type).</li>
            <li>Usage data (pages visited, interaction with content).</li>
            <li>Any other information you choose to provide to us.</li>
            <li>Use of Data.</li>
        </ul>

        <p>
            We use the information we collect to:
        </p>
        <ul>
            <li>Provide, maintain, and improve our services.</li>
            <li>Communicate with you, including responding to your inquiries and providing customer support.</li>
            <li>Enhance the security of our services.</li>
            <li>Comply with legal obligations.</li>
            <li>For any other purpose with your consent.</li>
            <li>Data Sharing and Disclosure.</li>
        </ul>

        <p>
            We do not share or disclose your personal information to third parties except in the following cases:
        </p>
        <ul>
            <li>With your consent.</li>
            <li>For legal reasons, such as to comply with a legal obligation or to protect the rights and safety of our users and the public.</li>
            <li>To our trusted service providers who process information on our behalf under strict confidentiality agreements.</li>
            <li>Data Security.</li>
        </ul>
        <p>We take the security of your data seriously and implement appropriate technical and organizational measures to protect it against unauthorized or unlawful processing and against accidental loss, destruction, or damage.</p>
    </section>
    
    <section class="sectionContent">
        <h2>Your Rights.</h2>
        <p>
            You have the right to:
        </p>
        <ul>
            <li>Access the personal information we hold about you.</li>
            <li>Request the correction of inaccurate information.</li>
            <li>Request the deletion of your personal information.</li>
            <li>Withdraw your consent for data processing at any time.</li>
            <li>Lodge a complaint with a supervisory authority.</li>
            <li>Data Retention.</li>
        </ul>
        <p>We retain your personal information only for as long as necessary to provide the services you have requested and thereafter for a variety of legitimate legal or business purposes.</p>
    </section>

    <section class="sectionContent">
        <h2>Users Only of Legal Age of Majority</h2>
        <p>
            Our Website does not cater to individuals under the age of 13. No one under the age of 13 may provide any data via the Website. We do not deliberately collect personal information from children under the age of 13. If you are below the age of 13, please abstain from using or providing any data on this Website or through any of its features. If we become aware that we have unintentionally collected or received personal information from a child under 13 without parental consent verification, we will delete this information.
        </p>
    </section>

    <section class="sectionContent">
        <h2>For California Residents: </h2>
        <p>
            California Civil Code Section 1798.83 permits users of our Website who are California residents to request specific information about our disclosure of personal information to third parties for their direct marketing purposes. To make such a request, please send an email to info@aspsols.com.
        </p>
    </section>

    <section class="sectionContent">
        <h2>For Residents of the European Union:</h2>
        <p>
            If you are located in the European Union, you possess certain additional rights concerning your personal information under the General Data Protection Regulation (Regulation (EU) 2016/679) (the "GDPR"), including:
        </p>
        <ul>
            <li>The right to access your personal information. </li>
            <li>The right to rectify your personal information if it is inaccurate or incomplete. </li>
            <li>The right to have your personal information erased ("right to be forgotten") if specific criteria are met. </li>
            <li>The right to withdraw your consent to our processing of your personal information at any time (if our processing is based on consent). </li>
            <li>The right to object to our processing of your personal information (if processing is based on legitimate interests). </li>
            <li>The right to object to our processing of your personal information for direct marketing purposes. </li>
            <li>The right to receive your personal information from us in a structured, commonly used, and machine-readable format, and the right to transmit your personal information to another controller without interference from us (data portability). </li>
            <li>With your consent.  </li>
        </ul>
    </section>

    <section class="sectionContent">
        <h2>Updates to This Policy.</h2>
        <p>
            We may update this policy from time to time. We will notify you of any changes by posting the new policy on this page. We encourage you to review this policy periodically for any changes.
        </p>
    </section>

    <section class="sectionContent">
        <h2>Contact Us.</h2>
        <p>
            If you have any questions about this Data Protection Policy, please contact us at info@aspsols.com.
        </p>
    </section>
</div>