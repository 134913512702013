<div class="row row-wel-jformsrun">
    <div class="col-sm-12 col-md-12 col-lg-12 bg-jformsrun">
        <div class="wel-jformsrun" data-aos="fade-left" data-aos-once="true" data-aos-duration="800">
            <h2>{{"JFORMSRUNTIME.titleJformsruntime" | translate}}</h2>
            <p>
                {{"JFORMSRUNTIME.paragraphJformsruntime" | translate}}
            </p>
            <!-- <button mat-button>{{"JFORMSRUNTIME.btnDownloadDoc" | translate}}</button> -->
        </div>
    </div>
</div>