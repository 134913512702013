import { BrowserModule, HammerGestureConfig, HAMMER_GESTURE_CONFIG} from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { MDBBootstrapModule} from 'angular-bootstrap-md';

import { AppRoutingModule, routingComponents } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

//Translate and WebServices
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

//Components Material
import { MatToolbarModule} from '@angular/material/toolbar';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatMenuModule} from '@angular/material/menu';
import {MatTabsModule} from '@angular/material/tabs';
import {MatDialogModule} from '@angular/material/dialog';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatTooltipModule} from '@angular/material/tooltip';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

//slick
import { SlickCarouselModule } from 'ngx-slick-carousel'

//ParticlesModule
// import { ParticlesModule } from 'angular-particle';

//Services
import { GuestInfoService } from './services/guest-info.service';
import { SendMailService } from './services/send-mail.service';

//Components Angular
import { HomeComponent } from './home/home.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { ContactComponent } from './contact/contact.component';
import { MenuComponent } from './menu/menu.component';
import { WelcomeComponent } from './home/welcome/welcome.component';
import { ProductsComponent } from './home/products/products.component';
import { TeamComponent } from './home/team/team.component';
import { FeaturedComponent } from './home/featured/featured.component';
import { TestimonialsComponent } from './home/testimonials/testimonials.component';
import { FooterComponent } from './footer/footer.component';
import { CopyComponent } from './footer/copy/copy.component';
import { ContactFastComponent } from './footer/contact-fast/contact-fast.component';
import { OurClientsComponent } from './home/our-clients/our-clients.component';
import { WelcomeUsComponent } from './about-us/welcome-us/welcome-us.component';
import { MisionComponent } from './about-us/mision/mision.component';
import { CustomersSegmentComponent } from './about-us/customers-segment/customers-segment.component';
import { FormContactComponent } from './contact/form-contact/form-contact.component';
import { DialogContactSuccess } from './contact/form-contact/form-contact.component';
import { DialogContactError } from './contact/form-contact/form-contact.component';
import { MapComponent } from './contact/map/map.component';
import { ServicesFastComponent } from './home/services-fast/services-fast.component';
import { ErpComponent } from './erp/erp.component';
import { Jsp7Component } from './erp/jsp7/jsp7.component';
import { Jsp7DetComponent } from './erp/jsp7-det/jsp7-det.component';
import { Jsp7CloudComponent } from './erp/jsp7-cloud/jsp7-cloud.component';
import { JformsComponent } from './jforms/jforms.component';
import { WelcomeJformsComponent } from './jforms/welcome-jforms/welcome-jforms.component';
import { DownloadToolsComponent } from './download-tools/download-tools.component';
import { JmigratorComponent } from './jmigrator/jmigrator.component';
import { WelcomeJmigratorComponent } from './jmigrator/welcome-jmigrator/welcome-jmigrator.component';
import { ArqOrgComponent } from './arq-org/arq-org.component';
import { JreportsComponent } from './jreports/jreports.component';
import { WelcomeJreportsComponent } from './jreports/welcome-jreports/welcome-jreports.component';
import { JformsruntimeComponent } from './jformsruntime/jformsruntime.component';
import { WelcomeJformsrunComponent } from './jformsruntime/welcome-jformsrun/welcome-jformsrun.component';
import { TopComponent } from './top/top.component';
import { MenuMobileComponent } from './menu/menu-mobile/menu-mobile.component';
import { MenuDesktopComponent } from './menu/menu-desktop/menu-desktop.component';
import { MultiLanguageComponent } from './multi-language/multi-language.component';
import { SoftwareComponent } from './software/software.component';
import { WelcomeSoftwareComponent } from './software/welcome-software/welcome-software.component';
import { ShortcutsSoftwareComponent } from './software/shortcuts-software/shortcuts-software.component';
import { FrameworkSoftComponent } from './software/framework-soft/framework-soft.component';
import { InteractionsSoftComponent } from './software/interactions-soft/interactions-soft.component';
import { BackendSoftComponent } from './software/backend-soft/backend-soft.component';
import { BusinessSoftComponent } from './software/business-soft/business-soft.component';
import { AnalysisSoftComponent } from './software/analysis-soft/analysis-soft.component';
import { MethodologySoftComponent } from './software/methodology-soft/methodology-soft.component';
import { ProjectsSoftComponent } from './software/projects-soft/projects-soft.component';
import { WisesophiaComponent } from './wisesophia/wisesophia.component';
import { WelcomeWiseComponent } from './wisesophia/welcome-wise/welcome-wise.component';
import { InfographicWiseComponent } from './wisesophia/infographic-wise/infographic-wise.component';
import { TeamWorkWiseComponent } from './wisesophia/team-work-wise/team-work-wise.component';
import { TestimonialsWiseComponent } from './wisesophia/testimonials-wise/testimonials-wise.component';
import { BusinessWiseComponent } from './wisesophia/business-wise/business-wise.component';
import{GlobalsService}from'./globals.service';
import { OutsourcingComponent } from './outsourcing/outsourcing.component';
import { WelcomeOutsourcingComponent } from './outsourcing/welcome-outsourcing/welcome-outsourcing.component';
import { ContentOutsourcingComponent } from './outsourcing/content-outsourcing/content-outsourcing.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Injectable } from '@angular/core';
import { MyTranslatePipe } from './my-translate.pipe';
import { MobileApplicationDevelopmentComponent } from './software/mobile-application-development/mobile-application-development.component';
// import { PageAspForeignMainComponent } from './pageAspForeign/page-asp-foreign-main/page-asp-foreign-main.component';
// import { AspForeignHomeComponent } from './pageAspForeign/asp-foreign-home/asp-foreign-home.component';
// import { AppRoutingModuleForeign } from './routing-module-foreign';
// import { AspForeignHeaderComponent } from './pageAspForeign/asp-foreign-header/asp-foreign-header.component';
import {MatRippleModule} from '@angular/material/core';
import { ForeignModule } from './foreign.module';
// import { WhatWeDoComponent } from './pageAspForeign/what-we-do/what-we-do.component';
// import { ExperienceComponent } from './pageAspForeign/experience/experience.component';
// import { OurServicesComponent } from './pageAspForeign/our-services/our-services.component';
// import { OurSolutionsComponent } from './pageAspForeign/our-solutions/our-solutions.component';
// import { OurWorkComponent } from './pageAspForeign/our-work/our-work.component';
// import { AlliedBrandsComponent } from './pageAspForeign/allied-brands/allied-brands.component';
// import { FooterForeignComponent } from './pageAspForeign/footer/footer-foreign.component';
// import { ClientsForeignComponent } from './pageAspForeign/clients-foreign/clients-foreign.component';

//Function scroll vertical mdb-carousel
declare var Hammer: any;
@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  overrides = <any> {
    'pan': { direction: Hammer.DIRECTION_All },
    'swipe': { direction: Hammer.DIRECTION_VERTICAL },
  };

  buildHammer(element: HTMLElement) {
    const mc = new Hammer(element, {
      touchAction: 'auto',
          inputClass: Hammer.SUPPORT_POINTER_EVENTS ? Hammer.PointerEventInput : Hammer.TouchInput,
          recognizers: [
            [Hammer.Swipe, {
              direction: Hammer.DIRECTION_HORIZONTAL
            }]
          ]
    });
    return mc;
  }
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AboutUsComponent,
    ContactComponent,
    routingComponents,
    MenuComponent,
    WelcomeComponent,
    ProductsComponent,
    TeamComponent,
    FeaturedComponent,
    TestimonialsComponent,
    FooterComponent,
    CopyComponent,
    ContactFastComponent,
    OurClientsComponent,
    WelcomeUsComponent,
    MisionComponent,
    CustomersSegmentComponent,
    FormContactComponent,
    DialogContactSuccess,
    DialogContactError,
    MapComponent,
    ServicesFastComponent,
    ErpComponent,
    Jsp7Component,
    Jsp7DetComponent,
    Jsp7CloudComponent,
    JformsComponent,
    WelcomeJformsComponent,
    DownloadToolsComponent,
    JmigratorComponent,
    WelcomeJmigratorComponent,
    ArqOrgComponent,
    JreportsComponent,
    WelcomeJreportsComponent,
    JformsruntimeComponent,
    WelcomeJformsrunComponent,
    TopComponent,
    MenuMobileComponent,
    MenuDesktopComponent,
    MultiLanguageComponent,
    SoftwareComponent,
    WelcomeSoftwareComponent,
    ShortcutsSoftwareComponent,
    FrameworkSoftComponent,
    InteractionsSoftComponent,
    BackendSoftComponent,
    BusinessSoftComponent,
    AnalysisSoftComponent,
    MethodologySoftComponent,
    ProjectsSoftComponent,
    WisesophiaComponent,
    WelcomeWiseComponent,
    InfographicWiseComponent,
    TeamWorkWiseComponent,
    TestimonialsWiseComponent,
    BusinessWiseComponent,
    OutsourcingComponent,
    WelcomeOutsourcingComponent,
    ContentOutsourcingComponent,
    MyTranslatePipe,
    MobileApplicationDevelopmentComponent,
    // PageAspForeignMainComponent,
    // AspForeignHomeComponent,
    // AspForeignHeaderComponent,
    // WhatWeDoComponent,
    // ExperienceComponent,
    // OurServicesComponent,
    // OurSolutionsComponent,
    // OurWorkComponent,
    // AlliedBrandsComponent,
    // FooterForeignComponent,
    // ClientsForeignComponent
  ],
  entryComponents: [
    DialogContactSuccess,
    DialogContactError
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    MatTabsModule,
    FormsModule,
    MatDialogModule,
    ReactiveFormsModule,
    SlickCarouselModule,
    MDBBootstrapModule.forRoot(),
    HttpClientModule,
    MatTooltipModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }), 
    MatSidenavModule,
    MatExpansionModule,
    MatCheckboxModule,
    MatRippleModule,
    NgbModule,
    ForeignModule,
    // AppRoutingModuleForeign
  ],
  providers: [GlobalsService,GuestInfoService, SendMailService,
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig
    }
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
}

//Function Translate
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}
