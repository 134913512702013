import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-shortcuts-software',
  templateUrl: './shortcuts-software.component.html',
  styleUrls: ['./shortcuts-software.component.css']
})
export class ShortcutsSoftwareComponent implements OnInit {

  constructor() {}

  ngOnInit() {
  }
  goMobileDev(){
    window.scrollTo({ top:760, left: 0,behavior:'smooth' });
  }
  goIntegration(){
    window.scrollTo({ top:1210, left: 0,behavior:'smooth' });
  }
  goBackend(){
    window.scrollTo({ top:2030, left: 0,behavior:'smooth' });
  }
  goBI(){
    window.scrollTo({ top:2300, left: 0,behavior:'smooth' });
  }
  /*
  goMethodology(){
    window.scrollTo({ top:3350, left: 0,behavior:'smooth' });
  } No esta l seccion de metodo scrum en servicios se paso a nostros*/
}
