import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-interactions-soft',
  templateUrl: './interactions-soft.component.html',
  styleUrls: ['./interactions-soft.component.css']
})
export class InteractionsSoftComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
  isShow = true;
 
  toggleDisplay() {
    this.isShow = !this.isShow;
  }

}
