import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalsService } from 'src/app/globals.service';
import { AppRoutingModuleForeign } from 'src/app/routing-module-foreign';

@Component({
  selector: 'app-experience',
  templateUrl: './experience.component.html',
  styleUrls: ['./experience.component.css']
})
export class ExperienceComponent implements OnInit {

  constructor( private router: Router, public globals: GlobalsService) { }

  ngOnInit(): void {
  }

  navigateContact(){
    this.router.navigateByUrl('/contactForeign');
    this.globals.selectNav = ""
  }

}
