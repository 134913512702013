<!-- <header>
 <div class="contenHeader paddingForeign">
    <div class="logoAspLLC"></div>
    <span class="flex-spacer"></span>
    <nav class="navHeader">
        <a class="linkHeader"  [ngClass]="{'linkSelected': selectNav === 'home'}" (click)="scrollToSection('home')">
            {{"pageAspForeign.header.linkHome" | translate}}
            <span class="circle" [ngClass]="{'active': selectNav === 'home'}"></span>
        </a>
        <a class="linkHeader" [ngClass]="{'linkSelected': selectNav === 'whatWeDo'}" (click)="scrollToSection('whatWeDo')">
            {{"pageAspForeign.header.linkWTD" | translate}}
            <span class="circle" [ngClass]="{'active': selectNav === 'whatWeDo'}"></span>
        </a>
        <a class="linkHeader" [ngClass]="{'linkSelected': selectNav === 'services'}" (click)="scrollToSection('services')">
            {{"pageAspForeign.header.linkServices" | translate}}
            <span class="circle" [ngClass]="{'active': selectNav === 'services'}"></span>
        </a>
        <a class="linkHeader" [ngClass]="{'linkSelected': selectNav === 'ourWork'}" routerLink="/ourServices">
            {{"pageAspForeign.header.linkOurWork" | translate}}
            <span class="circle" [ngClass]="{'active': selectNav === 'ourWork'}"></span>
        </a>
        <a class="linkHeader" [ngClass]="{'linkSelected': selectNav === 'blog'}" (click)="scrollToSection('blog')">
            {{"pageAspForeign.header.linkBlog" | translate}}
            <span class="circle" [ngClass]="{'active': selectNav === 'blog'}"></span>
        </a>
        <a class="linkHeader" [ngClass]="{'linkSelected': selectNav === 'careers'}" (click)="scrollToSection('careers')">
            {{"pageAspForeign.header.linkCareers" | translate}}
            <span class="circle" [ngClass]="{'active': selectNav === 'careers'}"></span>
        </a>


        <mat-icon class="flag-icon iconLenguaje" [ngClass]="{'wave': isHovered}" (mouseenter)="startAnimation()" (mouseleave)="stopAnimation()">
            <img *ngIf="language === 'en'" (click)="changeLanguage('es')"  matTooltip="Translate to Spanish" src="../../../assets/pageForeignAsp/headerForeign/icon-spanish.svg" alt="ES" class="flag-img">
            <img *ngIf="language === 'es' " (click)="changeLanguage('en')"  matTooltip="Translate to English"  src="../../../assets/pageForeignAsp/headerForeign/icon-english.svg" alt="EN" class="flag-img">
        </mat-icon>
       


        <div class="btnRadius" matRipple>{{"pageAspForeign.header.btnGetTouch" | translate}}</div>
    </nav>
 </div>
</header> -->
<app-header-asp-desktop class="headerDestokp"></app-header-asp-desktop>
<app-header-asp-mobile class="headerMobile"></app-header-asp-mobile>
