import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mision',
  templateUrl: './mision.component.html',
  styleUrls: ['./mision.component.css']
})
export class MisionComponent implements OnInit {

  constructor() { }

  mision : Boolean;
  vision : Boolean;
  oferta : Boolean;

  selectedBtn(selected){
    if(selected == 'mision'){
      this.mision  = true;
      this.vision = false;
      this.oferta = false;
    }else if(selected == 'vision'){
      this.mision  = false;
      this.vision = true;
      this.oferta = false;
    }else if(selected == 'oferta'){
      this.mision  = false;
      this.vision = false;
      this.oferta = true;
    }
  }

  ngOnInit() {
    this.selectedBtn('mision');
  }

}
