<div class="contentOurWork">
    <section class="caseStudy paddingForeign">
        <div class="row2">
            <article class="caseStudyLeft">
                <img src="../../../assets/pageForeignAsp/ourWork/img-caseStudyNew.svg" class="imgCaseStudy">
            </article>
    
            <article class="caseStudyRigth">
                <div class="caseStudyRigthTitle">
                    <h2 class="titleCaseStudy noMargin">{{"pageAspForeign.ourWork.caseStudy" | translate}}</h2>
                    <P class="txtCaseStudy noMargin"> {{"pageAspForeign.ourWork.caseStudyTxt" | translate}}  </P>
                </div>
                <label class="noMargin txtInnovative"> {{"pageAspForeign.ourWork.Innvative" | translate}} </label>
                <div class="containerTxtOurClient">
                    <h2 class="titleCaseStudy noMargin"> {{"pageAspForeign.ourWork.ourClient" | translate}} </h2>
                    <P class="noMargin txtCOurService"> {{"pageAspForeign.ourWork.ourClientTxt" | translate}} </P>
                </div>
            </article>
        </div>
    </section>

    <section class="theChallenge flex2 paddingForeign">
        <article class="contentLeftChallenge">
            <h2 class="titleCaseStudy fontSzTitle noMargin"> {{"pageAspForeign.ourWork.theChallenge" | translate}} </h2>
            <div class="contentTxtChallenge">
                <div class="txtLimited">
                    <p class="noMargin letter-spacing"><span>{{"pageAspForeign.ourWork.imitedIns" | translate}}</span> {{"pageAspForeign.ourWork.imitedInsTxt" | translate}} </p>
                </div>
                <div class="txtAnuvu">
                    <p class="noMargin letter-spacing"> <span>{{"pageAspForeign.ourWork.anuvu" | translate}}</span> {{"pageAspForeign.ourWork.anuvuTxt" | translate}} </p>
                </div>
                <div class="txtPulse">
                    <p class="noMargin letter-spacing"> <span> {{"pageAspForeign.ourWork.pulse" | translate}}</span> {{"pageAspForeign.ourWork.pulseTxt" | translate}}  </p>
                </div>
            </div>
        </article>

        <article class="contentRigthImgPulseChelenge">
            <!-- <div class="logoPulseContainer">
                <img src="../../../assets/pageForeignAsp/ourWork/theChallengePulseLogo.svg" alt="Pulse" class="logoPulseCh">
            </div>
            <img src="../../../assets/pageForeignAsp/ourWork/theChallengePulseScreen.svg" alt="Pulse-statistics"> -->
            <img src="../../../assets/pageForeignAsp/ourWork/img-theChallenge.svg" alt="Pulse-statistics">
        </article>
    </section>

    <section class="theTransformation paddingForeign">
        <div class="contentTransformationStart">
            <article class="contentRigthImgPulse">
                <img src="../../../assets/pageForeignAsp/ourWork/img-theTransformation.svg" alt="Pulse">
            </article>
    
            <article class="contentLeftTransformation">
                <h2 class="titleCaseStudy fontSzTitle noMargin">{{"pageAspForeign.ourWork.theTransformation" | translate}}</h2>
                <div class="contentTxtChallenge">
                    <div class="txtLimited">
                        <p class="noMargin"><span>{{"pageAspForeign.ourWork.txtTheTransformation1" | translate}} </span>
                            {{"pageAspForeign.ourWork.txtTheTransformation2" | translate}} </p>
                    </div>
                    <div class="txtAnuvu">
                        <p class="noMargin"> {{"pageAspForeign.ourWork.txtTheTransformation3" | translate}} </p>
                    </div>
                </div>
            </article>
        </div>

        <div>
            <p class="txtTransformationEnd">{{"pageAspForeign.ourWork.txtTheTransformation4" | translate}}</p>
        </div>
    </section>

    <section class="theImpact paddingForeign">
        <h2 class="titleCaseStudy noMargin">{{"pageAspForeign.ourWork.titleImpact" | translate}} </h2>
        <P>
            {{"pageAspForeign.ourWork.txtImpact" | translate}}
        </P>

        <h2 class="titleCaseStudy noMargin" style="margin-top: 20px;">{{"pageAspForeign.ourWork.titleExpRes" | translate}}</h2>
        <p>
            {{"pageAspForeign.ourWork.txtExpRes" | translate}}
        </p>
    </section>

    <section class="paddingForeign">
        <article class="guidedSection">
            <label> {{"pageAspForeign.ourWork.titleGuided1" | translate}} <br> {{"pageAspForeign.ourWork.titleGuided2" | translate}} <br> {{"pageAspForeign.ourWork.titleGuided3" | translate}} </label>
            <span class="flex-spacer"></span>
            <p> {{"pageAspForeign.ourWork.txtGuided" | translate}} </p>
        </article>

        <article class="imgFullStack">
            <!-- <img src="../../../assets/pageForeignAsp/ourWork/img-fullStacks.svg" alt="FullStackDevelopment" class="imgFullStackDevelopment"> -->
            <img class="imgFullStackDevelopment" *ngIf="!globals.mostrarIngles" src="../../../assets/pageForeignAsp/experience/img_diagram Horizon-experienceSpanish.svg" alt="FullStackDevelopment">
        <img class="imgFullStackDevelopment" *ngIf="globals.mostrarIngles" src="../../../assets/pageForeignAsp/experience/img_diagram Horizon-experience.svg" alt="FullStackDevelopment">
            <div class="btnRadius buttonHelp" (click)="navigateContact()" matRipple>{{"pageAspForeign.EXPERIENCE.btn" | translate}}</div>
        </article>
    </section>
</div>