<div class="row row-back">
    <div class="col-sm-12 col-md-12 col-lg-6 col-back-soft back-left">
        <div class="back-soft-l">
            <h2 data-aos="fade-right" data-aos-once="true" data-aos-duration="1200">{{"SOFTWARE.titleBack" | translate}}</h2>
        </div>
    </div>
    <div class="col-sm-12 col-md-12 col-lg-6 col-back-soft">
        <div class="back-soft-r" data-aos="fade-left" data-aos-once="true" data-aos-duration="1200">
            <ul>
                <li  *ngFor="let p of 'SOFTWARE.paragraphBack' | translate">{{p}}</li>
            </ul>
        </div>
    </div>
</div>
