import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class GuestInfoService {

  constructor(private http : HttpClient) { }

  getGuestIp(){
    return this.http.get("https://api.ipify.org?format=json");
  }

  getGuestInfo(ip){
    return this.http.get("sendMail/infoIp?ip="+ip);
  }
}
